import { put } from './client'
import type { BackendMeta } from './types'

export type SetMessageMetadata = BackendMeta<
  '/api/workspaces/{workspace_id}/ask_go/{session_id}/message_metadata',
  'put'
>

export type SetMessageMetadataParams = {
  workspaceId: SetMessageMetadata['pathParams']['workspace_id']
  sessionId: SetMessageMetadata['pathParams']['session_id']
  score: SetMessageMetadata['body']['metadata']['score']
  messageId: SetMessageMetadata['body']['message_id']
}

export const setMessageMetadata = ({
  workspaceId,
  sessionId,
  score,
  messageId,
}: SetMessageMetadataParams) => {
  return put<
    SetMessageMetadata['body'],
    SetMessageMetadata['successResponse'],
    SetMessageMetadata['path']
  >(`/api/workspaces/${workspaceId}/ask_go/${sessionId}/message_metadata`, {
    metadata: { score },
    message_id: messageId,
  })
}
