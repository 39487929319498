import { remove } from './client'
import type { BackendMeta } from './types'

export type RemoveProjectPermission = BackendMeta<
  '/api/workspaces/{workspace_id}/projects/{project_id}/members/{user_id}',
  'delete'
>

export type RemoveProjectPermissionParams = {
  workspaceId: RemoveProjectPermission['pathParams']['workspace_id']
  projectId: RemoveProjectPermission['pathParams']['project_id']
} & (
  | { userId: RemoveProjectPermission['pathParams']['user_id'] }
  | { group: 'anyone_in_workspace' | 'anyone_in_folder' }
)

/**
 * Remove a user's explicit role in a project, reverting their access to the
 * access inherited from their workspace or folder.
 */
export const removeProjectPermission = ({
  projectId,
  workspaceId,
  ...rest
}: RemoveProjectPermissionParams) => {
  const userId = 'userId' in rest ? rest.userId : rest.group
  return remove<
    undefined,
    RemoveProjectPermission['successResponse'],
    RemoveProjectPermission['path']
  >(`/api/workspaces/${workspaceId}/projects/${projectId}/members/${userId}`, undefined)
}
