<script lang="ts" setup>
import { computedAsync } from '@vueuse/core'
import { ref } from 'vue'

const props = defineProps<{
  loading: boolean
  url: string
}>()

const emit = defineEmits<{
  (e: 'error'): void
}>()

const loadingState = ref<'idle' | 'loading' | 'loaded'>('idle')
const value = computedAsync(async () => {
  if (props.loading) {
    URL.revokeObjectURL(props.url)
    return null
  }

  loadingState.value = 'loading'
  const response = await fetch(props.url)
  loadingState.value = 'loaded'

  if (!response.ok) {
    emit('error')
  }
  const blob = await response.blob()
  const urlWithHost = props.url.startsWith('/') ? window.location.origin + props.url : props.url
  const path = new URL(urlWithHost).pathname
  if (path.endsWith('.svg')) {
    const asText = await blob.text()
    return {
      svg: true,
      svgAsText: asText,
    } as const
  }
  return {
    url: true,
    urlObject: URL.createObjectURL(blob),
  } as const
}, null)
</script>

<template>
  <!-- eslint-disable vue/no-v-html -->
  <div
    v-if="loadingState !== 'loading' && value?.svg === true"
    v-bind="$attrs"
    class="[&>*]:size-full"
    v-html="value.svgAsText"
  />
  <!-- eslint-enable vue/no-v-html -->
  <img
    v-else-if="loadingState !== 'loading' && value?.url"
    :src="value.urlObject"
    v-bind="$attrs"
  />
  <slot
    v-else
    name="fallback"
    v-bind="$attrs"
  />
</template>
