import { setFieldValue } from '@/backend/setFieldValue'
import { PropertyType } from '@/backend/types'
import { toast } from '@/shared/toast'
import { useClipboard } from '@vueuse/core'
import { onBeforeUnmount, onMounted, type Ref } from 'vue'
import { useWorkspaces } from '../Workspaces/useWorkspaces'
import { serializeFields, useProject, type Entity } from './useProject'
import { useSerializeFieldToText } from './useSerializeFieldToText'
import type { TableRange } from './useTable'

/**
 * Powers feature that makes it possible to copy and paste data from/to the table.
 * It will try to paste the data into the currently selected cell and all cells to the right and below.
 * If there is not enough space below, add new rows as needed.
 *
 * Tightly coupled to ProjectTable. If we want to use it in a task or a view table later,
 * depending on how those are implemented, we will need to generalise.
 */
export const useTableClipboard = (
  selectedRange: Ref<TableRange | null>,
  isEditing: Ref<boolean>,
) => {
  const projectStore = useProject()
  const workspacesStore = useWorkspaces()

  function validateCell(
    entity: Entity,
    property: NonNullable<ReturnType<typeof projectStore.properties.at>>,
  ) {
    const isTextLikeProperty =
      property.type === PropertyType.text || property.type === PropertyType.url

    if (!isTextLikeProperty) {
      return 'Can only paste into text or URL fields'
    }
  }

  /**
   * Updates the value of the selected cells with the given data.
   */
  async function updateCells(cells: string[][]) {
    /** Row index of the start of the current selected range */
    const currentRowIndex = selectedRange.value?.start.rowIndex ?? null
    /** Column index of the start of the current selected range */
    const currentColIndex = selectedRange.value?.start.colIndex ?? null
    if (currentRowIndex === null || currentColIndex === null) {
      return
    }

    let pasteData: { entityId: string; propertyId: string; value: string }[] = []

    let toastError: string | undefined
    for (let rowIndex = 0; rowIndex < cells.length; rowIndex++) {
      const pastedRow = cells[rowIndex]
      const entity = projectStore.activeView?.entities?.[currentRowIndex + rowIndex]
      if (!entity) {
        break
      }

      for (let cellIndex = 0; cellIndex < pastedRow.length; cellIndex++) {
        if (!projectStore.activeView) {
          throw new Error("Can't paste data without an active view")
        }

        const pastedCellContent = pastedRow[cellIndex]
        const propertyId = projectStore.activeView.view.propertyIds?.at(currentColIndex + cellIndex)
        const property = projectStore.properties.find((p) => p.id === propertyId)
        if (!property) {
          throw new Error('Property not found when pasting data.')
        }

        const error = validateCell(entity, property)
        if (error) {
          toastError = error
          continue
        }

        pasteData.push({
          entityId: entity.id,
          propertyId: property.id,
          value: pastedCellContent,
        })
      }
    }

    if (cells.flat().length !== pasteData.length) {
      if (toastError) {
        toast.warning(toastError)
        return
      }
      // Fill first cell with data instead
      const entity = projectStore.activeView?.entities?.[currentRowIndex]
      const property = projectStore.properties.at(currentColIndex)
      if (!entity || !property) {
        toast.warning(
          "There isn't enough room to paste all the data from the currently selected field",
        )
        return
      }

      const error = validateCell(entity, property)
      if (error) {
        toast.warning(error)
      }

      pasteData = [
        {
          entityId: entity.id,
          propertyId: property.id,
          value: cells.map((line) => line.join('\t')).join('\n'),
        },
      ]
    }

    pasteData.forEach(async ({ entityId, propertyId, value }) => {
      const workspaceId = workspacesStore.currentWorkspace?.id
      const projectId = projectStore.projectId
      if (!workspaceId || !projectId) {
        return
      }
      const propertyIndex = projectStore.propIdToIndex[propertyId]
      const property = projectStore.properties[propertyIndex]
      if (property.type !== 'text' && property.type !== 'url') {
        return
      }

      const fieldValue =
        property.type === 'text'
          ? { text: value }
          : {
              url: value,
            }

      const result = await setFieldValue({
        workspaceId,
        projectId,
        entityId,
        fields: {
          [propertyId]: fieldValue,
        },
      })
      if (result.ok) {
        Array.from(serializeFields(result.data.fields).values()).forEach(projectStore.updateField)
      }
    })
  }

  const pasteListener = (event: ClipboardEvent) => {
    const text = event.clipboardData?.getData('text/plain')
    if (!text) {
      // the clipboard is empty - there is nothing to do
      return
    }

    // Parse the clipboard data into a table format - newlines separate rows, tabs separate columns
    const cells = text.split('\n').map((line) => line.split('\t'))

    if (cells && cells.length > 0 && selectedRange.value) {
      updateCells(cells)
      event.preventDefault()
    }
  }

  const serializeField = useSerializeFieldToText()
  const clipboard = useClipboard()
  const copyListener = () => {
    if (!selectedRange.value || !projectStore.activeView) {
      return
    }

    // If editing, then the browser's usual copying behaviour should apply
    // (i.e. only copy selected text)
    if (isEditing.value) {
      return
    }

    const selectedEntities = projectStore.activeView.entities?.slice(
      selectedRange.value.start.rowIndex,
      selectedRange.value.end.rowIndex + 1,
    )
    const selectedPropertyIds = projectStore.visibleProperties
      .slice(selectedRange.value.start.colIndex, selectedRange.value.end.colIndex + 1)
      .map((p) => p.id)
    if (!selectedEntities) {
      return
    }

    /**
     * In this block, we serialize the range of selected fields into a
     * string, where each row is separated by a newline and each column
     * is separated by a tab. This format means that we can paste into
     * Google Sheets, Excel, etc and preserve the range dimensions.
     */
    const serializedSelection = selectedEntities
      .reduce<string[]>((acc, entity) => {
        if (!entity) {
          return acc
        }
        const serializedRow = selectedPropertyIds
          .reduce<string[]>((rowAcc, propertyId) => {
            const field = entity.fields.get(propertyId)
            if (!field) {
              return rowAcc
            }

            const serializedField = serializeField(field)
            return [...rowAcc, serializedField]
          }, [])
          .join('\t')

        return [...acc, serializedRow]
      }, [])
      .join('\n')

    clipboard.copy(serializedSelection)
  }

  onMounted(() => {
    document.addEventListener('copy', copyListener)
    document.addEventListener('paste', pasteListener)
  })
  onBeforeUnmount(() => {
    document.removeEventListener('copy', copyListener)
    document.removeEventListener('paste', pasteListener)
  })
}
