<script setup lang="ts">
// Figma component: Sidebar/Properties
// https://www.figma.com/file/Xo7wQGCNhUmbTnF2Wbkcvj/AGIDB?node-id=508%3A21486&mode=dev
import { PropertyType } from '@/backend/types'
import ToolTip from '@/uiKit/ToolTip.vue'
import { onKeyStroke, useEventListener } from '@vueuse/core'
import { computed, onMounted, ref, watch } from 'vue'
import GroundingTooltip from './GroundingTooltip.vue'

import BadgeItem from '@/uiKit/BadgeItem.vue'
import DarwinButton from '@/uiKit/DarwinButton.vue'
import IconButton from '@/uiKit/IconButton.vue'
import IconSprite from '@/uiKit/IconSprite.vue'
import ListHeader from '@/uiKit/ListHeader.vue'
import ListMenu from '@/uiKit/ListMenu.vue'
import ListMenuItem from '@/uiKit/ListMenuItem.vue'
import SelectDropdown from '@/uiKit/SelectDropdown.vue'
import SelectDropdownTrigger from '@/uiKit/SelectDropdownTrigger.vue'
import type TextField from '@/uiKit/TextField.vue'

import { useFieldTypeOptions } from '@/modules/Project/useFieldTypeOptions'
import {
  FIELD_TOOL_LABELS,
  FIELD_TOOL_OUTPUT_TYPES,
  FIELD_TYPES_LABELS,
} from '@/modules/WorkspaceSettings/propertyConfig'
import {
  GO_API_REFERENCE_URL,
  GO_WEBHOOKS_REFERENCE_URL,
  goZapierUrlForCompleteField,
  isHtmlElement,
  openURLInNewTab,
} from '@/shared/utils'
import { scrollToElementIfOutOfBounds } from '@/shared/utils/scroll'
import { assertIsNotNullOrUndefined } from '@/shared/utils/typeAssertions'
import MentionableTextInput from '@/sharedComponents/MentionableTextInput.vue'
import { ANALYTICS_EVENT, useAnalytics } from '@/sharedComposables/useAnalytics'
import DividerLine from '@/uiKit/DividerLine.vue'
import InlineTextField from '@/uiKit/InlineTextField.vue'
import SwitchButton from '@/uiKit/SwitchButton.vue'
import { usePermissionsStore } from '../IdentityAndAccess/permissionsStore'
import { useLibraryStore } from '../Library/libraryStore'
import CollectionTypeProperties from './CollectionTypeProperties.vue'
import ModelInputMenu from './ModelInputMenu.vue'
import PropertySidebarLabel from './PropertySidebarLabel.vue'
import PropertySidebarTopDropdown from './PropertySidebarTopDropdown.vue'
import SelectTypeOptions from './SelectTypeOptions.vue'
import ToolMenu from './ToolMenu.vue'
import UserListMenu from './UserListMenu.vue'
import { TOOL_ICON, TYPE_ICON } from './icons'
import { useFieldToolOptions } from './useFieldToolOptions'
import { useGroundingToggle } from './useGroundingToggle'
import { useMentionableInputs } from './useMentionableInputs'
import { useModelInputs } from './useModelInputs'
import { usePinnedColumn } from './usePinnedColumn'
import { useProject, type Property } from './useProject'
import { useProperty } from './useProperty'
import { usePropertyMeta } from './usePropertyMeta'
import { useSidebarWidth } from './useSidebarWidth'

defineProps<{ disabled?: boolean; workspaceId: string; projectId: string }>()

const emit = defineEmits<{
  (e: 'close' | 'delete' | 'reprocess' | 'hide'): void
  (
    e: 'update',
    v: Pick<Property, 'name' | 'type' | 'tool' | 'inputs' | 'description'> & {
      config:
        | {
            maxSelected?: number | null
            removeOptions?: string[]
            upsertOptions?: {
              color?: string | null
              new_value?: string | null
              value: string
            }[]
          }
        | undefined
    },
  ): void
}>()

const propertyStore = useProperty()
const pinnedColumn = usePinnedColumn()
const permissionStore = usePermissionsStore()
const projectStore = useProject()
const fieldTypesOptions = useFieldTypeOptions()
const { inputIdOptions, selectedInputIdOptions } = useModelInputs()

// When the user clicks outside the sidebar, we want to close it, but because
// the 3 dropdowns render in a <Teleport />, we have to explicitly ignore them
const container = ref<HTMLDivElement>()
const typeDropdown = ref<HTMLElement>()

// dropdown refs
const editedToolDropdownRef = ref()
const editedTypeDropdownRef = ref()
const editedInputIdsDropdownRef = ref()
const propertySidebarTopDropdown = ref()

const isAnyDropdownOpen = computed(() => {
  return (
    editedToolDropdownRef.value?.open ||
    editedTypeDropdownRef.value?.open ||
    editedInputIdsDropdownRef.value?.open ||
    propertySidebarTopDropdown.value?.open
  )
})

const nameInput = ref<typeof TextField>()

const updateName = () => {
  // This happens on blur of input field.
  // There should be no event if there is no actual change
  if (propertyStore.editedName === propertyStore.property?.name) {
    return
  }
  emitUpdate()
}

const emitUpdate = () => {
  emit('update', {
    name: propertyStore.editedName,
    type: propertyStore.editedType,
    tool: propertyStore.editedTool,
    description: propertyStore.editedDescription,
    inputs: propertyStore.editedInputs,
    config: {
      removeOptions: propertyStore.editedConfig?.removeOptions,
      upsertOptions: propertyStore.editedConfig?.upsertOptions,
    },
  })
}

function save() {
  if (propertyStore.isDirty) {
    emitUpdate()
  }
}

const saveAndClose = () => {
  save()
  emit('close')
}

const canCloseCollectionMenu = computed(() => {
  return (
    PropertyType.collection !== propertyStore.editedType || propertyStore.visibleProperties.length
  )
})
const collectionError = ref(false)
watch(canCloseCollectionMenu, () => {
  if (canCloseCollectionMenu.value) collectionError.value = false
})

onKeyStroke('Escape', () => {
  if (isAnyDropdownOpen.value) return
  if (!canCloseCollectionMenu.value) {
    collectionError.value = true
    return
  }
  emit('close')
})

const fieldToolOptions = useFieldToolOptions()
const validTools = computed(() =>
  fieldToolOptions.value.filter((t) =>
    FIELD_TOOL_OUTPUT_TYPES[t.tool].includes(propertyStore.editedType),
  ),
)

const { captureAnalyticsEvent } = useAnalytics()
onMounted(() => {
  captureAnalyticsEvent(ANALYTICS_EVENT.OPEN_PROPERTY_SIDEBAR)
})

const { onAddMention, onRemoveMention } = useMentionableInputs()

const onRecomputeStaleFields = () => {
  emit('reprocess')

  captureAnalyticsEvent(ANALYTICS_EVENT.STALE_FIELDS_RECOMPUTED)
}

const { isRecomputeEnabled } = usePropertyMeta()

const libraryStore = useLibraryStore()
const onOpenLibrary = () => {
  libraryStore.dialogIsOpen = true
  emit('close')
}

const onToggleLibraryItem = (itemId: string) => {
  assertIsNotNullOrUndefined(libraryStore.library, 'Library is not loaded')

  const type = libraryStore.getItemType(itemId)
  const propertyId =
    type === 'file' ? libraryStore.library.fileProperty.id : libraryStore.library.textProperty.id

  propertyStore.toggleInputId(propertyId, itemId)

  if (selectedInputIdOptions.value.some((o) => o.id === itemId)) {
    captureAnalyticsEvent(ANALYTICS_EVENT.LIBRARY_INPUT_IN_PROPERTY)
  }
}

const { isGroundingEnabled, setGroundingState, areConditionsFilled } = useGroundingToggle()

const { sidebarWidth, startDrag } = useSidebarWidth(container)
const propertyIds = computed(() => {
  return projectStore.visibleProperties.map(({ id }) => id)
})

const propIndex = computed(() => {
  if (!projectStore.selectedProperty) return null
  return propertyIds.value?.indexOf(projectStore.selectedProperty.id) ?? null
})

const hasPrevProperty = computed(() => {
  return propIndex.value !== null && propIndex.value > 0
})

const hasNextProperty = computed(() => {
  if (!propertyIds.value) return false
  return propIndex.value !== null && propIndex.value < propertyIds.value?.length - 1
})

function scrollToSelected() {
  if (!propIndex.value) return
  const el = document.querySelector(
    `[data-test="property-header"][aria-colindex="${propIndex.value + 1}"]`,
  )
  if (!isHtmlElement(el)) return
  scrollToElementIfOutOfBounds(el, { left: 100, right: 100 })
}

function selectPrevProperty() {
  if (propIndex.value === null || propIndex.value === 0 || !propertyIds.value) return
  const newIdx = propIndex.value - 1
  projectStore.selectedPropertyId = propertyIds.value[newIdx]
  scrollToSelected()
}

function selectNextProperty() {
  if (
    propIndex.value === null ||
    !propertyIds.value ||
    propIndex.value === propertyIds.value?.length - 1
  )
    return
  const newIdx = propIndex.value + 1
  projectStore.selectedPropertyId = propertyIds.value[newIdx]
  scrollToSelected()
}

useEventListener(document, 'keydown', (e) => {
  if (e.target !== document.body) return
  if (e.key === 'ArrowLeft') {
    selectPrevProperty()
  } else if (e.key === 'ArrowRight') {
    selectNextProperty()
  }
})
</script>

<template>
  <div class="relative h-full pl-3">
    <div
      ref="container"
      class="z-0 mb-2.5 mr-2.5 flex h-full min-w-[250px] max-w-[70vw] flex-col overflow-hidden rounded-corner-12 border border-border-subtle bg-surface-primary p-0"
      data-test="property-sidebar"
      v-bind="$attrs"
      :style="{ width: `${sidebarWidth}px` }"
    >
      <!-- Resize handle -->
      <div
        class="group absolute left-0 top-0 h-full w-3 cursor-col-resize"
        aria-label="Resize sidebar"
        @mousedown="startDrag"
      >
        <div
          class="absolute left-1/2 top-1/2 h-5 w-1 -translate-x-1/2 -translate-y-1/2 rounded-full bg-background-gray-subtle transition-all group-hover:h-full group-hover:delay-100 group-active:bg-background-gray-subtle-pressed group-active:delay-0 hover:bg-background-gray-subtle-hovered"
          aria-hidden
        />
      </div>
      <ListHeader>
        <!-- <IconSprite :icon="TYPE_ICON[propertyStore.editedType]" /> -->
        <div class="w-full truncate">Property configuration</div>
        <template #icon>
          <div class="flex items-center gap-1">
            <IconButton
              size="md"
              variant="transparent"
              icon="chevron-left"
              aria-label="Previous property"
              :disabled="!hasPrevProperty"
              @click="selectPrevProperty"
            />
            <IconButton
              size="md"
              variant="transparent"
              icon="chevron-right"
              aria-label="Next property"
              :disabled="!hasNextProperty"
              @click="selectNextProperty"
            />
            <PropertySidebarTopDropdown
              ref="propertySidebarTopDropdown"
              :workspace-id="workspaceId"
            />
            <IconButton
              size="md"
              variant="transparent"
              icon="close"
              aria-label="Close sidebar"
              @click="saveAndClose"
            />
          </div>
        </template>
      </ListHeader>

      <div class="border-b border-border-subtle px-1 py-1.5">
        <InlineTextField
          ref="nameInput"
          size="sm"
          autofocus
          :value="propertyStore.editedName"
          aria-label="Name"
          @input="propertyStore.editedName = $event"
          @blur="updateName"
        />
      </div>

      <div
        class="flex grow flex-col overflow-auto p-1 scrollbar-thin scrollbar-track-background-transparent scrollbar-thumb-background-gray-subtle scrollbar-track-rounded-md [contain:strict]"
      >
        <div
          class="grid grid-cols-[96px_minmax(0,100%)] items-center justify-start gap-1 self-stretch pl-2"
        >
          <PropertySidebarLabel>Type</PropertySidebarLabel>
          <SelectDropdown ref="editedTypeDropdownRef">
            <template #trigger>
              <SelectDropdownTrigger
                inline
                :icon="TYPE_ICON[propertyStore.editedType]"
                >{{ FIELD_TYPES_LABELS[propertyStore.editedType] }}</SelectDropdownTrigger
              >
            </template>
            <template #dropdown="{ close }">
              <ListMenu
                ref="typeDropdown"
                :items="fieldTypesOptions"
                search-by-field="type"
                class="min-w-[200px]"
                :initial-active-item-predicate="
                  (item) => item.data.type === propertyStore.editedType
                "
                @select="(propertyStore.editedType = $event.type), close()"
              >
                <template #item="{ key, item, active, setActiveItem }">
                  <ListMenuItem
                    :label="item.data.label"
                    :active="active"
                    :aria-selected="active"
                    :icon="TYPE_ICON[item.data.type]"
                    default-hover-disabled
                    @mousemove="setActiveItem(key)"
                    @select="
                      () => {
                        propertyStore.editedType = item.data.type
                        if (!validTools.map((t) => t.tool).includes(propertyStore.editedTool)) {
                          propertyStore.editedTool = 'manual'
                        }
                        close()
                      }
                    "
                  />
                </template>
              </ListMenu>
            </template>
          </SelectDropdown>
          <PropertySidebarLabel>AI Tool</PropertySidebarLabel>
          <SelectDropdown ref="editedToolDropdownRef">
            <template #trigger>
              <SelectDropdownTrigger
                inline
                :icon="TOOL_ICON[propertyStore.editedTool]"
              >
                {{ FIELD_TOOL_LABELS[propertyStore.editedTool] }}
              </SelectDropdownTrigger>
            </template>
            <template #dropdown="{ close }">
              <ToolMenu
                ref="toolSubmenu"
                :active-tool="propertyStore.editedTool"
                :active-type="propertyStore.editedType"
                :grounded="isGroundingEnabled"
                class="min-w-[200px]"
                searchable
                @change="
                  (value) => {
                    propertyStore.editedTool = value
                    close()
                  }
                "
              />
            </template>
          </SelectDropdown>
          <PropertySidebarLabel>AI citations</PropertySidebarLabel>
          <div class="flex h-7 items-center">
            <ToolTip
              :disabled="areConditionsFilled"
              :placement="{ allowedPlacements: ['top-end'] }"
            >
              <SwitchButton
                class="ml-1.5"
                :checked="isGroundingEnabled"
                aria-label="AI citations"
                size="sm"
                color="blue"
                :disabled="!areConditionsFilled"
                @change="setGroundingState"
              />
              <template #content><GroundingTooltip /></template>
            </ToolTip>
          </div>
          <PropertySidebarLabel v-if="propertyStore.editedTool !== 'manual'"
            >Inputs</PropertySidebarLabel
          >
          <SelectDropdown
            v-if="propertyStore.editedTool !== 'manual'"
            ref="editedInputIdsDropdownRef"
          >
            <template #trigger>
              <SelectDropdownTrigger
                inline
                placeholder="Add..."
              >
                <BadgeItem
                  v-for="o in selectedInputIdOptions"
                  :key="o.id"
                  :label="o.data.name"
                  size="sm"
                  class="max-w-full"
                  variant="selected"
                  :leading-icon="o.data.group === 'Properties' ? TYPE_ICON[o.data.type] : undefined"
                />
              </SelectDropdownTrigger>
            </template>
            <template #dropdown>
              <ModelInputMenu
                :items="inputIdOptions"
                :selected-input-ids="selectedInputIdOptions.map((o) => o.id)"
                @toggle:property="propertyStore.toggleInputId($event)"
                @toggle:library-item="onToggleLibraryItem($event)"
                @close="$emit('close')"
                @open:library="onOpenLibrary"
              />
            </template>
          </SelectDropdown>
          <template v-if="PropertyType.user_select === propertyStore.editedType">
            <PropertySidebarLabel>Users</PropertySidebarLabel>
            <UserListMenu>
              <template #trigger="{ selectedUsers }">
                <SelectDropdownTrigger
                  inline
                  placeholder="Add..."
                >
                  <BadgeItem
                    v-for="o in selectedUsers"
                    :key="o.value"
                    :label="o.value"
                    size="sm"
                    class="max-w-full"
                    variant="selected"
                  />
                </SelectDropdownTrigger>
              </template>
            </UserListMenu>
          </template>
        </div>
        <div
          v-if="!['manual', 'ocr', 'whisper'].includes(propertyStore.editedTool)"
          class="mt-1"
        >
          <MentionableTextInput
            :code-style="propertyStore.editedTool === 'code'"
            :value="propertyStore.editedDescription || ''"
            :items="
              inputIdOptions.map((option) => ({
                ...option.data,
                icon: option.data.group === 'Properties' ? TYPE_ICON[option.data.type] : undefined,
              }))
            "
            @update:text="propertyStore.editedDescription = $event"
            @add:mention="onAddMention"
            @remove:mention="onRemoveMention"
            @open:library="onOpenLibrary"
          />
        </div>
        <div
          v-if="
            isRecomputeEnabled && permissionStore.currentProjectPermissions.recalculate_entities
          "
        >
          <DarwinButton
            variant="neutral"
            size="md"
            class="mt-1 w-full self-stretch"
            :disabled="disabled"
            @click="onRecomputeStaleFields"
          >
            <template #leading-icon>
              <IconSprite icon="process" />
            </template>
            Recompute all stale fields
          </DarwinButton>
        </div>

        <template
          v-if="
            PropertyType.single_select === propertyStore.editedType ||
            PropertyType.multi_select === propertyStore.editedType
          "
        >
          <hr class="!col-[1/4] mx-[-4px] my-1 border-border-subtle" />
          <SelectTypeOptions class="min-h-[110px]" />
        </template>
        <template v-else-if="PropertyType.collection === propertyStore.editedType">
          <hr class="!col-[1/4] mx-[-4px] my-1 border-border-subtle" />

          <CollectionTypeProperties
            class="min-h-[110px]"
            :error="collectionError"
          />
        </template>
      </div>

      <DividerLine
        class="w-full"
        color="subtle"
        :width="1"
      />
      <div class="flex w-full flex-col px-2 pb-0 pt-2">
        <ListMenuItem
          v-if="pinnedColumn.canPin"
          :icon="pinnedColumn.isPinned ? 'pin-fill' : 'pin'"
          :label="pinnedColumn.isPinned ? 'Unpin' : 'Pin'"
          role="button"
          @select="pinnedColumn.togglePin"
        />
        <ListMenuItem
          icon="hide"
          label="Hide from view"
          role="button"
          @select="$emit('hide')"
        />
        <ListMenuItem
          v-if="propertyStore.editedTool === 'manual'"
          icon="json"
          label="Use API to import data"
          role="button"
          @select="openURLInNewTab(GO_API_REFERENCE_URL)"
        />
        <ListMenuItem
          icon="automation"
          label="Use webhooks to integrate with other tools"
          role="button"
          @select="openURLInNewTab(GO_WEBHOOKS_REFERENCE_URL)"
        />
        <ListMenuItem
          icon="automation"
          label="Trigger Zapier when property is complete"
          role="button"
          @select="
            openURLInNewTab(
              goZapierUrlForCompleteField(workspaceId, projectId, propertyStore.property?.id ?? ''),
            )
          "
        />
      </div>
      <div class="flex w-full flex-col items-stretch gap-2 p-2">
        <DarwinButton
          variant="critical-subtle"
          size="md"
          class="self-stretch"
          aria-label="Delete Property"
          :disabled="disabled"
          @click="emit('delete')"
        >
          <template #leading-icon>
            <IconSprite icon="trash" />
          </template>
          Delete Property
        </DarwinButton>
      </div>
    </div>
  </div>
</template>
