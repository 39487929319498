<script lang="ts" setup>
import { useProjects, type Project } from '@/modules/Projects/useProjects'
import { useRecentProjects } from '@/modules/Projects/useRecentProjects'
import ProjectDropdown from '@/sharedComponents/ProjectDropdown.vue'
import DarwinButton from '@/uiKit/DarwinButton.vue'
import ProjectName from '@/modules/Project/ProjectName.vue'
import { computed } from 'vue'
import IconSprite from '@/uiKit/IconSprite.vue'
import { RouterLink } from 'vue-router'
import CircularProgress from '@/uiKit/CircularProgress.vue'
import ObjectURLImage from './ObjectURLImage.vue'
import { usePermissionsStore } from '../IdentityAndAccess/permissionsStore'

const props = defineProps<{ project: Project }>()

const projectRoute = computed(() => ({
  name: 'WorkspaceProject',
  params: { workspaceId: props.project.workspaceId, projectId: props.project.id },
}))

const projectsStore = useProjects()
const recentProjectsStore = useRecentProjects()

const onImageSrcError = () => {
  projectsStore.setCoverImageDownloadError(props.project.id, true)
  recentProjectsStore.setCoverImageDownloadError(props.project.id, true)
}

const permissionsStore = usePermissionsStore()
</script>

<template>
  <RouterLink
    :to="projectRoute"
    class="contents"
  >
    <div
      data-test="project"
      class="group relative box-border flex w-full flex-row items-center gap-3 self-stretch rounded-corner-12 bg-background-transparent p-4 transition has-[[data-state='open']]:bg-background-transparent-hovered hover:bg-background-transparent-hovered"
    >
      <div
        class="flex h-12 w-16 items-center justify-center rounded-corner-8 bg-background-gray-subtlest text-xl-18px-default text-text-subtle"
      >
        <IconSprite
          v-if="project.coverImageDownloadError"
          icon="file"
          size="lg"
          class="h-12 w-16"
        />
        <CircularProgress
          v-else-if="project.coverImageUploading"
          size="sm"
          class="animate-spin"
        />
        <ObjectURLImage
          v-else
          class="h-12 w-16 min-w-16 rounded-corner-8 object-cover"
          :url="project.coverImageUrls.low"
          :loading="project.coverImageUploading ?? false"
          @error="onImageSrcError"
        />
      </div>
      <div class="flex shrink flex-col">
        <div class="line-clamp-1 text-md-13px-bold text-text">
          <ProjectName :project="project" />
        </div>
        <div class="line-clamp-1 text-sm-12px-light text-text-subtlest">
          {{ project.propertyCount }} properties
        </div>
      </div>
      <div class="flex flex-1 items-center justify-end gap-1">
        <DarwinButton
          class="opacity-0 transition-opacity group-hover:opacity-100"
          variant="outline"
          flair="soft"
          size="sm"
          tabindex="-1"
          role="presentation"
        >
          Open project
        </DarwinButton>
        <ProjectDropdown
          :workspace-id="project.workspaceId"
          :project-id="project.id"
          :can-delete="!!permissionsStore.allProjectPermissions[project.id]?.delete_projects"
          :can-update-cover-image="
            !!permissionsStore.allProjectPermissions[project.id]?.update_projects
          "
          size="lg"
        />
      </div>
    </div>
  </RouterLink>
</template>
