<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import ModalDialog from '@/uiKit/ModalDialog.vue'
import ListMenuContainer from '@/uiKit/ListMenuContainer.vue'
import { RouterLink, useRouter } from 'vue-router'

import { createView } from '@/backend/createView'
import { omit } from '@/shared/utils'
import { updateView } from '@/backend/updateView'
import { removeView } from '@/backend/removeView'
import ConfirmationDialog from '@/uiKit/ConfirmationDialog.vue'
import { serializePropertyLayout, useProject, type View } from '@/modules/Project/useProject'
import { useWorkspaces } from '@/modules/Workspaces/useWorkspaces'
import DividerLine from '@/uiKit/DividerLine.vue'
import ListMenuItem from '@/uiKit/ListMenuItem.vue'
import FloatingMenu from '@/uiKit/FloatingMenu.vue'
import InlineTextField from '@/uiKit/InlineTextField.vue'
import IconSprite from '@/uiKit/IconSprite.vue'
import IntroStagesTutotrial from '@/modules/Project/IntroStagesTutotrial.vue'
import { useStorage } from '@vueuse/core'

import { getViewColor } from './utils'
import { ANALYTICS_EVENT, useAnalytics } from '@/sharedComposables/useAnalytics'
import IconButton from '@/uiKit/IconButton.vue'
import LabelValueMenuItem from '@/sharedComponents/LabelValueMenuItem.vue'
import { FeatureFlag, useFeatureFlags } from '../App/useFeatureFlags'
import AskGo from '@/modules/Project/AskGo.vue'
import ProjectFilters from './ProjectFilters.vue'
import { useAskGo } from './useAskGo'
import { useRoutes } from '../App/useRoutes'
import { usePermissionsStore } from '../IdentityAndAccess/permissionsStore'
import { useResolveSubProjectRoute } from './useResolveSubProjectRoute'

const stagedViewIdToDelete = ref<null | string>(null)
const viewIdBeingRenamed = ref<null | string>(null)
const viewHasError = ref(false)
const localViewName = ref('')

const showIntroModal = ref(false)
const hasSeenStagesTutorial = useStorage<boolean>('has-seen-stages-tutorial', false)

const tempValue = ref('')
const projectStore = useProject()
const router = useRouter()
const workspacesStore = useWorkspaces()
const { captureAnalyticsEvent } = useAnalytics()
const views = computed(() => projectStore.views)

const MenuIds = {
  Rename: 'rename',
  Assign: 'assign',
  Delete: 'delete',
} as const

const { currentlyInEntityView, currentlyInSubProject, route } = useRoutes()

const addNewView = async () => {
  if (!workspacesStore.currentWorkspace || !projectStore.projectId || !tempValue.value.trim()) {
    return
  }
  const result = await createView({
    workspaceId: workspacesStore.currentWorkspace.id,
    projectId: projectStore.projectId,
    name: tempValue.value.trim(),
    propertyIds: projectStore.properties[0]?.id ? [projectStore.properties[0].id] : [],
  })
  tempValue.value = ''

  if (result.ok) {
    projectStore.upsertView({
      id: result.data.id,
      name: result.data.name,
      propertyIds: result.data.property_ids,
      propertyLayouts: result.data.property_layouts.map(serializePropertyLayout),
      filters: [],
      propertyOptions: result.data.property_options,
      assignablePropertyId: result.data.assignable_property_id,
      numPinnedProperties: result.data.num_pinned_properties,
    })
    tempValue.value = ''

    captureAnalyticsEvent(ANALYTICS_EVENT.STAGE_CREATED)

    if (currentlyInSubProject.value) {
      router.push({
        name: 'WorkspaceSubProjectTableView',
        params: {
          workspaceId: route.params.workspaceId,
          projectId: route.params.projectId,
          parentProjectId: route.params.parentProjectId,
          viewId: result.data.id,
        },
        query: { parentEntityId: route.query.parentEntityId },
      })
      return
    }

    router.push({
      name: 'WorkspaceProjectTableView',
      params: {
        workspaceId: workspacesStore.currentWorkspace?.id,
        projectId: projectStore.projectId,
        viewId: result.data.id,
      },
    })
  }
}

const isViewActive = (view: View) => projectStore.activeView?.id == view.id

const resolveSubProjectRoute = useResolveSubProjectRoute()
const routerLocation = (view: View) => {
  if (currentlyInEntityView.value && currentlyInSubProject.value) {
    return view.name === 'main'
      ? {
          name: 'WorkspaceSubProjectEntityView',
          params: {
            workspaceId: route.params.workspaceId,
            projectId: route.params.projectId,
            parentProjectId: route.params.parentProjectId,
            entityId: route.params.entityId,
          },
          query: { parentEntityId: route.query.parentEntityId },
        }
      : {
          name: 'WorkspaceSubProjectEntitySubView',
          params: {
            workspaceId: route.params.workspaceId,
            projectId: route.params.projectId,
            parentProjectId: route.params.parentProjectId,
            entityId: route.params.entityId,
            viewId: view.id,
          },
          query: { parentEntityId: route.query.parentEntityId },
        }
  }

  if (currentlyInEntityView.value) {
    return view.name === 'main'
      ? {
          name: 'WorkspaceProjectEntityView',
          params: {
            workspaceId: route.params.workspaceId,
            projectId: route.params.projectId,
            entityId: route.params.entityId,
          },
        }
      : {
          name: 'WorkspaceProjectEntitySubView',
          params: {
            workspaceId: route.params.workspaceId,
            projectId: route.params.projectId,
            entityId: route.params.entityId,
            viewId: view.id,
          },
        }
  }

  if (currentlyInSubProject.value) {
    return view.name === 'main'
      ? resolveSubProjectRoute({
          parentEntityId: route.query.parentEntityId,
          workspaceId: route.params.workspaceId,
          parentProjectId: route.params.parentProjectId,
          subProjectId: route.params.projectId,
        })
      : {
          name: 'WorkspaceSubProjectTableView',
          params: {
            workspaceId: route.params.workspaceId,
            projectId: route.params.projectId,
            parentProjectId: route.params.parentProjectId,
            viewId: view.id,
          },
          query: { parentEntityId: route.query.parentEntityId },
        }
  }

  return view.name === 'main'
    ? {
        name: 'WorkspaceProjectTable',
        params: {
          workspaceId: workspacesStore.currentWorkspace?.id,
          projectId: projectStore.projectId,
        },
      }
    : {
        name: 'WorkspaceProjectTableView',
        params: {
          workspaceId: workspacesStore.currentWorkspace?.id,
          projectId: projectStore.projectId,
          viewId: view.id,
        },
      }
}

const style = (view: View) => {
  const color = getViewColor(view, projectStore.properties)

  return {
    color:
      view.name === 'main' || color === 'rainbow-17'
        ? 'var(--color-icon-subtle)'
        : `var(--${color}-saturated)`,
  }
}

const deleteView = async () => {
  const workspaceId = workspacesStore.currentWorkspace?.id
  const projectId = projectStore.projectId
  const viewId = stagedViewIdToDelete.value

  if (!workspaceId || !projectId || !viewId) {
    return
  }

  const result = await removeView(workspaceId, projectId, viewId)
  if (result.ok) {
    projectStore.removeView(viewId)
    projectStore.setActiveViewId()

    if (currentlyInSubProject.value) {
      router.push(
        resolveSubProjectRoute({
          parentEntityId: route.query.parentEntityId,
          workspaceId: route.params.workspaceId,
          parentProjectId: route.params.parentProjectId,
          subProjectId: route.params.projectId,
        }),
      )
    } else {
      router.push({
        name: 'WorkspaceProjectTable',
        params: {
          workspaceId: workspacesStore.currentWorkspace?.id,
          projectId: projectStore.projectId,
        },
      })
    }
  }
  stagedViewIdToDelete.value = null
}

const updateLocalValue = (newValue: string) => {
  localViewName.value = newValue
  viewHasError.value = newValue.length < 3
}

const renameView = async (newName: string) => {
  const workspaceId = workspacesStore.currentWorkspace?.id
  const projectId = projectStore.projectId
  const viewId = viewIdBeingRenamed.value
  const view = views.value.find((v) => v.id === viewId)

  if (!workspaceId || !projectId || !viewId || !view) {
    return
  }

  viewHasError.value = false
  if (newName.length < 3) {
    viewHasError.value = true
    return
  }

  const result = await updateView({
    workspaceId: workspaceId,
    projectId: projectId,
    viewId: viewId,
    name: newName,
    propertyIds: view.propertyIds ?? [],
    propertyLayouts: view.propertyLayouts,
    filters: view.filters,
    propertyOptions: view.propertyOptions ?? [],
    assignablePropertyId: view.assignablePropertyId,
  })

  projectStore.upsertView({
    id: viewId,
    name: newName,
    propertyIds: view.propertyIds,
    propertyLayouts: view.propertyLayouts,
    filters: view.filters,
    propertyOptions: view.propertyOptions,
    assignablePropertyId: view.assignablePropertyId,
    numPinnedProperties: view.numPinnedProperties,
  })

  if (!result.ok) {
    viewHasError.value = true
    return
  }

  viewIdBeingRenamed.value = null
}

const revertViewName = () => {
  viewIdBeingRenamed.value = null
}

const closeIntroModal = () => {
  showIntroModal.value = false
  hasSeenStagesTutorial.value = true
}

watch(
  () => viewIdBeingRenamed.value,
  (newValue) => {
    if (newValue === null) return
    const view = views.value.find((v) => v.id === newValue)
    if (view) {
      localViewName.value = view.name
    }
    router.push({
      name: 'WorkspaceProjectTableView',
      params: {
        workspaceId: workspacesStore.currentWorkspace?.id,
        projectId: projectStore.projectId,
        viewId: newValue,
      },
    })
  },
)

const ASSIGNABLE_SUBMENU_ID = 'assignable-submenu'
const assignableSubmenu = ref()
const assignableSubmenuIsOpen = ref(false)

const assignableProperties = computed(() =>
  projectStore.properties
    .filter((p) => p.type === 'user_select')
    .map((p) => ({
      id: p.id,
      data: {
        label: p.name,
      },
    })),
)

const assignView = async (propertyId: string, viewId: string) => {
  const workspaceId = workspacesStore.currentWorkspace?.id
  const projectId = projectStore.projectId
  const view = views.value.find((v) => v.id === viewId)

  if (!workspaceId || !projectId || !viewId || !view) {
    return
  }

  let result
  if (view.assignablePropertyId === propertyId) {
    result = await updateView({
      workspaceId: workspaceId,
      projectId: projectId,
      viewId: viewId,
      name: view.name,
      propertyIds: view.propertyIds ?? [],
      propertyLayouts: view.propertyLayouts,
      filters: view.filters,
      assignablePropertyId: null,
      propertyOptions: view.propertyOptions ?? [],
    })
  } else {
    result = await updateView({
      workspaceId: workspaceId,
      projectId: projectId,
      viewId: viewId,
      name: view.name,
      propertyIds: view.propertyIds ?? [],
      propertyLayouts: view.propertyLayouts,
      filters: view.filters,
      assignablePropertyId: propertyId,
      propertyOptions: view.propertyOptions ?? [],
    })
  }

  if (!result.ok) {
    return
  }

  projectStore.upsertView({
    id: viewId,
    name: view.name,
    propertyIds: view.propertyIds,
    propertyLayouts: view.propertyLayouts,
    filters: view.filters,
    assignablePropertyId: result.data.assignable_property_id,
    propertyOptions: view.propertyOptions,
    numPinnedProperties: view.numPinnedProperties,
  })
}

const assignedUserProperty = (view: View) => {
  const userProperty = projectStore.properties.find((p) => p.id === view.assignablePropertyId)
  return userProperty
}

const isWorkAssignmentEnabled = useFeatureFlags(FeatureFlag.WORK_ASSIGNENT)
const isAskGoEnabled = useFeatureFlags(FeatureFlag.ASK_GO_PROJECT_CREATION)

const permissionsStore = usePermissionsStore()

const askGoStore = useAskGo()
</script>

<template>
  <div
    class="flex items-center justify-between border-t border-border-subtle bg-surface-secondary-persist pr-2.5"
  >
    <!-- eslint-disable tailwindcss/no-custom-classname -->
    <div class="no-scrollbar flex items-center gap-2.5 overflow-x-scroll px-2.5 py-2">
      <ProjectFilters />

      <div class="flex items-center gap-0.5">
        <div
          v-for="(view, index) in views"
          :key="view.id"
          class="flex h-7 items-center gap-0.5"
        >
          <RouterLink
            :to="routerLocation(view)"
            :aria-label="`View to '${view.name}'`"
            class="group/tab relative"
            data-test="view-tab"
          >
            <div
              class="flex gap-1 whitespace-nowrap text-nowrap rounded-corner-8 px-3 py-1.5 text-center text-sm-12px-default"
              :class="[
                isViewActive(view)
                  ? `${viewHasError ? 'bg-background-critical-subtle' : 'bg-surface-primary'} shadow-xs`
                  : 'text-text-subtle',
                !viewIdBeingRenamed &&
                  'hover:bg-background-transparent-hovered active:bg-background-transparent-pressed',
              ]"
            >
              <IconSprite
                icon="table"
                :style="style(view)"
              />

              <InlineTextField
                v-if="viewIdBeingRenamed === view.id"
                autofocus
                size="xs"
                :value="localViewName"
                :error="viewHasError"
                class="inline h-4 !bg-background-transparent"
                :input-attrs="{ size: Math.max(5, view.name.length).toString() }"
                @submit="renameView"
                @input="updateLocalValue"
                @blur="renameView(localViewName)"
                @esc="revertViewName"
              />
              <p
                v-else
                data-sentry="unmask"
              >
                {{ view.name === 'main' ? 'Main' : view.name }}
              </p>
            </div>

            <div
              v-if="viewIdBeingRenamed === null && view.name !== 'main'"
              class="invisible absolute inset-0 rounded-corner-8 bg-gradient-to-l from-[20px] to-background-transparent"
              :class="[
                isViewActive(view) ? 'from-surface-primary' : 'bg-background-gray-sunken-hovered',
                !isViewActive(view) &&
                  permissionsStore.currentProjectPermissions.manage_views &&
                  'from-surface-tertiary-persist',
                permissionsStore.currentProjectPermissions.manage_views &&
                  'group-hover/tab:visible',
              ]"
            >
              <FloatingMenu
                v-if="permissionsStore.currentProjectPermissions.manage_views"
                @select="
                  (id) => {
                    switch (id) {
                      case MenuIds.Rename:
                        viewIdBeingRenamed = view.id
                        return
                      case MenuIds.Delete:
                        stagedViewIdToDelete = view.id
                        return
                    }
                  }
                "
              >
                <template #trigger="{ triggerProps }">
                  <IconButton
                    v-if="view.name !== 'main'"
                    size="xs"
                    icon="chevron-bottom"
                    variant="transparent"
                    class="absolute right-1.5 top-1/2 -translate-y-1/2"
                    v-bind="omit(triggerProps, ['disabled'])"
                    aria-label="View options"
                    @click.prevent
                  />
                </template>
                <template #content="{ contentProps, getItemProps }">
                  <ListMenuContainer
                    class="w-[280px] min-w-max"
                    v-bind="contentProps"
                  >
                    <div
                      v-if="isWorkAssignmentEnabled"
                      class="w-full p-0.5"
                    >
                      <FloatingMenu @select="(id) => assignView(id, view.id)">
                        <template #item-trigger="{ itemTriggerProps }">
                          <LabelValueMenuItem
                            id="assignable-combobox"
                            label="Assignable via"
                            :class="{
                              'bg-background-transparent-hovered': assignableSubmenuIsOpen,
                            }"
                            :value="
                              view.assignablePropertyId ? assignedUserProperty(view)?.name : ''
                            "
                            :submenu="{
                              id: ASSIGNABLE_SUBMENU_ID,
                              isOpen: assignableSubmenuIsOpen,
                            }"
                            v-bind="itemTriggerProps"
                          />
                        </template>
                        <template
                          #content="{ contentProps: subProps, getItemProps: getSubItemProps }"
                        >
                          <ListMenuContainer
                            v-bind="subProps"
                            ref="assignableSubmenu"
                            class="min-w-[240px] p-0.5"
                            aria-label="Select a User property"
                          >
                            <ListMenuItem
                              v-for="item in assignableProperties"
                              :key="item.id"
                              v-bind="omit(getSubItemProps({ value: item.id }), ['onSelect'])"
                              :label="item.data.label"
                              default-hover-disabled
                              icon="user"
                            >
                              <template #prefix>
                                <IconSprite
                                  :icon="item.id === view.assignablePropertyId ? 'check' : 'blank'"
                                  class="mr-1 text-icon-subtle"
                                />
                              </template>
                            </ListMenuItem>
                          </ListMenuContainer>
                        </template>
                      </FloatingMenu>
                    </div>
                    <div
                      v-if="isWorkAssignmentEnabled"
                      class="w-full border-t border-background-gray-subtlest"
                    ></div>
                    <div class="w-full p-0.5">
                      <ListMenuItem
                        label="Rename"
                        icon="edit"
                        v-bind="omit(getItemProps({ value: MenuIds.Rename }), ['onSelect'])"
                      />
                    </div>
                    <div
                      v-if="!isWorkAssignmentEnabled"
                      class="w-full border-t border-background-gray-subtlest"
                    ></div>
                    <div class="w-full p-0.5">
                      <ListMenuItem
                        critical
                        label="Delete view"
                        icon="trash"
                        v-bind="omit(getItemProps({ value: MenuIds.Delete }), ['onSelect'])"
                      />
                    </div>
                  </ListMenuContainer>
                </template>
              </FloatingMenu>
            </div>
          </RouterLink>
          <DividerLine
            color="default"
            direction="vertical"
            :width="1"
            class="h-7 py-1.5"
            :class="[
              !isViewActive(view) && index < views.length - 1 && !isViewActive(views[index + 1])
                ? 'opacity-100'
                : 'opacity-0',
            ]"
          />
        </div>
        <InlineTextField
          v-if="permissionsStore.currentProjectPermissions.manage_views"
          class="min-w-56 grow"
          icon="plus-fill"
          :value="tempValue"
          size="sm"
          placeholder="New view"
          @focus="!hasSeenStagesTutorial && (showIntroModal = true)"
          @input="tempValue = $event"
          @change="addNewView"
        />
      </div>
    </div>
    <AskGo
      v-if="isAskGoEnabled && permissionsStore.hasAskGoPermission && askGoStore.isAskGoAvailable"
    />

    <ConfirmationDialog
      :open="stagedViewIdToDelete !== null"
      title="Delete this view?"
      description="This view will be deleted immediately. You can't undo this action."
      @confirm="deleteView"
      @close="stagedViewIdToDelete = null"
    />

    <ModalDialog
      :open="showIntroModal"
      aria-label="Create a new view tutorial"
      @close="closeIntroModal"
    >
      <IntroStagesTutotrial @close="closeIntroModal" />
    </ModalDialog>
  </div>
</template>
