<script setup lang="ts">
import { ref, watch, toRef, computed } from 'vue'

import { useTableCellFocus } from './useTableCellFocus'
import { RouterLink } from 'vue-router'
import IconSprite from '@/uiKit/IconSprite.vue'
import { useResolveSubProjectRoute } from './useResolveSubProjectRoute'

const props = defineProps<{
  workspaceId: string
  projectId: string
  collectionProjectId: string
  entityId: string
  value: string
  isFocused: boolean
  isSelected: boolean
  isModelOutput?: boolean
  viewId?: string
}>()

const target = ref<HTMLElement>()

/**
 * The value of the contenteditable when the cell enters edit mode. This will
 * either be:
 * - props.value if the user has pressed enter
 * - an empty string if the user has pressed backspaces, delete, or a printable character
 * This needs to be set whenever the cell enters edit mode, otherwise the contenteditable
 * 'remembers' its previous value, which causes bugs when escaping.
 */
const startValue = ref(props.value)

/**
 * The current value of the text inside of the contenteditable. Is used:
 * 1. To emit an updated value with the save event
 * 2. For optimistic UI when submitting changes
 */
const localValue = ref('')

// A stateless approach to the editable, by directly binding to the prop.value and emiting change,
// does not work, because we lose focus on every edit and re-update.
// So we have to store a local value.
watch(
  () => props.value,
  () => {
    localValue.value = props.value
    startValue.value = props.value
  },
  { immediate: true },
)

useTableCellFocus({
  cell: target,
  isFocused: toRef(props, 'isFocused'),
  isSelected: toRef(props, 'isSelected'),
})

const resolveCollectionRoute = useResolveSubProjectRoute()
const collectionRoute = computed(() =>
  resolveCollectionRoute({
    parentEntityId: props.entityId,
    subProjectId: props.collectionProjectId,
    parentProjectId: props.projectId,
    workspaceId: props.workspaceId,
  }),
)
</script>

<template>
  <div
    ref="target"
    class="relative size-full outline-none"
  >
    <div
      data-table-cell-content
      class="line-clamp-1 flex h-8 basis-0 items-center truncate rounded-corner-4 px-1 py-2 focus-within:box-border"
    >
      <RouterLink
        title="Open collection"
        :to="collectionRoute"
        aria-label="Open collection"
        @click.stop
      >
        <div
          v-if="localValue"
          class="flex h-5 items-center gap-1 rounded-corner-6 px-1 hover:bg-background-blue-subtle"
        >
          <IconSprite
            class="text-icon-primary"
            icon="collection-fill"
            size="xs"
          />
          <p
            class="border border-background-transparent border-b-icon-gray-subtle text-sm-12px-default text-icon-primary hover:border-b-background-transparent"
          >
            {{ `${localValue} collection ${localValue === '1' ? 'item' : 'items'}` }}
          </p>
        </div>
      </RouterLink>
    </div>
  </div>
</template>
