<script setup lang="ts">
const props = defineProps<{ sourceId: number }>()

const label = (props.sourceId + 1).toString().padStart(2, '0')
</script>

<template>
  <button
    :aria-label="`Go to source ${label}`"
    class="mx-0.5 h-[14px] min-w-[23px] rounded-corner-8 bg-background-stages-model px-1.5 py-px text-xxs-8px-bold text-text-inverted-irreversible outline-2 outline-background-stages-model-subtle-pressed hover:outline focus-visible:outline disabled:opacity-30"
  >
    {{ label }}
  </button>
</template>
