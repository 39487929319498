import { createEventHook } from '@vueuse/core'
import { acceptHMRUpdate, defineStore } from 'pinia'
import { computed, ref, watch } from 'vue'

import { createSession } from '@/backend/createSession'
import { listSessions } from '@/backend/listSessions'
import { type SessionResponse } from '@/backend/types'
import { useWorkspaces } from '@/modules/Workspaces/useWorkspaces'
import { useDataLoader } from '@/sharedComposables/useDataLoader'

import { setMessageMetadata } from '@/backend/setMessageMetadata'
import { usePermissionsStore } from '@/modules/IdentityAndAccess/permissionsStore'
import { useModelProviders } from '../WorkspaceSettings/modelProvidersStore'
import { useProject, type Property } from './useProject'

export type Action = {
  action: string
  metadata: {
    [key: string]: string | undefined
  }
}

export type Message = {
  actions?: Action[]
  authorId: string | null
  filesUploaded: number
  id: string
  text: string | null
  timestamp: string
  score?: 1 | -1 | null | undefined
}

export type Session = {
  id: string
  messages: Message[]
  projectId: string
  userId?: string
}

export const serializeSession = (payload: SessionResponse): Session => {
  return {
    id: payload.id,
    messages: payload.messages.map((m) => ({
      actions: m.actions,
      authorId: m.author_id,
      filesUploaded: m.files_uploaded,
      id: m.id,
      text: m.text,
      timestamp: m.timestamp,
      score: m.metadata.score,
    })),
    projectId: payload.project_id,
    userId: payload.user_id,
  }
}

export const useAskGo = defineStore('ask-go', () => {
  const askEvent = createEventHook<string>()
  const projectStore = useProject()
  const workspacesStore = useWorkspaces()

  const sessions = ref<Session[]>([])

  watch(
    () => workspacesStore.currentWorkspace,
    async (currentWorkspace) => {
      if (currentWorkspace !== undefined) {
        const { load } = useDataLoader(() => listSessions({ workspaceId: currentWorkspace.id }))

        const result = await load()
        if (result.ok) {
          setSessions(result.data.data.map(serializeSession))
        }
      }
    },
    { immediate: true },
  )

  const setSessions = (newSessions: Session[]) => {
    sessions.value = newSessions
  }

  const updateSession = (session: Session) => {
    const index = sessions.value.findIndex((s) => s.projectId === session.projectId)
    if (index >= 0) {
      sessions.value.splice(index, 1, session)
    } else {
      sessions.value.push(session)
    }
  }

  const currentSession = computed(() => {
    if (!projectStore.projectId) return

    return sessions.value.find((s) => s.projectId === projectStore.projectId)
  })

  const isAskingGo = computed(() => {
    if (!currentSession.value) return
    if (currentSession.value.messages.length === 0) return

    const lastMessage = currentSession.value.messages[currentSession.value.messages.length - 1]

    const isLastMessageFromUser = lastMessage.authorId !== null
    const isLastMessageEmpty = lastMessage.text === null || lastMessage.text.length === 0

    return isLastMessageFromUser || isLastMessageEmpty
  })

  const isOpen = ref(false)

  const open = () => {
    isOpen.value = true
  }

  const close = () => {
    isOpen.value = false
  }

  const getSession = async () => {
    if (!currentSession.value) {
      await newSession()
    }
  }

  async function newSession() {
    if (!workspacesStore.currentWorkspace || !projectStore.projectId) return
    const response = await createSession({
      projectId: projectStore.projectId,
      workspaceId: workspacesStore.currentWorkspace.id,
    })

    if (response.ok) {
      updateSession(serializeSession(response.data))
    }
  }

  const highlightedInput = ref<Property['inputs'][0]>()

  const highlightProperty = (propertyId?: string) => {
    if (!propertyId) {
      highlightedInput.value = undefined
      return
    }

    highlightedInput.value = { propertyId }
  }

  const modelProviderStore = useModelProviders()

  const permissionsStore = usePermissionsStore()

  const isAskGoAvailable = computed(
    () => modelProviderStore.providers.open_ai.enabled && permissionsStore.hasAskGoPermission,
  )

  const updateMessageScore = async (messageId: string, score: 1 | -1 | null | undefined) => {
    if (!workspacesStore.currentWorkspace || !currentSession.value) return

    // Optimistically update the local state
    const updatedSession = {
      ...currentSession.value,
      messages: currentSession.value.messages.map((message) =>
        message.id === messageId ? { ...message, score } : message,
      ),
    }
    updateSession(updatedSession)

    // Make the API call
    const response = await setMessageMetadata({
      workspaceId: workspacesStore.currentWorkspace.id,
      sessionId: currentSession.value.id,
      messageId,
      score,
    })

    // If the API call fails, revert the optimistic update
    if (!response.ok) {
      updateSession(currentSession.value)
    }
  }

  return {
    currentSession,
    sessions,
    setSessions,
    updateSession,
    isAskingGo,
    isOpen,
    open,
    close,
    askEvent,
    getSession,
    newSession,
    highlightedInput,
    highlightProperty,
    isAskGoAvailable,
    updateMessageScore,
  }
})

// Add this line at the end of the file
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAskGo, import.meta.hot))
}
