export const keyboardTargetIsInput = (event: KeyboardEvent): boolean => {
  const target = event.target
  if (target instanceof HTMLElement) {
    const isTypingIntoTextField =
      target.tagName === 'INPUT' || target.tagName === 'TEXTAREA' || target.isContentEditable
    if (isTypingIntoTextField) {
      return true
    }
  }

  return false
}
