<script setup lang="ts">
/**
 * Primary component for '/:workspaceId'
 *
 * Renders workspace details and a list of projects in the workspace
 */
import { computed, toRef, watch } from 'vue'
import { RouterView } from 'vue-router'

import { useWorkspaces } from './useWorkspaces'

import SidebarLayout from '@/sharedComponents/SidebarLayout.vue'
import PaywallDialog from '@/modules/Billing/PaywallDialog.vue'
import { usePaywallStore } from '../Billing/paywall'
import { useBilling } from '../Billing/useBilling'
import EnterprisePaywallDialog from '../Billing/EnterprisePaywallDialog.vue'
import { useLaunchDarklyContext } from './useLaunchDarklyContext'
import TemplateModal from './TemplateModal.vue'
import CsvImportModal from './CsvImportModal.vue'
import LibraryDialog from '@/modules/Library/LibraryDialog.vue'
import { useLoadWorkspaceData } from './useLoadWorkspaceData'

const props = defineProps<{ workspaceId: string }>()
const workspacesStore = useWorkspaces()
const workspace = computed(() => workspacesStore.workspaces.find((w) => w.id === props.workspaceId))

watch(workspace, () => workspacesStore.setCurrentWorkspace(workspace.value), { immediate: true })

useLoadWorkspaceData(toRef(props, 'workspaceId'))

const paywallStore = usePaywallStore()

const billingStore = useBilling()

useLaunchDarklyContext(workspace)
</script>

<template>
  <SidebarLayout>
    <template #sidebar>
      <RouterView name="Sidebar" />
    </template>
    <template #page-header>
      <RouterView name="BreadCrumbs" />
    </template>
    <template #page>
      <RouterView />
      <component
        :is="
          billingStore.activePlan?.name === 'enterprise' ? EnterprisePaywallDialog : PaywallDialog
        "
        :open="paywallStore.dialogIsOpen"
        :workspace-id="workspaceId"
        @close="paywallStore.dialogIsOpen = false"
      />
    </template>
  </SidebarLayout>

  <LibraryDialog :workspace-id="workspaceId" />
  <TemplateModal :workspace-id="workspaceId" />
  <CsvImportModal :workspace-id="workspaceId" />
</template>
