import type { ProjectResponse } from '@/backend/types'
import {
  serializeProperty,
  serializeView,
  type Property,
  type View,
} from '@/modules/Project/useProject'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

export type Project = {
  id: ProjectResponse['id']
  name: ProjectResponse['name']
  workspaceId: ProjectResponse['workspace_id']
  propertyCount: number
  parentProperty?: Property
  coverImageUrls: ProjectResponse['cover_image_urls']
  coverImageDownloadError?: boolean
  coverImageUploading?: boolean
  views?: View[]
}

export const serializeProject = (project: ProjectResponse): Project => {
  return {
    id: project.id,
    name: project.name,
    propertyCount: project.properties.length,
    workspaceId: project.workspace_id,
    parentProperty: project.parent_property
      ? serializeProperty(project.parent_property)
      : undefined,
    coverImageUrls: project.cover_image_urls,
    views: project.views.map(serializeView),
  }
}

export const useProjects = defineStore('projects', () => {
  const addProject = (project: Project) => {
    projectMap.value[project.id] = project
  }

  const projectMap = ref<Record<Project['id'], Project | undefined>>({})
  const projects = computed<Project[]>(() =>
    Object.values(projectMap.value).filter((p): p is Project => !!p),
  )

  const setProjects = (p: Project[]) => {
    projectMap.value = Object.fromEntries(p.map((p) => [p.id, p]))
  }

  const addProjects = (p: Project[]) => {
    p.forEach((p) => {
      projectMap.value[p.id] = p
    })
  }

  const updateProject = (id: string, data: Partial<Project>) => {
    const project = projectMap.value[id]
    if (!project) {
      return
    }
    projectMap.value[id] = { ...project, ...data }
  }

  const removeProject = (id: string) => {
    projectMap.value[id] = undefined
  }

  const mainProjects = computed(() =>
    projects.value.filter((p) => p && p.parentProperty === undefined),
  )

  const setCoverImageDownloadError = (id: string, value: boolean) => {
    const project = projects.value.find((p) => p.id === id)
    if (project) {
      project.coverImageDownloadError = value
    }
  }

  const setCoverImageUploading = (id: string, value: boolean) => {
    resetCoverImagesUploading()

    const project = projects.value.find((p) => p.id === id)
    if (project) {
      project.coverImageUploading = value
    }
  }

  const resetCoverImagesUploading = () => {
    for (let i = 0; i < projects.value.length; i++) {
      projects.value[i].coverImageUploading = false
    }
  }

  return {
    projectMap,
    addProject,
    addProjects,
    updateProject,
    removeProject,
    projects,
    setProjects,
    mainProjects,
    setCoverImageDownloadError,
    setCoverImageUploading,
  }
})
