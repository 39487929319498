<script setup lang="ts">
import { clamp } from '@vueuse/core'

import IconButton from '@/uiKit/IconButton.vue'
import FloatingMenu from '@/uiKit/FloatingMenu.vue'
import DarwinButton from '@/uiKit/DarwinButton.vue'
import ListMenuContainer from '@/uiKit/ListMenuContainer.vue'
import ListMenuItem from '@/uiKit/ListMenuItem.vue'
import { omit } from '@/shared/utils'
import { ref } from 'vue'
import type { ScaleType } from '@/sharedComposables/useNaturalZoom'

const props = withDefaults(
  defineProps<{
    scale: number | 'fit'
    minScale?: number
    maxScale?: number
    step?: number
    disableFit?: boolean
    /**
     * If true, the buttons will take up the full width of the container
     */
    fullWidthMode?: boolean
  }>(),
  {
    minScale: 0.25,
    maxScale: 2,
    step: 0.25,
    disableFit: false,
    inlineMode: false,
  },
)

/**
 * emits the closest step value to the current scale within the min and max scale
 */
const emit = defineEmits<{
  (e: 'update:scale', scale: ScaleType): void
}>()

const emitChange = (next: boolean) => {
  const { scale, step, minScale, maxScale } = props
  if (scale === 'fit') {
    emit('update:scale', 1)
    return
  }
  const multipliedScale = scale * 100
  const multipliedStep = step * 100
  const newScale = next
    ? Math.floor(multipliedScale / multipliedStep) * multipliedStep + multipliedStep
    : Math.ceil(multipliedScale / multipliedStep) * multipliedStep - multipliedStep

  const toEmit = clamp(newScale / 100, minScale, maxScale)
  if (toEmit !== scale) emit('update:scale', toEmit)
}

const menuOpen = ref(false)
</script>

<template>
  <div
    class="select-none items-center gap-0.5 rounded-lg p-0.5"
    :class="fullWidthMode ? 'flex bg-background-gray-subtlest' : 'inline-flex bg-surface-popover'"
  >
    <IconButton
      icon="minus"
      size="md"
      data-test="zoom-out-button"
      variant="transparent"
      :class="fullWidthMode && 'w-full'"
      @click="emitChange(false)"
    />
    <FloatingMenu
      disable-teleport
      :positioning="{ placement: 'top', offset: { mainAxis: 4, crossAxis: -2 } }"
      :open="menuOpen"
      @change:open="menuOpen = $event"
      @select="
        (value) => {
          emit('update:scale', value === 'fit' ? 'fit' : Number(value))
          menuOpen = false
        }
      "
    >
      <template #trigger="{ triggerProps }">
        <DarwinButton
          v-bind="triggerProps"
          size="xs"
          :class="fullWidthMode ? 'w-full' : 'w-12'"
          variant="transparent"
        >
          {{ scale === 'fit' ? 'Fit' : `${(scale * 100).toFixed(0)}%` }}
        </DarwinButton>
      </template>
      <template #content="{ contentProps, getItemProps }">
        <ListMenuContainer
          class="p-1 text-center"
          v-bind="contentProps"
        >
          <ListMenuItem
            v-for="s in [2, 1.75, 1.5, 1.25, 'fit', 1, 0.75, 0.5, 0.25].filter(
              (s) => s !== 'fit' || !disableFit,
            )"
            v-bind="omit(getItemProps({ value: s }), ['onSelect'])"
            :key="s"
            :label="typeof s === 'string' ? 'Fit' : `${(s * 100).toFixed(0)}%`"
          />
        </ListMenuContainer>
      </template>
    </FloatingMenu>
    <IconButton
      icon="plus"
      size="md"
      :class="fullWidthMode && 'w-full'"
      data-test="zoom-in-button"
      variant="transparent"
      @click="emitChange(true)"
    />
  </div>
</template>
