import type { ProjectResponse } from '@/backend/types'
import { usePermissionsStore } from '@/modules/IdentityAndAccess/permissionsStore'

/**
 * Some users can see all projects in a workspace, regardless of whether or not they
 * are assigned to them. Other users cannot. This composable checks if the user can
 * see a given project.
 */
export const useCanSeeProject = () => {
  const permissionsStore = usePermissionsStore()

  const canSeeProject = (project: Pick<ProjectResponse, 'properties'>) => {
    return (
      permissionsStore.workspaceRole !== 'worker' ||
      /**
       * When the user can't see unassigned projects, we use the property count as a proxy
       * for whether the project is assigned to the user. This is because it takes a property
       * (of type user_select) to assign a project to a user.
       *
       * There's a small chance the count could be 0 and the user IS assigned to the project,
       * but that would only happen if the assigned view has 0 properties, in which case there
       * is nothing for the user to see anyway, so we are safe to hide it.
       */
      project.properties.length > 0
    )
  }

  return canSeeProject
}
