import { defineStore } from 'pinia'
import { ref } from 'vue'

import type { UserMeResponse } from '@/backend/types'
import { useStorage } from '@vueuse/core'

export type User = {
  id: UserMeResponse['id']
  firstName: UserMeResponse['first_name']
  lastName: UserMeResponse['last_name']
  email: UserMeResponse['email']
  createdAt: Date
  acceptedTermsAndConditions: UserMeResponse['accepted_terms_and_conditions']
  marketingOptin: UserMeResponse['marketing_optin']
  signupCompleted: UserMeResponse['signup_completed']
  showTutorials: UserMeResponse['show_tutorials']
}

export type UserRequest = {
  firstName: UserMeResponse['first_name']
  lastName: UserMeResponse['last_name']
  email: UserMeResponse['email']
  acceptedTermsAndConditions: UserMeResponse['accepted_terms_and_conditions']
  marketingOptin: UserMeResponse['marketing_optin']
  signupCompleted: UserMeResponse['signup_completed']
  showTutorials: UserMeResponse['show_tutorials']
}

export const serializeUser = (response: UserMeResponse): User => ({
  id: response.id,
  firstName: response.first_name,
  lastName: response.last_name,
  email: response.email,
  createdAt: new Date(response.created_at),
  acceptedTermsAndConditions: response.accepted_terms_and_conditions,
  marketingOptin: response.marketing_optin,
  signupCompleted: response.signup_completed,
  showTutorials: response.show_tutorials,
})

export const deserializeUser = (user: UserRequest): Omit<UserMeResponse, 'id' | 'created_at'> => ({
  first_name: user.firstName,
  last_name: user.lastName,
  email: user.email,
  accepted_terms_and_conditions: user.acceptedTermsAndConditions,
  marketing_optin: user.marketingOptin,
  signup_completed: user.signupCompleted,
  show_tutorials: user.showTutorials,
})

export const useUser = defineStore('user', () => {
  const user = ref<User | null>(null)
  const invitationStatus = ref<'pending' | 'error' | 'valid' | 'idle'>('idle')
  const invitationErrorMessage = ref<string>('')

  /**  The project ID to which the user was invited (if any). */
  const invitationProjectId = useStorage<string | null>('invitation-project-id', null)

  const setUser = (newUser: User) => {
    user.value = newUser
  }

  const validateUserInvite = async (workspaceId: string, inviteToken: string) => {
    invitationStatus.value = 'pending'

    if (!workspaceId || !inviteToken) {
      invitationStatus.value = 'error'
      invitationErrorMessage.value = 'Invalid invitation URL.'
      return
    }

    // This avoids a playwright error with imports.
    const { acceptInvitation } = await import('@/backend/acceptInvitation')

    const response = await acceptInvitation({ inviteToken, workspaceId })

    if (!response.ok) {
      invitationStatus.value = 'error'
      invitationErrorMessage.value = response.error.message
      return
    }

    invitationStatus.value = 'valid'
  }

  return {
    user,
    setUser,
    validateUserInvite,
    invitationStatus,
    invitationErrorMessage,
    invitationProjectId,
  }
})
