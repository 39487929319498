import { defineStore } from 'pinia'
import { computed, ref, watch } from 'vue'

import { PropertyTool, PropertyType } from '@/backend/types'

import { ANALYTICS_EVENT, useAnalytics } from '@/sharedComposables/useAnalytics'
import { useProject, type Property } from './useProject'

/**
 * A pinia store backend for editing a project property.
 *
 * We use a store to enable the app to visually react to changes in the property,
 * for example, changes to input ids highlight table columns, etc.
 */
export const useProperty = defineStore('property', () => {
  const { captureAnalyticsEvent } = useAnalytics()
  const projectStore = useProject()

  const propIndex = computed(() =>
    projectStore.selectedPropertyId
      ? projectStore.propIdToIndex[projectStore.selectedPropertyId]
      : null,
  )

  /**
   * The currently selected property, for editing.
   */
  const property = computed(() =>
    propIndex.value !== null ? projectStore.properties[propIndex.value] : null,
  )

  const sidebarIsOpen = ref(false)

  /**
   * The edited name of the property. Set to original on prop selection.
   */
  const editedName = ref<string>('')

  /**
   * The edited type of the property. Set to original on prop selection.
   */
  const editedType = ref<PropertyType>(PropertyType.text)

  /**
   * The edited tool of the property. Set to original on prop selection.
   */
  const editedTool = ref<PropertyTool>(PropertyTool.manual)

  /**
   * The edited input ids of the property. Set to original on prop selection.
   */
  const editedInputs = ref<Property['inputs']>([])
  const includesInputId = (propertyId: string, entityId: string) =>
    editedInputs.value.some(
      (input) => input.propertyId === propertyId && (!entityId || input.entityId === entityId),
    )
  /**
   * Then the tool is 'gpt_4', this is the prompt.
   */
  const editedDescription = ref<string | null>(null)

  const editedConfig = ref<{
    removeOptions?: string[]
    upsertOptions?: {
      color?: string | null
      new_value?: string | null
      value: string
    }[]
    removeCollectionProperties?: string[]
    upsertCollectionProperties?: {
      type: PropertyType
      new_name?: string | null
      name: string
    }[]
    defaultOption?: string | null
  } | null>(null)

  watch(
    () => property.value && 'config' in property.value && property.value.config,
    () => {
      editedConfig.value =
        property.value &&
        'config' in property.value &&
        property.value.config &&
        'defaultOption' in property.value.config &&
        (editedType.value === 'single_select' ||
          editedType.value === 'multi_select' ||
          editedType.value === 'user_select')
          ? {
              defaultOption: property.value.config.defaultOption,
              removeOptions: undefined,
              upsertOptions: undefined,
            }
          : null
    },
  )

  /**
   * List of other properties in the project. These are input id candidates.
   *
   * NOTE: This is probably not final. Not all properties can be input ids, and
   * it's possible we will decide to only allow those properties to the left
   * of the current property in the table, to be input ids.
   */
  const otherProperties = computed(() =>
    projectStore.properties.filter((p) => p.id !== property.value?.id),
  )

  /**
   * Resets edited values to those of the original.
   * Should be called by a single app component, as a reaction to changing
   * `useProject().selectedPropertyId`
   */
  const reset = () => {
    editedName.value = property.value?.name ?? ''
    editedType.value = property.value?.type ?? PropertyType.text
    editedTool.value = property.value?.effectiveTool ?? property.value?.tool ?? PropertyTool.manual
    editedDescription.value = property.value?.description ?? null

    // Copy the inputs to avoid modifying the original
    editedInputs.value = [...(property.value?.inputs ?? [])]

    if (
      editedType.value === 'multi_select' ||
      editedType.value === 'single_select' ||
      editedType.value === 'user_select'
    ) {
      editedConfig.value = {
        upsertOptions: [],
        removeOptions: [],
        defaultOption: undefined,
      }
    } else {
      editedConfig.value = null
    }
  }

  const inputsHaveChanged = computed(
    () =>
      editedInputs.value.length !== property.value?.inputs.length ||
      !editedInputs.value.every(({ entityId, propertyId }) => {
        const hasMatch = property.value?.inputs.some(
          (input) => input.propertyId === propertyId && (!entityId || input.entityId === entityId),
        )
        return hasMatch
      }),
  )

  /**
   * Signals if any changes have been made to the property
   */
  const isDirty = computed(() => {
    return (
      editedName.value !== property.value?.name ||
      editedDescription.value !== property.value?.description ||
      editedTool.value !== (property.value?.effectiveTool ?? property.value?.tool) ||
      editedType.value !== property.value?.type ||
      inputsHaveChanged.value ||
      (editedConfig.value?.upsertOptions || []).length > 0 ||
      (editedConfig.value?.removeOptions || []).length > 0 ||
      (property.value &&
        'config' in property.value &&
        property.value.config &&
        'defaultOption' in property.value.config &&
        editedConfig.value?.defaultOption !== undefined &&
        editedConfig.value?.defaultOption !== property.value.config.defaultOption) ||
      (editedConfig.value?.upsertCollectionProperties || []).length > 0 ||
      (editedConfig.value?.removeCollectionProperties || []).length > 0
    )
  })

  /**
   * Selects or deselects an input id for the property
   */
  const toggleInputId = (propertyId: string, entityId?: string) => {
    const index = editedInputs.value.findIndex(
      (input) => input.propertyId === propertyId && (!entityId || input.entityId === entityId),
    )
    if (index > -1) {
      editedInputs.value.splice(index, 1)
      captureAnalyticsEvent(ANALYTICS_EVENT.INPUT_SELECTED, { propertyId, entityId, value: false })
    } else {
      editedInputs.value.push({ propertyId, entityId })
      captureAnalyticsEvent(ANALYTICS_EVENT.INPUT_SELECTED, { propertyId, entityId, value: true })
    }
  }

  const updateSelectOption = (
    { value: newValue, color: newColor }: { value: string; color?: string | null },
    oldValue: string,
  ) => {
    const isSelectType =
      property.value?.type === PropertyType.single_select ||
      property.value?.type === PropertyType.multi_select ||
      property.value?.type === PropertyType.user_select

    const pristineExistingOptions = isSelectType ? (property.value?.config?.options ?? []) : []
    const stagedUpsertOptions = editedConfig.value?.upsertOptions ?? []

    const existingOption = pristineExistingOptions.find((o) => o.value === oldValue)

    if (existingOption) {
      // existing option case
      const stagedIndex = stagedUpsertOptions.findIndex((o) => o.value === oldValue)
      const updateObject = {
        value: existingOption.value,
        new_value: newValue,
        color: newColor,
      }

      if (stagedIndex !== undefined && stagedIndex !== -1) {
        // staged case
        stagedUpsertOptions.splice(stagedIndex, 1, updateObject)
      } else {
        // stage new updated
        stagedUpsertOptions.push(updateObject)
      }
    } else {
      // new option case
      const stagedIndex = stagedUpsertOptions.findIndex((o) => o.value === oldValue)

      if (stagedIndex !== undefined && stagedIndex !== -1) {
        // staged case
        stagedUpsertOptions[stagedIndex].value = newValue
        stagedUpsertOptions[stagedIndex].color = newColor
      } else {
        // stage new updated
        stagedUpsertOptions.push({ value: newValue, color: newColor })
      }
    }

    editedConfig.value = {
      ...editedConfig.value,
      upsertOptions: stagedUpsertOptions,
    }
  }

  const deleteSelectOption = (value: string) => {
    const isSelectType =
      property.value?.type === PropertyType.single_select ||
      property.value?.type === PropertyType.multi_select ||
      property.value?.type === PropertyType.user_select

    const existingOption = isSelectType
      ? property.value?.config?.options.find((option) => option.value === value)
      : undefined

    if (existingOption) {
      const stagedToRemove = editedConfig.value?.removeOptions ?? []
      stagedToRemove.push(value)
      editedConfig.value = {
        ...editedConfig.value,
        removeOptions: stagedToRemove,
      }
    }

    // it might be a newly created option we just need to remove it from the staged upsert state
    const existingUpsertIndex = editedConfig.value?.upsertOptions?.findIndex(
      (o) => o.value === value,
    )
    if (existingUpsertIndex !== undefined && existingUpsertIndex !== -1) {
      editedConfig.value?.upsertOptions?.splice(existingUpsertIndex, 1)
    }
  }

  const markOptionAsDefault = (value: string | null) => {
    const isSelectType =
      editedType.value === PropertyType.single_select ||
      editedType.value === PropertyType.multi_select ||
      editedType.value === PropertyType.user_select

    if (!isSelectType) return

    editedConfig.value = {
      ...editedConfig.value,
      defaultOption: value,
    }
  }

  const createSelectOption = (newOption: { value: string; color?: string | null }) => {
    const upsertOptions = editedConfig.value?.upsertOptions ?? []
    // don't allow empty duplicates
    if (upsertOptions.some((o) => o.value === newOption.value)) return

    // if recreating an option that was deleted, remove it from the delete list
    if (editedConfig.value?.removeOptions?.includes(newOption.value)) {
      editedConfig.value.removeOptions = editedConfig.value.removeOptions.filter(
        (o) => o !== newOption.value,
      )
    }

    upsertOptions.push(newOption)
    editedConfig.value = {
      ...editedConfig.value,
      upsertOptions,
    }
  }

  const toggleSelectOption = (newOption: { value: string; color?: string | null }) => {
    const isSelectType =
      property.value?.type === PropertyType.single_select ||
      property.value?.type === PropertyType.multi_select ||
      property.value?.type === PropertyType.user_select

    const upsertOptions = editedConfig.value?.upsertOptions ?? []
    const isAlreadyUpserted = upsertOptions.some((o) => o.value === newOption.value)
    const isAlreadyRemoved = editedConfig.value?.removeOptions?.includes(newOption.value)
    const isAlreadySaved =
      isSelectType && property.value.config?.options?.some((o) => o.value === newOption.value)
    if (isAlreadyUpserted || (isAlreadySaved && !isAlreadyRemoved)) {
      deleteSelectOption(newOption.value)
    } else {
      createSelectOption(newOption)
    }
  }

  const visibleOptions = computed(() => {
    const isSelectType =
      property.value?.type === PropertyType.single_select ||
      property.value?.type === PropertyType.multi_select ||
      property.value?.type === PropertyType.user_select

    const upsertedMap = Object.fromEntries(
      editedConfig.value?.upsertOptions?.map((o) => [o.value, o]) ?? [],
    )

    const options = isSelectType ? (property.value?.config?.options ?? []) : []

    return (
      options
        .filter((option) => !editedConfig.value?.removeOptions?.includes(option.value))
        .map((option) => ({
          // make sure we use the staged value and color if it exists
          value: option.value,
          color: upsertedMap[option.value]?.color ?? option.color,
        }))
        .concat(
          // concat the inserted options at the end
          editedConfig.value?.upsertOptions
            ?.filter((option) => !options.some((o) => o.value === option.value))
            .map((x) => ({ value: x.value, color: x.color })) ?? [],
        ) ?? []
    )
  })

  const updateCollectionProperty = (
    { name: newName, type: newType }: { name: string; type: PropertyType },
    oldName: string,
  ) => {
    const isCollectionType = property.value?.type === PropertyType.collection
    const pristineExistingCollectionProperties = isCollectionType
      ? (property.value?.config?.properties ?? [])
      : []
    const stagedUpsertCollectionProperties = editedConfig.value?.upsertCollectionProperties ?? []

    const existingCollectionProperty = pristineExistingCollectionProperties.find(
      (p) => p.name === oldName,
    )

    if (existingCollectionProperty) {
      // existing collection property case
      const stagedIndex = stagedUpsertCollectionProperties.findIndex((p) => p.name === oldName)

      const updateObject = {
        name: existingCollectionProperty.name,
        new_name: newName,
        type: newType,
      }

      if (stagedIndex !== undefined && stagedIndex !== -1) {
        // staged case
        stagedUpsertCollectionProperties.splice(stagedIndex, 1, updateObject)
      } else {
        // stage new updated
        stagedUpsertCollectionProperties.push(updateObject)
      }
    } else {
      // new option case
      const stagedIndex = stagedUpsertCollectionProperties.findIndex((p) => p.name === oldName)

      if (stagedIndex !== undefined && stagedIndex !== -1) {
        // staged case
        stagedUpsertCollectionProperties[stagedIndex].name = newName
        stagedUpsertCollectionProperties[stagedIndex].type = newType
      } else {
        // stage new updated
        stagedUpsertCollectionProperties.push({ name: newName, type: newType })
      }
    }

    editedConfig.value = {
      ...editedConfig.value,
      upsertCollectionProperties: stagedUpsertCollectionProperties,
    }
  }

  const deleteCollectionProperty = (name: string) => {
    const isCollectionType = property.value?.type === PropertyType.collection

    const existingCollectionProperty = isCollectionType
      ? property.value?.config?.properties?.find((p) => p.name === name)
      : undefined

    if (existingCollectionProperty) {
      const stagedToRemove = editedConfig.value?.removeCollectionProperties ?? []
      stagedToRemove.push(name)
      editedConfig.value = {
        ...editedConfig.value,
        removeCollectionProperties: stagedToRemove,
      }
    }

    // it might be a newly created property we just need to remove it from the staged upsert state
    const existingUpsertIndex = editedConfig.value?.upsertCollectionProperties?.findIndex(
      (p) => p.name === name,
    )
    if (existingUpsertIndex !== undefined && existingUpsertIndex !== -1) {
      editedConfig.value?.upsertCollectionProperties?.splice(existingUpsertIndex, 1)
    }
  }

  const createCollectionProperty = (newCollectionProperty: {
    name: string
    type: PropertyType
  }) => {
    const upsertCollectionProperties = editedConfig.value?.upsertCollectionProperties ?? []
    // don't allow empty duplicates
    if (upsertCollectionProperties.some((p) => p.name === newCollectionProperty.name)) return

    // if recreating an option that was deleted, remove it from the delete list
    if (editedConfig.value?.removeCollectionProperties?.includes(newCollectionProperty.name)) {
      editedConfig.value.removeCollectionProperties =
        editedConfig.value.removeCollectionProperties.filter(
          (p) => p !== newCollectionProperty.name,
        )
      return
    }

    upsertCollectionProperties.push(newCollectionProperty)
    editedConfig.value = {
      ...editedConfig.value,
      upsertCollectionProperties,
    }
  }

  const visibleProperties = computed(() => {
    const isCollectionType = property.value?.type === PropertyType.collection

    const upsertedMap = Object.fromEntries(
      editedConfig.value?.upsertCollectionProperties?.map((p) => [p.name, p]) ?? [],
    )

    const properties = isCollectionType ? (property.value?.config?.properties ?? []) : []

    return (
      properties
        .filter(
          (property) => !editedConfig.value?.removeCollectionProperties?.includes(property.name),
        )
        .map((property) => ({
          // make sure we use the staged value and color if it exists
          name: property.name,
          type: upsertedMap[property.name]?.type ?? property.type,
        }))
        .concat(
          // concat the inserted options at the end
          editedConfig.value?.upsertCollectionProperties
            ?.filter((property) => !properties.some((p) => p.name === property.name))
            .map((x) => ({ name: x.name, type: x.type })) ?? [],
        ) ?? []
    )
  })

  return {
    editedDescription,
    editedInputs,
    includesInputId,
    editedName,
    editedTool,
    editedType,
    editedConfig,
    deleteSelectOption,
    visibleOptions,
    isDirty,
    otherProperties,
    property,
    reset,
    toggleInputId,
    updateSelectOption,
    createSelectOption,
    toggleSelectOption,
    sidebarIsOpen,
    createCollectionProperty,
    deleteCollectionProperty,
    updateCollectionProperty,
    visibleProperties,
    markOptionAsDefault,
  }
})
