<script lang="ts" setup>
import { computed, type ComputedRef } from 'vue'

import { useProject, type Field } from '@/modules/Project/useProject'

import type { ToolTipProps } from './ToolTip.vue'
import ToolTip from './ToolTip.vue'
import { useWorkspaces } from '@/modules/Workspaces/useWorkspaces'
import IconSprite from '@/uiKit/IconSprite.vue'
import { useLimitedAction } from '@/modules/Billing/useLimitedAction'
import { usePermissionsStore } from '@/modules/IdentityAndAccess/permissionsStore'

const props = defineProps<{ field: Field; outOfDate?: boolean }>()

// https://www.figma.com/file/1HfA941cU4A9RZxXHLmbpG/AGIDB---Design-System?node-id=5%3A2771&mode=dev

const project = useProject()
const workspaces = useWorkspaces()

const tooltipTitle = computed(() => {
  if (props.field.status === 'error') {
    return 'An error occurred'
  }

  if (props.field.groundTruth) {
    return 'This field is locked'
  }

  if (props.field.status === 'computing') {
    return 'This field is being computed'
  }

  if (props.outOfDate) {
    return 'This field is stale'
  }

  if (props.field.errorMessage) {
    return props.field.errorMessage
  }

  return ''
})

const tooltipBody = computed(() => {
  if (props.field.status === 'error') {
    return props.field.errorMessage || ''
  }

  if (props.field.groundTruth) {
    return 'Locked fields are immutable and do not recompute.'
  }

  if (props.field.status === 'computing') {
    return 'Please wait until the AI Tool generates a value.'
  }

  if (props.outOfDate) {
    return 'Recompute the field to get the latest value.'
  }

  return ''
})

const tooltipProps: ComputedRef<ToolTipProps> = computed(() => {
  const toolTipProps: ToolTipProps = {
    title: tooltipTitle.value,
    body: tooltipBody.value,
    placement: { allowedPlacements: ['top', 'right', 'left'] },
  }
  return toolTipProps
})

const { recalculateEntity } = useLimitedAction()
const recalculate = async () => {
  if (!workspaces.currentWorkspace || !project.projectId || !hasRecalculatePermission.value) {
    return
  }

  if (props.field.status === 'error' || props.outOfDate) {
    await recalculateEntity(
      workspaces.currentWorkspace.id,
      project.projectId,
      props.field.entityId,
      [props.field.propertyId],
    )
  }
}

const permissionStore = usePermissionsStore()
const hasRecalculatePermission = computed(
  () => permissionStore.currentProjectPermissions.recalculate_entities ?? false,
)
</script>

<template>
  <ToolTip v-bind="tooltipProps">
    <button
      v-if="!field.groundTruth && (outOfDate || field.status === 'error' || field.errorMessage)"
      class="-mr-1 grid size-5 place-items-center"
      :class="hasRecalculatePermission ? 'group/dot cursor-pointer' : 'cursor-default'"
      aria-label="Recalculate field"
      @click="recalculate"
    >
      <div
        class="relative size-1.5 overflow-hidden rounded-full transition-all duration-250 ease-out group-hover/dot:size-5"
        :class="[
          (outOfDate || field.errorMessage) && ['bg-icon-stale'],
          field.status === 'error' && ['bg-icon-critical'],
        ]"
      >
        <IconSprite
          icon="process"
          class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 rotate-90 text-[12px] opacity-0 transition-all duration-150 ease-out group-hover/dot:rotate-0 group-hover/dot:opacity-100 group-hover/dot:delay-100"
        />
      </div>
    </button>
    <div
      v-else
      class="group grid size-3 place-items-center rounded-corner-4 bg-background-transparent hover:bg-background-transparent-hovered active:bg-background-transparent-pressed"
    >
      <div
        class="col-start-1 col-end-1 row-start-1 row-end-1 size-2.5 rounded-full transition-opacity"
        :class="[
          field.status === 'complete' && ['bg-icon-success'],
          field.status === 'computing' && ['bg-background-stages-model'],
          outOfDate && ['bg-icon-stale'],
          field.status === 'computing'
            ? 'animate-pulse opacity-10'
            : 'opacity-0 group-hover/dot:opacity-10',
        ]"
      ></div>
      <div
        class="col-start-1 col-end-1 row-start-1 row-end-1 size-1.5 rounded-full transition-opacity"
        :class="[
          field.groundTruth
            ? 'bg-background-success'
            : [
                field.status === 'complete' &&
                  (outOfDate ? ['bg-icon-stale'] : ['bg-icon-success']),
                field.status === 'computing' && ['bg-background-stages-model'],
              ],
        ]"
      ></div>
    </div>
  </ToolTip>
</template>
