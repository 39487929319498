<script setup lang="ts">
import { GO_DOCS_REFERENCE_URL, omit } from '@/shared/utils'
import FloatingMenu from '@/uiKit/FloatingMenu.vue'
import IconButton from '@/uiKit/IconButton.vue'
import ListMenuContainer from '@/uiKit/ListMenuContainer.vue'
import ListMenuItem from '@/uiKit/ListMenuItem.vue'
import IconSprite from '@/uiKit/IconSprite.vue'
import { useUser } from '../IdentityAndAccess/useUser'
import { updateUser } from '@/backend/updateUser'
import { useWelcomeTour } from '../WelcomeTour/useWelcomeTour'
import { forceEnableSignup } from '../Signup/SignupForm.vue'

const IDS = {
  hideTutorials: 'hide-tutorials',
  findMoreTutorials: 'find-more-tutorials',
  showTutorials: 'show-tutorials',
  welcomeTour: 'welcome-tour',
  forceSignup: 'force-signup',
}

const isDev = import.meta.env.DEV
const userStore = useUser()
const welcomeTour = useWelcomeTour()

const onSelect = async (id: string) => {
  if (!userStore.user) {
    throw new Error('No user found in the store')
  }

  switch (id) {
    case IDS.hideTutorials: {
      userStore.user.showTutorials = false
      try {
        await updateUser({ showTutorials: false })
      } catch (error) {
        userStore.user.showTutorials = true
        throw error
      }
      break
    }
    case IDS.findMoreTutorials: {
      window.open(GO_DOCS_REFERENCE_URL, '_blank')
      break
    }
    case IDS.showTutorials: {
      userStore.user.showTutorials = true
      try {
        await updateUser({ showTutorials: true })
      } catch (error) {
        userStore.user.showTutorials = false
        throw error
      }
      break
    }
    case IDS.welcomeTour: {
      await welcomeTour.prepare()
      break
    }
    case IDS.forceSignup: {
      forceEnableSignup()
      break
    }
    default:
      break
  }
}
</script>

<template>
  <FloatingMenu
    v-if="userStore.user"
    v-bind="$attrs"
    @select="onSelect"
  >
    <template #trigger="{ triggerProps }">
      <IconButton
        icon="more-dots"
        size="lg"
        variant="transparent"
        v-bind="{ ...omit(triggerProps, ['disabled']), ...$attrs }"
        aria-label="Open tutorials menu"
        @click="(e) => e.preventDefault()"
        >Click me</IconButton
      >
    </template>
    <template #content="{ contentProps, getItemProps }">
      <ListMenuContainer
        v-bind="contentProps"
        class="min-w-56"
      >
        <div class="flex w-full flex-col p-0.5">
          <ListMenuItem
            v-if="userStore.user.showTutorials"
            element="button"
            v-bind="omit(getItemProps({ value: IDS.hideTutorials }), ['onSelect'])"
            icon="hide"
          >
            Hide tutorials
          </ListMenuItem>
          <ListMenuItem
            v-else
            element="button"
            v-bind="omit(getItemProps({ value: IDS.showTutorials }), ['onSelect'])"
            icon="show"
          >
            Show tutorials
          </ListMenuItem>
          <ListMenuItem
            element="button"
            v-bind="omit(getItemProps({ value: IDS.findMoreTutorials }), ['onSelect'])"
            icon="video"
            >Find tutorials
            <template #suffix>
              <IconSprite
                class="ml-auto text-icon-subtle"
                icon="arrow-top-right"
              /> </template
          ></ListMenuItem>
          <ListMenuItem
            element="button"
            v-bind="omit(getItemProps({ value: IDS.welcomeTour }), ['onSelect'])"
            icon="chat-bubble"
          >
            Begin welcome tour
          </ListMenuItem>
          <ListMenuItem
            v-if="isDev"
            element="button"
            v-bind="omit(getItemProps({ value: IDS.forceSignup }), ['onSelect'])"
            icon="chat-bubble"
          >
            (DEV) Force open signup form
          </ListMenuItem>
        </div>
      </ListMenuContainer>
    </template>
  </FloatingMenu>
</template>
