import { listProjectInvitations } from '@/backend/listProjectInvitations'
import { listProjectMembers } from '@/backend/listProjectMembers'
import { Permission } from '@/backend/types'
import { FeatureFlag, useFeatureFlags } from '@/modules/App/useFeatureFlags'
import { usePermissionsStore } from '@/modules/IdentityAndAccess/permissionsStore'
import { useUser } from '@/modules/IdentityAndAccess/useUser'
import type { PartialRecord } from '@/shared/types'
import { useLoadAllPaginatedResults } from '@/sharedComposables/useLoadAllPaginatedResults'
import { watch, type Ref } from 'vue'
import { useProjectPermissionsStore } from './projectPermissionsStore'

const ALL_PERMISSIONS_ENABLED = Object.values(Permission).reduce<PartialRecord<Permission, true>>(
  (acc, permission) => {
    return { ...acc, [permission]: true }
  },
  {},
)

export const useLoadProjectPermissions = ({
  rootProjectId,
  workspaceId,
}: {
  workspaceId: Ref<string>
  rootProjectId: Ref<string>
}) => {
  const projectPermissionsStore = useProjectPermissionsStore()
  const permissionsStore = usePermissionsStore()
  const userStore = useUser()

  const godMode = useFeatureFlags(FeatureFlag.ENABLE_ALL_FRONTEND_ACTIONS)
  const loadAllInvitations = useLoadAllPaginatedResults(
    async ({ after, first }) =>
      listProjectInvitations({
        projectId: rootProjectId.value,
        workspaceId: workspaceId.value,
        after,
        first,
      }),

    (invitations) => {
      projectPermissionsStore.projectInvitations = invitations.map((invitation) => ({
        email: invitation.email,
        role: invitation.role,
        id: invitation.id,
      }))
    },
  )

  watch(
    () => [rootProjectId.value, workspaceId.value, userStore.user?.id, godMode.value] as const,
    async ([newProjectId, newWorkspaceId, userId, isInGodMode]) => {
      if (!userId) {
        return
      }
      permissionsStore.currentProjectId = newProjectId
      if (isInGodMode) {
        Object.keys(permissionsStore.allProjectPermissions).forEach((projectId) => {
          permissionsStore.allProjectPermissions[projectId] = ALL_PERMISSIONS_ENABLED
        })
        permissionsStore.workspacePermissions = ALL_PERMISSIONS_ENABLED
      } else {
        permissionsStore.loadProjectPermissions({
          projectId: newProjectId,
          userId,
          workspaceId: newWorkspaceId,
        })
      }

      loadAllInvitations()

      projectPermissionsStore.reset()
      const membersRes = await listProjectMembers({
        projectId: newProjectId,
        workspaceId: newWorkspaceId,
      })

      if (!membersRes.ok) {
        throw new Error('Failed to load project permissions')
      }

      // Clear the default role for anyone_in_workspace in case it's not in the list
      projectPermissionsStore.defaultRole = null
      membersRes.data.data.forEach((member) => {
        if (typeof member.user === 'string') {
          if (member.user === 'anyone_in_workspace') {
            projectPermissionsStore.defaultRole = member.role
          }
          return
        }

        projectPermissionsStore.workspaceMemberRoles[member.user.id] = member.role
      })
    },
    {
      immediate: true,
    },
  )
}
