<script setup lang="ts">
import { ref } from 'vue'

import { deleteWorkspaceIcon } from '@/backend/deleteWorkspaceIcon'
import { uploadWorkspaceIcon } from '@/backend/uploadWorkspaceIcon'
import { useWorkspaces } from '@/modules/Workspaces/useWorkspaces'
import { copyToClipboard } from '@/shared/clipboard'
import { omit } from '@/shared/utils'
import DividerLine from '@/uiKit/DividerLine.vue'
import FloatingMenu from '@/uiKit/FloatingMenu.vue'
import IconButton from '@/uiKit/IconButton.vue'
import ListMenuContainer from '@/uiKit/ListMenuContainer.vue'
import ListMenuItem from '@/uiKit/ListMenuItem.vue'
import ToolTip from '@/uiKit/ToolTip.vue'
import { usePermissionsStore } from '../IdentityAndAccess/permissionsStore'

const props = defineProps<{
  workspaceId: string
}>()

const permissionsStore = usePermissionsStore()

const IDS = {
  copyWorkspaceId: 'copy-workspace-id',
  uploadIcon: 'upload-icon',
  removeIcon: 'remove-icon',
}

const onSelect = (id: string) => {
  switch (id) {
    case IDS.copyWorkspaceId: {
      copyToClipboard('Workspace ID', props.workspaceId)
      break
    }
    case IDS.removeIcon: {
      handleRemoveWorkspaceIcon()
      break
    }
    default:
      break
  }
}

const handleRemoveWorkspaceIcon = async () => {
  setIconUploading(true)

  await deleteWorkspaceIcon(props.workspaceId)

  setIconUploading(false)
}

const fileInput = ref<HTMLInputElement | null>(null)

const handleFileUpload = async (event: Event) => {
  const files = (event.target as HTMLInputElement).files
  if (files && files.length > 0) {
    const file = files[0]

    const formData = new FormData()
    formData.append('file', file)

    setIconUploading(true)

    await uploadWorkspaceIcon({
      workspaceId: props.workspaceId,
      formData,
    })

    setIconUploading(false)
  }
}

const workspaceStore = useWorkspaces()

const setIconUploading = (value: boolean) => {
  workspaceStore.setIconDownloadError(props.workspaceId, false)
  workspaceStore.setIconUploading(props.workspaceId, value)
}
</script>

<template>
  <FloatingMenu
    v-bind="$attrs"
    @select="onSelect"
  >
    <template #trigger="{ triggerProps }">
      <IconButton
        icon="more-dots"
        size="sm"
        variant="transparent"
        rounded
        data-test="project-menu-trigger"
        v-bind="{ ...omit(triggerProps, ['disabled']), ...$attrs }"
        @click="(e) => e.preventDefault()"
        >Click me</IconButton
      >
    </template>
    <template #content="{ contentProps, getItemProps }">
      <ListMenuContainer
        v-bind="contentProps"
        class="min-w-56"
      >
        <div class="flex w-full flex-col p-0.5">
          <ToolTip
            class="w-full"
            :arrow="true"
            :placement="{ allowedPlacements: ['left'] }"
            :title="workspaceId"
          >
            <ListMenuItem
              element="button"
              v-bind="omit(getItemProps({ value: IDS.copyWorkspaceId }), ['onSelect'])"
              icon="copy"
            >
              Copy workspace ID
            </ListMenuItem>
          </ToolTip>

          <ListMenuItem
            v-if="permissionsStore.hasWorkspaceUpdatePermission"
            element="button"
            v-bind="omit(getItemProps({ value: IDS.uploadIcon }), ['onSelect', 'onClick'])"
            icon="picture"
            @click="fileInput?.click()"
          >
            Upload logo<input
              ref="fileInput"
              class="hidden"
              type="file"
              @change="handleFileUpload"
            />
          </ListMenuItem>
        </div>
        <template v-if="permissionsStore.hasWorkspaceUpdatePermission">
          <DividerLine color="subtle" />
          <div class="flex w-full flex-col p-0.5">
            <ListMenuItem
              element="button"
              v-bind="omit(getItemProps({ value: IDS.removeIcon }), ['onSelect'])"
              icon="trash"
              critical
              >Remove logo</ListMenuItem
            >
          </div>
        </template>
        <DividerLine color="subtle" />
      </ListMenuContainer>
    </template>
  </FloatingMenu>
</template>
