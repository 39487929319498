<script setup lang="ts">
// Figma: https://www.figma.com/file/Xo7wQGCNhUmbTnF2Wbkcvj/AGIDB?type=design&node-id=4797-621286&mode=design&t=3mwPi9xYS0BXpc7J-0
import GroundingTooltip from './GroundingTooltip.vue'
import { type Property, useProject } from './useProject'
import {
  FIELD_TOOL_LABELS,
  FIELD_TYPES_LABELS,
  FIELD_TOOL_OUTPUT_TYPES,
} from '@/modules/WorkspaceSettings/propertyConfig'
import ConfirmationDialog from '@/uiKit/ConfirmationDialog.vue'
import IconSprite from '@/uiKit/IconSprite.vue'
import { PropertyType } from '@/backend/types'
import SelectTypeOptions from './SelectTypeOptions.vue'
import { useProperty } from './useProperty'
import ListMenu from '@/uiKit/ListMenu.vue'
import ListMenuContainer from '@/uiKit/ListMenuContainer.vue'
import DividerLine from '@/uiKit/DividerLine.vue'
import ListMenuItem from '@/uiKit/ListMenuItem.vue'
import { TOOL_ICON, TYPE_ICON } from './icons'
import InlineTextField from '@/uiKit/InlineTextField.vue'
import { computed, onMounted, ref, watch } from 'vue'
import { onClickOutside, onKeyStroke } from '@vueuse/core'
import BadgeItem from '@/uiKit/BadgeItem.vue'
import PopupMenu from '@/uiKit/PopupMenu.vue'
import DarwinButton from '@/uiKit/DarwinButton.vue'
import type { OffsetOptions } from '@floating-ui/vue'
import { useFieldTypeOptions } from '@/modules/Project/useFieldTypeOptions'
import { useFieldToolOptions } from '@/modules/Project/useFieldToolOptions'
import LabelValueMenuItem from '@/sharedComponents/LabelValueMenuItem.vue'
import { ANALYTICS_EVENT, useAnalytics } from '@/sharedComposables/useAnalytics'
import MentionableTextInput from '@/sharedComponents/MentionableTextInput.vue'
import ToolTip from '@/uiKit/ToolTip.vue'
import CollectionTypeProperties from './CollectionTypeProperties.vue'
import { useProjects } from '../Projects/useProjects'
import { usePropertyMeta } from './usePropertyMeta'
import { useModelInputs } from './useModelInputs'
import ToolMenu from './ToolMenu.vue'
import { HORIZONTAL_OFFSET, VERTICAL_OFFSET } from './constants'
import UserListMenu from './UserListMenu.vue'
import { FeatureFlag, useFeatureFlags } from '../App/useFeatureFlags'
import ModelInputMenu from './ModelInputMenu.vue'
import { useLibraryStore } from '../Library/libraryStore'
import { assertIsNotNullOrUndefined } from '@/shared/utils/typeAssertions'
import { useMentionableInputs } from './useMentionableInputs'
import { useWelcomeTour } from '../WelcomeTour/useWelcomeTour'
import SwitchButton from '@/uiKit/SwitchButton.vue'
import { useGroundingToggle } from './useGroundingToggle'
import { usePinnedColumn } from './usePinnedColumn'
import { usePermissionsStore } from '../IdentityAndAccess/permissionsStore'

export type UpdatedProperty = Pick<Property, 'name' | 'type' | 'tool' | 'inputs' | 'description'>

const emit = defineEmits<{
  (e: 'delete' | 'hide'): void
  (e: 'update', v: { updatedProperty: UpdatedProperty; keepMenuOpen?: boolean }): void
  (e: 'reprocess', v: UpdatedProperty): void
  (e: 'close', v?: UpdatedProperty): void
  (name: 'click:outside', event: PointerEvent): void
}>()

const welcomeTour = useWelcomeTour()
const propertyStore = useProperty()
const pinnedColumn = usePinnedColumn()
const projectStore = useProject()
const projectsStore = useProjects()
const permissionStore = usePermissionsStore()

const project = computed(() => projectsStore.projects.find((p) => p.id === projectStore.projectId))

const updatedProperty = computed<UpdatedProperty>(() => ({
  name: propertyStore.editedName,
  type: propertyStore.editedType,
  tool: propertyStore.editedTool,
  inputs: propertyStore.editedInputs,
  description: propertyStore.editedDescription,
  config: propertyStore.editedConfig
    ? {
        removeOptions: propertyStore.editedConfig.removeOptions,
        upsertOptions: propertyStore.editedConfig.upsertOptions,
        upsertCollectionProperties: propertyStore.editedConfig.upsertCollectionProperties,
        removeCollectionProperties: propertyStore.editedConfig.removeCollectionProperties,
        defaultOption: propertyStore.editedConfig.defaultOption,
      }
    : undefined,
}))

const fieldTypesOptions = useFieldTypeOptions()
const fieldToolOptions = useFieldToolOptions()

const updateValue = (keepMenuOpen?: boolean) => {
  emit('update', { updatedProperty: updatedProperty.value, keepMenuOpen })
}

// The dropdowns use <Teleport> to render outside of the component, so we need
// to store refs to each to ensure that we only close the menu when the user
// clicks outside ALL of the menus.
const rootElement = ref()
const toolSubmenu = ref()
const typeSubmenu = ref()
const inputSubmenu = ref()

const reprocessColumnConfirmationOpen = ref(false)

const toolSubmenuIsOpen = ref(false)
const typeSubmenuIsOpen = ref(false)
const inputSubmenuIsOpen = ref(false)
const someSubmenuIsOpen = computed(
  () => toolSubmenuIsOpen.value || typeSubmenuIsOpen.value || inputSubmenuIsOpen.value,
)

const canCloseCollectionMenu = computed(() => {
  return (
    PropertyType.collection !== propertyStore.editedType || propertyStore.visibleProperties.length
  )
})
const collectionError = ref(false)
watch(canCloseCollectionMenu, () => {
  if (canCloseCollectionMenu.value) collectionError.value = false
})

onClickOutside(
  rootElement,
  (e) => {
    if (welcomeTour.status === 'IN_PROGRESS') return
    // If one of the submenus is open, a click outside of the entire nested
    // menu structure should close the submenu, but not the entire menu.
    if (someSubmenuIsOpen.value || reprocessColumnConfirmationOpen.value) {
      toolSubmenuIsOpen.value = false
      typeSubmenuIsOpen.value = false
      inputSubmenuIsOpen.value = false
      reprocessColumnConfirmationOpen.value = false
      e.stopPropagation()
      return
    }

    if (!canCloseCollectionMenu.value) {
      collectionError.value = true
      return
    }

    // Save the unsaved changes and close the dialog
    if (propertyStore.isDirty) {
      updateValue(false)
      return
    }

    emit('click:outside', e)
  },
  {
    ignore: [
      toolSubmenu,
      typeSubmenu,
      inputSubmenu,
      '#color-preset-menu',
      '#reprocess-property-confirmation',
    ],
  },
)

const { inputIdOptions, selectedInputIdOptions } = useModelInputs()

onKeyStroke('Escape', () => {
  if (welcomeTour.status === 'IN_PROGRESS') return
  if (someSubmenuIsOpen.value || reprocessColumnConfirmationOpen.value) {
    toolSubmenuIsOpen.value = false
    typeSubmenuIsOpen.value = false
    inputSubmenuIsOpen.value = false
    reprocessColumnConfirmationOpen.value = false
    return
  }

  if (!canCloseCollectionMenu.value) {
    collectionError.value = true
    return
  }

  emit('close', updatedProperty.value)
})

const onOpenSidebar = () => {
  propertyStore.sidebarIsOpen = true
}

const onDelete = () => {
  emit('delete')
}

const validTools = computed(() =>
  fieldToolOptions.value.filter((t) =>
    FIELD_TOOL_OUTPUT_TYPES[t.tool].includes(propertyStore.editedType),
  ),
)

const submenuOffset: OffsetOptions = ({ rects }) => ({
  alignmentAxis: -rects.reference.height + VERTICAL_OFFSET,
  mainAxis: HORIZONTAL_OFFSET,
})

const TYPE_SUBMENU_ID = 'property-configuration-type-submenu'
const TOOL_SUBMENU_ID = 'property-configuration-tool-submenu'
const INPUT_SUBMENU_ID = 'property-configuration-input-submenu'

const { captureAnalyticsEvent } = useAnalytics()
onMounted(() => {
  captureAnalyticsEvent(ANALYTICS_EVENT.OPEN_PROPERTY_MENU)
})

const onRecomputeStaleFields = () => {
  reprocessColumnConfirmationOpen.value = true

  captureAnalyticsEvent(ANALYTICS_EVENT.STALE_FIELDS_RECOMPUTED)
}

// AB_PROPERTY_EDITOR EXPERIMENT
// - A: Save vs. Recalculate + confirmation
// - B: This menu only on creation, label is always call Save
const { isRecomputeEnabled } = usePropertyMeta()
const isPropertyEditorOpensInSidebar = useFeatureFlags(FeatureFlag.AB_PROPERTY_EDITOR)

const show = computed(() => {
  return {
    group: {
      selectOptions:
        PropertyType.single_select === propertyStore.editedType ||
        PropertyType.multi_select === propertyStore.editedType,
      typeProperties: PropertyType.collection === propertyStore.editedType,
      recompute:
        isRecomputeEnabled.value && permissionStore.currentProjectPermissions.recalculate_entities,
      userSelect: PropertyType.user_select === propertyStore.editedType,
    },
  }
})

const libraryStore = useLibraryStore()
const onOpenLibrary = () => {
  libraryStore.dialogIsOpen = true
  emit('close')
}

const onToggleLibraryItem = (itemId: string) => {
  assertIsNotNullOrUndefined(libraryStore.library, 'Library is not loaded')

  const type = libraryStore.getItemType(itemId)
  const propertyId =
    type === 'file' ? libraryStore.library.fileProperty.id : libraryStore.library.textProperty.id

  propertyStore.toggleInputId(propertyId, itemId)

  if (selectedInputIdOptions.value.some((o) => o.id === itemId)) {
    captureAnalyticsEvent(ANALYTICS_EVENT.LIBRARY_INPUT_IN_PROPERTY)
  }
}

const { onAddMention, onRemoveMention } = useMentionableInputs()

const { isGroundingEnabled, setGroundingState, areConditionsFilled } = useGroundingToggle()
</script>

<template>
  <ListMenuContainer
    ref="rootElement"
    class="flex !size-full min-w-[360px] max-w-[400px] origin-top flex-col overflow-auto scrollbar-thin scrollbar-track-background-transparent scrollbar-thumb-background-gray-subtle scrollbar-track-rounded-md"
    :grow="false"
    v-bind="$attrs"
  >
    <div
      class="contents"
      role="form"
    >
      <div class="w-full p-1">
        <InlineTextField
          class="w-full"
          placeholder="Name"
          size="sm"
          :value="propertyStore.editedName"
          aria-label="Name"
          @input="propertyStore.editedName = $event"
          @submit="updateValue(true)"
        />
      </div>
      <DividerLine
        class="w-full"
        color="subtle"
        :width="1"
      />
      <div class="flex w-full flex-col p-1">
        <PopupMenu
          class="w-full"
          :placement="'right-start'"
          :offset="submenuOffset"
          :open="typeSubmenuIsOpen"
          @change:open="typeSubmenuIsOpen = $event"
        >
          <template #trigger>
            <LabelValueMenuItem
              id="type-combobox"
              label="Type"
              :class="{ 'bg-background-transparent-hovered': typeSubmenuIsOpen }"
              :value="FIELD_TYPES_LABELS[propertyStore.editedType]"
              :icon="TYPE_ICON[propertyStore.editedType]"
              :submenu="{
                isOpen: typeSubmenuIsOpen,
                id: TYPE_SUBMENU_ID,
              }"
            />
          </template>
          <template #dropdown>
            <ListMenu
              :id="TYPE_SUBMENU_ID"
              ref="typeSubmenu"
              class="min-w-[240px]"
              role="listbox"
              aria-label="Select a field type"
              :items="fieldTypesOptions"
              :initial-active-item-predicate="(item) => item.data.type === propertyStore.editedType"
            >
              <template #item="{ item, active, key, setActiveItem }">
                <ListMenuItem
                  v-if="project?.parentProperty === undefined || item.data.type !== 'collection'"
                  :label="item.data.label"
                  :active="active"
                  :aria-selected="active"
                  default-hover-disabled
                  :icon="TYPE_ICON[item.data.type]"
                  @mousemove="setActiveItem(key)"
                  @select="
                    () => {
                      propertyStore.editedType = item.data.type
                      typeSubmenuIsOpen = false
                      if (!validTools.map((t) => t.tool).includes(propertyStore.editedTool)) {
                        propertyStore.editedTool = 'manual'
                      }
                    }
                  "
                >
                  <template #prefix>
                    <div class="flex size-5 items-center justify-center">
                      <IconSprite
                        :icon="item.data.type === propertyStore.editedType ? 'check' : 'blank'"
                        class="text-icon-subtle"
                      />
                    </div>
                  </template>
                </ListMenuItem>
              </template>
            </ListMenu>
          </template>
        </PopupMenu>
        <PopupMenu
          placement="right-start"
          :offset="submenuOffset"
          class="w-full"
          :open="toolSubmenuIsOpen"
          @change:open="toolSubmenuIsOpen = $event"
        >
          <template #trigger>
            <LabelValueMenuItem
              id="tool-combobox"
              label="Tool"
              :class="{ 'bg-background-transparent-hovered': toolSubmenuIsOpen }"
              :value="FIELD_TOOL_LABELS[propertyStore.editedTool]"
              :icon="TOOL_ICON[propertyStore.editedTool]"
              :submenu="{
                isOpen: toolSubmenuIsOpen,
                id: TOOL_SUBMENU_ID,
              }"
            />
          </template>
          <template #dropdown>
            <ToolMenu
              :id="TOOL_SUBMENU_ID"
              ref="toolSubmenu"
              :active-tool="propertyStore.editedTool"
              :active-type="propertyStore.editedType"
              class="min-w-[240px]"
              :grounded="isGroundingEnabled"
              @change="
                (value) => {
                  propertyStore.editedTool = value
                  toolSubmenuIsOpen = false
                }
              "
            />
          </template>
        </PopupMenu>
        <LabelValueMenuItem
          id="grounding-toggle-listitem"
          label="AI citations"
          :submenu="null"
        >
          <ToolTip
            :disabled="areConditionsFilled"
            :placement="{ allowedPlacements: ['top-end'] }"
          >
            <SwitchButton
              :checked="isGroundingEnabled"
              size="sm"
              color="blue"
              aria-label="AI citations"
              :disabled="!areConditionsFilled"
              @change="setGroundingState"
            />
            <template #content><GroundingTooltip /></template>
          </ToolTip>
        </LabelValueMenuItem>
        <PopupMenu
          v-if="propertyStore.editedTool !== 'manual'"
          placement="right-start"
          :offset="{ alignmentAxis: -2, mainAxis: 4 }"
          class="w-full"
          cover-trigger
          :open="inputSubmenuIsOpen"
          @change:open="inputSubmenuIsOpen = $event"
        >
          <template #trigger>
            <LabelValueMenuItem
              id="input-combobox"
              ref="inputTrigger"
              label="Inputs"
              alignment="top"
              :class="{
                'bg-background-transparent-hovered': inputSubmenuIsOpen,
              }"
              :submenu="{
                isOpen: inputSubmenuIsOpen,
                id: INPUT_SUBMENU_ID,
              }"
            >
              <div
                v-if="selectedInputIdOptions.length > 0"
                class="flex w-full flex-wrap justify-end gap-1"
              >
                <BadgeItem
                  v-for="o in selectedInputIdOptions"
                  :key="o.id"
                  :label="o.data.name"
                  variant="selected"
                  size="sm"
                  :leading-icon="o.data.group === 'Properties' ? TYPE_ICON[o.data.type] : undefined"
                />
              </div>
              <div
                v-else
                class="grow text-right text-text-subtlest"
              >
                Select inputs
              </div>
            </LabelValueMenuItem>
          </template>
          <template #dropdown>
            <ModelInputMenu
              :items="inputIdOptions"
              :selected-input-ids="selectedInputIdOptions.map((o) => o.id)"
              @toggle:property="propertyStore.toggleInputId($event)"
              @toggle:library-item="onToggleLibraryItem($event)"
              @open:library="onOpenLibrary"
            />
          </template>
        </PopupMenu>
        <div
          v-if="!['manual', 'ocr', 'whisper', 'bing_search'].includes(propertyStore.editedTool)"
          class="w-full py-0.5"
        >
          <MentionableTextInput
            :code-style="propertyStore.editedTool === 'code'"
            :value="propertyStore.editedDescription || ''"
            :items="
              inputIdOptions.map((option) => ({
                ...option.data,
                icon: option.data.group === 'Properties' ? TYPE_ICON[option.data.type] : undefined,
              }))
            "
            @update:text="propertyStore.editedDescription = $event"
            @add:mention="onAddMention"
            @remove:mention="onRemoveMention"
            @open:library="onOpenLibrary"
          />
        </div>
      </div>
      <DividerLine
        class="w-full"
        color="subtle"
        :width="1"
      />
      <div
        v-if="Object.values(show.group).some(Boolean)"
        class="flex w-full flex-col p-1"
      >
        <UserListMenu v-if="show.group.userSelect">
          <template #trigger="{ isOpen, submenuId, selectedUsers }">
            <LabelValueMenuItem
              id="user-combobox"
              ref="userTrigger"
              label="Users"
              alignment="top"
              :class="{
                'bg-background-transparent-hovered': isOpen,
              }"
              :submenu="{
                isOpen,
                id: submenuId,
              }"
            >
              <div
                v-if="selectedUsers.length > 0"
                class="flex w-full flex-wrap justify-end gap-1 self-start"
              >
                <BadgeItem
                  v-for="o in selectedUsers"
                  :key="o.value"
                  :label="o.value"
                  variant="selected"
                  size="sm"
                  leading-icon="user"
                />
              </div>
              <div
                v-else
                class="grow text-right text-text-subtlest"
              >
                Select users
              </div>
            </LabelValueMenuItem>
          </template>
        </UserListMenu>
        <SelectTypeOptions
          v-if="show.group.selectOptions"
          class="max-h-[210px] overflow-auto"
        />
        <CollectionTypeProperties
          v-if="show.group.typeProperties"
          class="max-h-[210px] overflow-auto"
          :error="collectionError"
        />
        <DarwinButton
          v-if="show.group.recompute"
          variant="neutral"
          size="sm"
          class="w-full"
          type="button"
          @click="onRecomputeStaleFields"
        >
          <template #leading-icon>
            <IconSprite icon="process" />
          </template>
          Recompute all stale fields
        </DarwinButton>
      </div>
      <DividerLine
        v-if="Object.values(show.group).some(Boolean)"
        class="w-full"
        color="subtle"
        :width="1"
      />
      <div class="flex w-full flex-col p-1">
        <ListMenuItem
          v-if="pinnedColumn.canPin"
          :icon="pinnedColumn.isPinned ? 'pin-fill' : 'pin'"
          :label="pinnedColumn.isPinned ? 'Unpin' : 'Pin'"
          role="button"
          @select="pinnedColumn.togglePin"
        />

        <ToolTip
          :arrow="true"
          :placement="{ allowedPlacements: ['right', 'left'] }"
          title="Automations, API, property IDs, and other advanced settings are here."
        >
          <ListMenuItem
            icon="settings"
            label="More settings"
            role="button"
            @select="onOpenSidebar"
          />
        </ToolTip>
        <ListMenuItem
          v-if="!isPropertyEditorOpensInSidebar"
          icon="hide"
          label="Hide from view"
          role="button"
          @select="$emit('hide')"
        />
        <ListMenuItem
          icon="trash"
          label="Delete Property"
          critical
          role="button"
          @select="onDelete"
        />
      </div>
    </div>
  </ListMenuContainer>
  <ConfirmationDialog
    id="reprocess-property-confirmation"
    :open="reprocessColumnConfirmationOpen"
    title="Recompute all stale fields for this property?"
    description="This may take a while and become quite costly."
    variant="black"
    confirm-text="Confirm"
    @confirm="$emit('reprocess', updatedProperty)"
    @close="reprocessColumnConfirmationOpen = false"
  />
</template>
