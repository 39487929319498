import { getLibrary } from '@/backend/getLibrary'
import { listLibraryItems } from '@/backend/listLibraryItems'
import { PropertyType } from '@/backend/types'
import { assertIsNotNullOrUndefined } from '@/shared/utils/typeAssertions'
import { useLoadAllPaginatedResults } from '@/sharedComposables/useLoadAllPaginatedResults'
import { computed, watch, type Ref } from 'vue'
import { useLibraryStore, type LibraryItem } from './libraryStore'
import { serializeLibraryItem } from './serializers'
import { useLibraryWebsocket } from './useLibraryWebsocket'

/**
 * Loads library data into the library store when the workspace ID changes.
 */
export const useLoadLibrary = (workspaceId: Ref<string>) => {
  const libraryStore = useLibraryStore()

  const loadAllLibraryItems = useLoadAllPaginatedResults(
    ({ after, first }) => listLibraryItems({ workspaceId: workspaceId.value, after, first }),

    (items) => {
      assertIsNotNullOrUndefined(libraryStore.library, 'Library ID is null')
      const fileSlug = libraryStore.library.fileProperty.slug
      const textSlug = libraryStore.library.textProperty.slug

      const { emptyIds, newItems } = items.reduce<{
        newItems: LibraryItem[]
        emptyIds: string[]
      }>(
        (acc, entity) => {
          const fileField = entity.fields[fileSlug]
          assertIsNotNullOrUndefined(fileField, 'File field is not defined')
          const textField = entity.fields[textSlug]
          assertIsNotNullOrUndefined(textField, 'Text field is not defined')

          if (fileField.manual_value.value) {
            acc.newItems.push(serializeLibraryItem(entity, fileSlug))
          } else if (textField.manual_value.value) {
            acc.newItems.push(serializeLibraryItem(entity, textSlug))
          } else {
            acc.emptyIds.push(entity.id)
          }
          return acc
        },
        { newItems: [], emptyIds: [] },
      )

      libraryStore.libraryItems = newItems
      libraryStore.emptyItemIds = emptyIds
    },
  )

  /**
   * Connect to a websocket session to receive library updates
   * from other users.
   */
  const libraryId = computed(() => libraryStore.library?.id)
  useLibraryWebsocket(libraryId)

  watch(
    () => workspaceId.value,
    async (id) => {
      libraryStore.reset()

      const getLibraryRes = await getLibrary(id)
      if (!getLibraryRes.ok) {
        throw new Error('Failed to load library')
      }

      const libraryId = getLibraryRes.data.id
      const fileProperty = getLibraryRes.data.properties.find((p) => p.type === PropertyType.file)
      const textProperty = getLibraryRes.data.properties.find((p) => p.type === PropertyType.text)
      assertIsNotNullOrUndefined(fileProperty, 'File property not found in library response')
      assertIsNotNullOrUndefined(textProperty, 'Text property not found in library response')

      libraryStore.library = {
        id: libraryId,
        fileProperty,
        textProperty,
      }
      await loadAllLibraryItems()
    },
    {
      immediate: true,
    },
  )
}
