import { get } from './client'
import type { BackendMeta } from './types'

export type ListProjectMembers = BackendMeta<
  '/api/workspaces/{workspace_id}/projects/{project_id}/members',
  'get'
>

export type ListProjectMembersParams = {
  workspaceId: ListProjectMembers['pathParams']['workspace_id']
  projectId: ListProjectMembers['pathParams']['project_id']
}

/**
 * List all the users who have been given an explicit role in the project.
 */
export const listProjectMembers = ({ projectId, workspaceId }: ListProjectMembersParams) =>
  get<
    ListProjectMembers['queryParams'],
    ListProjectMembers['successResponse'],
    ListProjectMembers['path']
  >(`/api/workspaces/${workspaceId}/projects/${projectId}/members`, undefined)
