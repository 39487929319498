import { addProjectPermission } from '@/backend/addProjectPermission'
import { createProjectInvitation } from '@/backend/createProjectInvitation'
import { removeProjectInvitation } from '@/backend/removeProjectInvitation'
import { removeProjectPermission } from '@/backend/removeProjectPermission'
import { updateProjectPermission } from '@/backend/updateProjectPermission'

import type { DefaultProjectRole, ResourceRole } from '@/backend/types'
import type { WorkspaceMember } from '@/modules/WorkspaceSettings/useWorkspaceMembers'
import { defineStore } from 'pinia'
import { ref } from 'vue'
import type { ProjectMemberRole } from './types'

export type ProjectInvitation = { email: string; role: ResourceRole; id?: string }

export const useProjectPermissionsStore = defineStore('projectPermissions', () => {
  // the effective permission for anyone_in_workspace
  const defaultRole = ref<DefaultProjectRole | null>('reader')

  // Map workspace members to their permissions
  const workspaceMemberRoles = ref<Partial<Record<WorkspaceMember['id'], ResourceRole>>>({})

  /**
   * Set an explicit project permission for a workspace member or anyone_in_workspace.
   */
  const setProjectRole = async ({
    role,
    userId,
    projectId,
    workspaceId,
  }: {
    userId: WorkspaceMember['id'] | 'anyone_in_workspace'
    role: ProjectMemberRole | null
    workspaceId: string
    projectId: string
  }) => {
    const anyoneInWorkspace = userId === 'anyone_in_workspace'

    const oldDefaultRole = defaultRole.value
    const oldRole = workspaceMemberRoles.value[userId]

    // Optimistically update the role
    if (anyoneInWorkspace) {
      defaultRole.value = role
    } else {
      workspaceMemberRoles.value[userId] = role || undefined
    }

    let res
    if (role === null) {
      // No role, i.e. remove the explicit permission
      res = await removeProjectPermission({
        userId,
        projectId,
        workspaceId,
      })
    } else if (anyoneInWorkspace ? oldDefaultRole : oldRole) {
      // There's an old role and a new role - update the permission
      res = await updateProjectPermission({
        userId,
        role,
        projectId,
        workspaceId,
      })
    } else {
      // No old role, so we need to add a new permission
      res = await addProjectPermission({
        userId,
        role,
        projectId,
        workspaceId,
      })
    }

    if (!res.ok) {
      // Revert the changes if the request failed
      if (anyoneInWorkspace) {
        defaultRole.value = oldDefaultRole
      } else {
        workspaceMemberRoles.value[userId] = oldRole
      }
      throw new Error('Failed to set project permission')
    }
  }

  const projectInvitations = ref<ProjectInvitation[]>([])

  /**
   * Send workspace invitations to a list of emails. When accepted, the users
   * will have the specified role in this project.
   */
  const sendProjectInvitations = async ({
    emails,
    projectId,
    role,
    workspaceId,
  }: {
    workspaceId: string
    projectId: string
    emails: string[]
    role: ProjectMemberRole
  }) => {
    const newEmails = emails.filter(
      (email) => !projectInvitations.value.some((invitation) => invitation.email === email),
    )

    // Optimistic UI. We don't have an ID yet, so use the email for now,
    // we'll update it when we get the response.
    projectInvitations.value = [
      ...projectInvitations.value,
      ...newEmails.map((email) => ({ email, role })),
    ]

    const res = await createProjectInvitation({
      workspaceId,
      projectId,
      invitations: emails.map((email) => ({ email, role })),
    })

    if (!res.ok) {
      projectInvitations.value = projectInvitations.value.filter(
        (invitation) => !newEmails.includes(invitation.email),
      )
      throw new Error('Failed to send project invitations')
    }

    res.data.data.forEach((invitationResponse) => {
      const index = projectInvitations.value.findIndex(
        (invitation) => invitation.email === invitationResponse.email,
      )

      if (invitationResponse.state === 'error') {
        // Remove the invitation if it failed
        projectInvitations.value.splice(index, 1)
      } else {
        // Successful invite - update the invitation with the its information
        // from the response
        projectInvitations.value = projectInvitations.value.with(index, {
          email: invitationResponse.email,
          role: invitationResponse.role,
          id: invitationResponse.id,
        })
      }
    })

    return res.data.data
  }

  const deleteProjectInvitation = async ({
    invitationId,
    projectId,
    workspaceId,
  }: {
    workspaceId: string
    projectId: string
    invitationId: string
  }) => {
    const oldInvitation = projectInvitations.value.find(
      (invitation) => invitation.id === invitationId,
    )
    if (!oldInvitation) {
      return
    }

    projectInvitations.value = projectInvitations.value.filter(
      (invitation) => invitation.id !== invitationId,
    )

    const res = await removeProjectInvitation({
      projectId,
      workspaceId,
      invitationId,
    })

    if (!res.ok) {
      projectInvitations.value = [...projectInvitations.value, oldInvitation]
      throw new Error('Failed to delete project invitation')
    }
  }

  const reset = () => {
    defaultRole.value = null
    workspaceMemberRoles.value = {}
    projectInvitations.value = []
  }

  return {
    defaultRole,
    workspaceMemberRoles,
    setProjectRole,
    projectInvitations,
    sendProjectInvitations,
    deleteProjectInvitation,
    reset,
  }
})
