<script setup lang="ts">
import PlanTableDataCell from './PlanTableDataCell.vue'
import PlanTableRowHeader from './PlanTableRowHeader.vue'
import PlanTableColumnHeader from './PlanTableColumnHeader.vue'
import DarwinButton from '@/uiKit/DarwinButton.vue'
import PlanTablePriceCell from './PlanTablePriceCell.vue'
import { useRouter } from 'vue-router'
import { useStripeCheckout } from '../Billing/useStripeCheckout'
import { useBilling } from '@/modules/Billing/useBilling'
import { computed, ref } from 'vue'
import SegmentedControl, { type SegmentedControlItem } from '@/uiKit/SegmentedControl.vue'
import BadgeItem from '@/uiKit/BadgeItem.vue'
import type { PlanTemplate } from '@/modules/Billing/types'
import PlanTableTooltip from './PlanTableTooltip.vue'
import { PLAN_PRICES } from '../Billing/constants'
import { useIntercom } from '@/sharedComposables/useIntercom'
import { FeatureFlag, useFeatureFlags } from '../App/useFeatureFlags'
import { usePermissionsStore } from '../IdentityAndAccess/permissionsStore'

const props = defineProps<{
  workspaceId: string
}>()

const PAGE_TITLE_ID = 'page-title'
const PAGE_SUMMARY_ID = 'page-summary'

const billingStore = useBilling()

const isPlansTokenEstimateEnabled = useFeatureFlags(FeatureFlag.PLAN_TOKENS_VALUE)

const pro = computed(() => billingStore.planCatalog?.pro[billingPeriod.value])

const activePlanName = computed(() => billingStore.activePlan?.name)
const isOnFree = computed(() => activePlanName.value === 'free')
const isOnPro = computed(() => activePlanName.value === 'pro')
const isOnEnterprise = computed(() => activePlanName.value === 'enterprise')

// Dynamic text for the Free plan CTA
const freePlanCta = computed(() => (isOnFree.value ? 'Manage Plan' : 'Downgrade to Free'))
const onClickFree = () => {}

// Dynamic text for the Pro plan CTA
const proPlanCta = computed(() => {
  if (isOnFree.value) {
    return 'Upgrade to Pro'
  }

  if (isOnPro.value) {
    return 'Manage Plan'
  }

  return 'Downgrade to Pro'
})

const { isLoadingCheckoutUrl, startStripeCheckout } = useStripeCheckout()
const router = useRouter()
const onClickPro = async () => {
  if (activePlanName.value === 'pro') {
    router.push({ name: 'WorkspaceSettingsBilling' })
  }

  await startStripeCheckout({
    billingPeriod: billingPeriod.value,
    workspaceId: props.workspaceId,
  })
}

// Dynamic text for the Enterprise plan CTA
const enterprisePlanCta = computed(() => (isOnEnterprise.value ? 'Manage Plan' : 'Contact Sales'))

const { showIntercom } = useIntercom()
const onClickEnterprise = () => {
  if (isOnEnterprise.value) {
    showIntercom()
  }
}
const billingPeriod = ref<PlanTemplate['billingPeriod']>('monthly')
const billingPeriodOptions: SegmentedControlItem<PlanTemplate['billingPeriod']>[] = [
  {
    label: 'Monthly',
    value: 'monthly',
  },
  {
    label: 'Annually',
    value: 'yearly',
  },
]

const demoFormLink = computed(() => {
  if (!activePlanName.value) {
    return
  }

  const map = {
    free: 'http://www.v7labs.com/contact-sales-go?utm_campaign=%5BProduct%5D%20Go%20Plan%20Upgrade&utm_source=website&utm_medium=go&utm_term=contact-sales&utm_content=plans-page-free-user',
    pro: 'http://www.v7labs.com/contact-sales-go?utm_campaign=%5BProduct%5D%20Go%20Plan%20Upgrade&utm_source=website&utm_medium=go&utm_term=contact-sales&utm_content=plans-page-pro-user',
    enterprise: undefined,
  }

  return map[activePlanName.value]
})

const permissionsStore = usePermissionsStore()
</script>

<template>
  <div class="m-auto flex max-w-[760px] flex-col items-stretch py-16">
    <h1
      :id="PAGE_TITLE_ID"
      class="mb-1 text-xl-18px-bold text-text"
    >
      Plans
    </h1>
    <p
      :id="PAGE_SUMMARY_ID"
      class="mb-9 text-sm-12px-light text-text-subtle"
    >
      View your plan information or switch plans according to your needs.
    </p>
    <table
      v-if="pro && billingPeriod"
      class="table-fixed border-collapse"
      :aria-describedby="PAGE_SUMMARY_ID"
      :aria-labelledby="PAGE_TITLE_ID"
    >
      <thead class="sticky top-0 bg-surface-primary-persist">
        <tr>
          <th aria-label="name" />
          <PlanTableColumnHeader
            :is-active-plan="isOnFree"
            name="Free"
          />

          <PlanTableColumnHeader
            name="Pro"
            :is-active-plan="isOnPro"
          />

          <PlanTableColumnHeader
            :is-active-plan="isOnEnterprise"
            name="Enterprise"
          />
        </tr>
        <tr>
          <th aria-label="price" />
          <PlanTablePriceCell
            :is-active-plan="isOnFree"
            :billing-period="billingPeriod"
            :price="0"
          />
          <PlanTablePriceCell
            :is-active-plan="isOnPro"
            :billing-period="billingPeriod"
            :price="PLAN_PRICES['pro'][billingPeriod]"
          />
          <PlanTablePriceCell
            :is-active-plan="isOnEnterprise"
            :billing-period="billingPeriod"
            price="Talk to Sales"
          />
        </tr>
        <tr>
          <td>
            <SegmentedControl
              v-if="!isOnEnterprise"
              name="billingPeriod"
              aria-label="Billing Period"
              :value="billingPeriod"
              :items="billingPeriodOptions"
              @change="billingPeriod = $event"
            >
              <template #item:yearly:trailing
                ><BadgeItem
                  label="-20%"
                  variant="neutral"
                  size="xs" /></template
            ></SegmentedControl>
          </td>
          <td
            :class="isOnFree && 'bg-surface-secondary'"
            class="p-4"
          >
            <div
              v-if="isOnEnterprise"
              class="text-center text-text-subtle"
            >
              Contact your CSM to manage your plan
            </div>
            <DarwinButton
              v-else-if="permissionsStore.workspacePermissions.manage_billing"
              variant="outline"
              size="sm"
              class="w-full"
              :href="
                !isOnFree && billingStore.customerPortalUrl
                  ? billingStore.customerPortalUrl
                  : undefined
              "
              @click="onClickFree"
              >{{ freePlanCta }}</DarwinButton
            >
          </td>
          <td
            :class="isOnPro && 'bg-surface-secondary'"
            class="p-4"
          >
            <div
              v-if="isOnEnterprise"
              class="text-center text-text-subtle"
            >
              Contact your CSM to manage your plan
            </div>
            <DarwinButton
              v-else-if="permissionsStore.workspacePermissions.manage_billing"
              :variant="isOnFree ? 'black' : 'outline'"
              class="w-full"
              size="sm"
              :loading="isLoadingCheckoutUrl"
              @click="onClickPro"
              >{{ proPlanCta }}</DarwinButton
            >
          </td>
          <td
            :class="isOnEnterprise && 'bg-surface-secondary'"
            class="p-4"
          >
            <DarwinButton
              v-if="permissionsStore.workspacePermissions.manage_billing"
              :variant="isOnPro ? 'black' : 'outline'"
              class="w-full"
              size="sm"
              target="_blank"
              :href="demoFormLink"
              @click="onClickEnterprise"
              >{{ enterprisePlanCta }}</DarwinButton
            >
          </td>
        </tr>
        <tr>
          <td
            class="h-px bg-border-subtle p-0"
            colspan="4"
          ></td>
        </tr>
      </thead>
      <tbody>
        <tr>
          <PlanTableRowHeader
            class="w-1/4 text-left"
            scope="rowGroup"
          >
            Usage
          </PlanTableRowHeader>
          <PlanTableDataCell
            class="w-1/4"
            :is-active-plan="isOnFree"
          />
          <PlanTableDataCell
            class="w-1/4"
            :is-active-plan="isOnPro"
          />
          <PlanTableDataCell
            :is-active-plan="isOnEnterprise"
            class="w-1/4"
          />
        </tr>
        <tr>
          <PlanTableRowHeader scope="row"
            ><PlanTableTooltip
              tooltip-text="Fields are what you would call cells in a spreadsheet and approximate to the number of steps in your workflow. Your total Fields are your Entities (rows) multiplied by your Properties (columns)."
              >Fields</PlanTableTooltip
            ></PlanTableRowHeader
          >
          <PlanTableDataCell
            :is-active-plan="isOnFree"
            :value="250"
          />
          <PlanTableDataCell
            :is-active-plan="isOnPro"
            :value="billingStore.getLimit('pro', billingPeriod, 'field_count', 'total')"
          />
          <PlanTableDataCell
            :is-active-plan="isOnEnterprise"
            value="Custom"
          />
        </tr>
        <tr>
          <PlanTableRowHeader scope="row"
            ><PlanTableTooltip
              tooltip-text="Go Tokens approximate to $s. More expensive models consume Go Tokens at a faster rate and vice versa. Free users have non-resetting tokens, whereas paid users have tokens that reset on the same cadence as their billing plan, either monthly or annually. If you hit a token limit, you can upgrade onto a higher plan tier."
              >Go tokens</PlanTableTooltip
            ></PlanTableRowHeader
          >
          <PlanTableDataCell
            :is-active-plan="isOnFree"
            :value="billingStore.getLimit('free', billingPeriod, 'tool_token_usage', 'total')"
            :estimate-enabled="isPlansTokenEstimateEnabled && !isOnEnterprise"
          />
          <PlanTableDataCell
            :is-active-plan="isOnPro"
            :period="billingPeriod"
            :value="
              billingStore.getLimit('pro', billingPeriod, 'tool_token_usage', 'billing_period')
            "
            :estimate-enabled="isPlansTokenEstimateEnabled && !isOnEnterprise"
          />
          <PlanTableDataCell
            :is-active-plan="isOnEnterprise"
            value="Custom"
          />
        </tr>
        <tr>
          <PlanTableRowHeader scope="row"
            ><PlanTableTooltip tooltip-text="Properties (columns) represent workflow complexity."
              >Properties</PlanTableTooltip
            >
            / project</PlanTableRowHeader
          >
          <PlanTableDataCell
            :is-active-plan="isOnFree"
            :value="20"
          />
          <PlanTableDataCell
            :is-active-plan="isOnPro"
            :value="billingStore.getLimit('pro', billingPeriod, 'property_count', 'total')"
          />
          <PlanTableDataCell
            :is-active-plan="isOnEnterprise"
            value="Unlimited"
          />
        </tr>
        <tr>
          <PlanTableRowHeader scope="row">Seats</PlanTableRowHeader>
          <PlanTableDataCell
            :is-active-plan="isOnFree"
            :value="10"
          />
          <PlanTableDataCell
            :is-active-plan="isOnPro"
            :value="billingStore.getLimit('pro', billingPeriod, 'member_count', 'total')"
          />
          <PlanTableDataCell
            value="Custom"
            :is-active-plan="isOnEnterprise"
          />
        </tr>
        <tr>
          <PlanTableRowHeader
            class="pb-[21px]"
            scope="row"
            >Workspaces</PlanTableRowHeader
          >
          <PlanTableDataCell
            :is-active-plan="isOnFree"
            class="pb-[21px]"
            value="1"
          />
          <PlanTableDataCell
            :is-active-plan="isOnPro"
            class="pb-[21px]"
            value="1"
          />
          <PlanTableDataCell
            :is-active-plan="isOnEnterprise"
            class="pb-[21px]"
            value="Custom"
          />
        </tr>
      </tbody>
      <tbody>
        <tr>
          <PlanTableRowHeader
            class="border-t border-border-subtle text-left"
            scope="rowGroup"
          >
            Features
          </PlanTableRowHeader>
          <PlanTableDataCell
            class="border-t border-border-subtle text-left"
            :is-active-plan="isOnFree"
          ></PlanTableDataCell>
          <PlanTableDataCell
            :is-active-plan="isOnPro"
            class="border-t border-border-subtle text-left"
          ></PlanTableDataCell>
          <PlanTableDataCell
            :is-active-plan="isOnEnterprise"
            class="border-t border-border-subtle text-left"
          />
        </tr>
        <tr>
          <PlanTableRowHeader scope="row">Command queue priority</PlanTableRowHeader>
          <PlanTableDataCell
            :is-active-plan="isOnFree"
            :value="false"
          />
          <PlanTableDataCell
            :is-active-plan="isOnPro"
            :value="true"
          />
          <PlanTableDataCell
            :is-active-plan="isOnEnterprise"
            :value="true"
          />
        </tr>
        <tr>
          <PlanTableRowHeader
            scope="row"
            class="pb-[21px]"
            ><PlanTableTooltip
              tooltip-text="By default, all models on Go use V7 platform tokens. If you would like to use your own models or API keys, contact sales."
              >Bring your own Key</PlanTableTooltip
            ></PlanTableRowHeader
          >
          <PlanTableDataCell
            :is-active-plan="isOnFree"
            class="pb-[21px]"
            :value="false"
          />
          <PlanTableDataCell
            :is-active-plan="isOnPro"
            class="pb-[21px]"
            :value="false"
          />
          <PlanTableDataCell
            :is-active-plan="isOnEnterprise"
            class="pb-[21px]"
            :value="true"
          />
        </tr>
      </tbody>
      <tbody>
        <tr>
          <PlanTableRowHeader
            class="border-t border-border-subtle text-left"
            scope="rowGroup"
          >
            Security
          </PlanTableRowHeader>
          <PlanTableDataCell
            :is-active-plan="isOnFree"
            class="border-t border-border-subtle text-left"
          ></PlanTableDataCell>
          <PlanTableDataCell
            :is-active-plan="isOnPro"
            class="border-t border-border-subtle text-left"
          ></PlanTableDataCell>
          <PlanTableDataCell
            :is-active-plan="isOnEnterprise"
            class="border-t border-border-subtle text-left"
          />
        </tr>
        <tr>
          <PlanTableRowHeader scope="row">SAML Single Sign-On (SSO)</PlanTableRowHeader>
          <PlanTableDataCell
            :is-active-plan="isOnFree"
            :value="false"
          />
          <PlanTableDataCell
            :is-active-plan="isOnPro"
            :value="true"
          />
          <PlanTableDataCell
            :is-active-plan="isOnEnterprise"
            :value="true"
          />
        </tr>
        <tr>
          <PlanTableRowHeader
            scope="row"
            class="pb-[21px]"
            >Custom data retention</PlanTableRowHeader
          >
          <PlanTableDataCell
            :is-active-plan="isOnFree"
            class="pb-[21px]"
            :value="false"
          />
          <PlanTableDataCell
            :is-active-plan="isOnPro"
            class="pb-[21px]"
            :value="false"
          />
          <PlanTableDataCell
            class="pb-[21px]"
            :is-active-plan="isOnEnterprise"
            :value="true"
          />
        </tr>
      </tbody>

      <tbody>
        <tr>
          <PlanTableRowHeader
            scope="rowGroup"
            class="border-t border-border-subtle text-left"
          >
            Billing
          </PlanTableRowHeader>
          <PlanTableDataCell
            class="border-t border-border-subtle"
            :is-active-plan="isOnFree"
          ></PlanTableDataCell>
          <PlanTableDataCell
            :is-active-plan="isOnPro"
            class="border-t border-border-subtle"
          ></PlanTableDataCell>
          <PlanTableDataCell
            :is-active-plan="isOnEnterprise"
            class="border-t border-border-subtle"
          />
        </tr>
        <tr>
          <PlanTableRowHeader scope="row">Billing method</PlanTableRowHeader>
          <PlanTableDataCell
            :is-active-plan="isOnFree"
            :value="false"
          />
          <PlanTableDataCell
            :is-active-plan="isOnPro"
            value="Card only"
          />
          <PlanTableDataCell
            :is-active-plan="isOnEnterprise"
            value="Invoice or card"
          />
        </tr>
        <tr>
          <PlanTableRowHeader scope="row">Payment frequency</PlanTableRowHeader>
          <PlanTableDataCell
            :is-active-plan="isOnFree"
            :value="false"
          />
          <PlanTableDataCell
            :is-active-plan="isOnPro"
            value="Monthly or annually"
          />
          <PlanTableDataCell
            :is-active-plan="isOnEnterprise"
            value="Annually"
          />
        </tr>
        <tr>
          <PlanTableRowHeader
            class="pb-[21px]"
            scope="row"
            >Enterprise SLAs</PlanTableRowHeader
          >
          <PlanTableDataCell
            class="pb-[21px]"
            :is-active-plan="isOnFree"
            :value="false"
          />
          <PlanTableDataCell
            class="pb-[21px]"
            :is-active-plan="isOnPro"
            :value="false"
          />
          <PlanTableDataCell
            class="pb-[21px]"
            :is-active-plan="isOnEnterprise"
            :value="true"
          />
        </tr>
      </tbody>
      <tbody>
        <tr>
          <PlanTableRowHeader
            scope="rowGroup"
            class="border-t border-border-subtle text-left"
          >
            Support
          </PlanTableRowHeader>
          <PlanTableDataCell
            class="border-t border-border-subtle"
            :is-active-plan="isOnFree"
          ></PlanTableDataCell>
          <PlanTableDataCell
            :is-active-plan="isOnPro"
            class="border-t border-border-subtle"
          />
          <PlanTableDataCell
            :is-active-plan="isOnEnterprise"
            class="border-t border-border-subtle"
          />
        </tr>
        <tr>
          <PlanTableRowHeader
            class="pb-[21px]"
            scope="row"
            >Technical Support</PlanTableRowHeader
          >
          <PlanTableDataCell
            :is-active-plan="isOnFree"
            class="rounded-b-md pb-[21px]"
            :value="false"
          />
          <PlanTableDataCell
            class="rounded-b-md pb-[21px]"
            :is-active-plan="isOnPro"
            value="Chat Support"
          />
          <PlanTableDataCell
            :is-active-plan="isOnEnterprise"
            class="rounded-b-md pb-[21px]"
            value="Customer Success Manager"
          />
        </tr>
      </tbody>
    </table>
  </div>
</template>
