import { put } from './client'
import type { BackendMeta } from './types'

export type SetEntityMetadata = BackendMeta<
  '/api/workspaces/{workspace_id}/projects/{project_id}/entities/{entity_id}/properties/{property_id_or_slug}/set_metadata',
  'put'
>

type SetEntityMetadataParams = {
  workspaceId: SetEntityMetadata['pathParams']['workspace_id']
  projectId: SetEntityMetadata['pathParams']['project_id']
  entityId: SetEntityMetadata['pathParams']['entity_id']
  propertyIdOrSlug: SetEntityMetadata['pathParams']['property_id_or_slug']
  score: SetEntityMetadata['body']['value']['value_score']
}

export const setEntityMetadata = ({
  workspaceId,
  projectId,
  entityId,
  propertyIdOrSlug,
  score,
}: SetEntityMetadataParams) =>
  put<SetEntityMetadata['body'], SetEntityMetadata['successResponse'], SetEntityMetadata['path']>(
    `/api/workspaces/${workspaceId}/projects/${projectId}/entities/${entityId}/properties/${propertyIdOrSlug}/set_metadata`,
    { value: { value_score: score } },
  )
