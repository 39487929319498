export function scrollToElementIfOutOfBounds(
  element: HTMLElement,
  padding: { top?: number; right?: number; bottom?: number; left?: number } = {},
) {
  const scrollParent = findScrollParent(element)
  if (!scrollParent) return

  const elementRect = element.getBoundingClientRect()
  const parentRect = scrollParent.getBoundingClientRect()

  const isOutOfBounds =
    elementRect.top < parentRect.top + (padding.top || 0) ||
    elementRect.right > parentRect.right - (padding.right || 0) ||
    elementRect.bottom > parentRect.bottom - (padding.bottom || 0) ||
    elementRect.left < parentRect.left + (padding.left || 0)

  if (isOutOfBounds) {
    const scrollLeft =
      scrollParent.scrollLeft + elementRect.left - parentRect.left - (padding.left || 0)
    const scrollTop = scrollParent.scrollTop + elementRect.top - parentRect.top - (padding.top || 0)

    scrollParent.scrollTo({
      left: scrollLeft,
      top: scrollTop,
      behavior: 'smooth',
    })
  }
}

function isScrollable(element: HTMLElement): boolean {
  const style = window.getComputedStyle(element)
  return (
    ['auto', 'scroll'].includes(style.overflowY) ||
    ['auto', 'scroll'].includes(style.overflowX) ||
    ['auto', 'scroll'].includes(style.overflow)
  )
}

function findScrollParent(element: HTMLElement): HTMLElement | null {
  if (!element) {
    return null
  }

  if (isScrollable(element)) {
    return element
  } else {
    return findScrollParent(element.parentElement as HTMLElement)
  }
}
