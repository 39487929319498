import type { components } from '@/api'
import type { APIResult } from '@/backend/types'
import type { IfEquals } from '@/shared/types'
import { readonly, ref, type Ref } from 'vue'

export type PaginatedArgs = {
  first: components['schemas']['Pagination.First']
  after: components['schemas']['Pagination.Cursor']
}

export type PageMetadata = components['schemas']['Pagination.PageMetadata']

/** The success response from a paginated endpoint */
type PaginatedResult<T> = {
  data: T[]
  metadata: PageMetadata
}

export type LoadMoreArgs<Args> = IfEquals<
  Args,
  PaginatedArgs,
  [],
  [Omit<Args, keyof PaginatedArgs>]
>

export type PaginatedFn<Args extends PaginatedArgs, ResponseType> = (
  args: Args,
) => Promise<APIResult<PaginatedResult<ResponseType>>>

export const usePaginatedLoader = <Args extends PaginatedArgs, ResponseType>(
  loadFn: PaginatedFn<Args, ResponseType>,
  limit = 50,
) => {
  const status = ref<'idle' | 'loading' | 'loaded' | 'errored'>('idle')
  const data = ref<ResponseType[]>([]) as Ref<ResponseType[]>
  const canLoadMore = ref(true)
  const cursor = ref<string | null>(null)

  const loadMore = async (...args: LoadMoreArgs<Args>): Promise<PageMetadata | null> => {
    if (status.value === 'loading') return null
    status.value = 'loading'

    const loadFnArgs = { ...args?.[0], first: limit, after: cursor.value } as Args
    const result = await loadFn(loadFnArgs)
    status.value = result.ok ? 'loaded' : 'errored'
    if (!result.ok) return null

    cursor.value = result.data.metadata.end_cursor
    canLoadMore.value = result.data.metadata.has_next_page
    data.value = [...data.value, ...result.data.data]
    return result.data.metadata
  }

  const reset = () => {
    data.value = []
    cursor.value = null
    canLoadMore.value = true
    status.value = 'idle'
  }

  return { status, loadMore, canLoadMore: readonly(canLoadMore), data, reset }
}
