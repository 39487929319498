import { clamp, onClickOutside, onKeyStroke, useEventListener } from '@vueuse/core'
import { type Ref } from 'vue'
import { useTable } from './useTable'

/**
 * When clicking outside the table, we want to unfocus the focused cell.
 * Because some cells could be teleported to outside the table, we need to pass a
 * list of selectors that should be ignored when clicking outside the table.
 *
 * This is not perfectly clean, but it works.
 */
export const TELEPORTED_CELL_SELECTOR = 'table-cell-content'

/**
 * To be used in tandem with the useTable pinia store
 * Provides keybindings and interactions that need to be setup in one main component.
 *
 * Note that the pinia store doesn't really need to know about row and column counts,
 * so we pass them in as refs here.
 */
export const useTableInteractions = (
  /**
   * The primary table container element ref. Used for logic in certain interactions.
   */
  tableRef: Ref<HTMLElement | null>,
  /**
   * Total count of rows in the table
   */
  rowCount: Ref<number>,
  /**
   * Total count of columns in the table
   */
  columnCount: Ref<number>,
) => {
  const store = useTable()

  const moveCellFocusDown = () => {
    if (!store.selectedCell) {
      return
    }
    const { rowIndex, colIndex } = store.selectedCell
    if (store.hasSelectedMultipleCells && store.selectedRange) {
      // move to the next cell in the selected range, moving
      // top to bottom, left to right
      const isAtBottomRow = rowIndex === store.selectedRange?.end.rowIndex
      const isAtRightCol = colIndex === store.selectedRange?.end.colIndex
      if (isAtBottomRow && isAtRightCol) {
        store.selectedCell = store.selectedRange.start
      } else if (isAtBottomRow) {
        store.selectedCell = {
          rowIndex: store.selectedRange.start.rowIndex,
          colIndex: colIndex + 1,
        }
      } else {
        store.selectedCell = { rowIndex: rowIndex + 1, colIndex }
      }
    } else {
      const indexOfCellBelow = rowIndex + 1
      if (rowIndex < rowCount.value - 1) {
        store.selectCell(indexOfCellBelow, colIndex)
      }
    }
  }

  /**
   * Unfocuses focused cell on escape.
   * We may want to also deselect selected cell(s), but for now, we really don't use selection
   */
  onKeyStroke('Escape', () => {
    if (store.focusedCell) {
      store.focusedCell = null
      return
    }

    if (store.selectedRange) {
      store.focusedCell = null
      store.selectedRange = null
      store.selectedCell = null
    }
  })

  /**
   * Navigate focused cells using arrow keys
   */
  onKeyStroke(
    ['ArrowDown', 'ArrowUp', 'ArrowLeft', 'ArrowRight'],
    (e: KeyboardEvent) => {
      if (!store.selectedRange || store.focusedCell || !store.selectedCell) {
        return
      }

      e.preventDefault()

      const { rowIndex, colIndex } = store.selectedCell

      const delta = { row: 0, col: 0 }
      e.key === 'ArrowUp' && (delta.row = -1)
      e.key === 'ArrowDown' && (delta.row = 1)
      e.key === 'ArrowLeft' && (delta.col = -1)
      e.key === 'ArrowRight' && (delta.col = 1)

      const isHoldingShift = e.getModifierState('Shift')
      if (isHoldingShift && store.selectedCell && store.selectedRange) {
        if (e.key === 'ArrowUp') {
          if (store.selectedCell.rowIndex === store.selectedRange.end.rowIndex) {
            store.selectedRange.start.rowIndex = Math.max(0, store.selectedRange.start.rowIndex - 1)
          } else {
            store.selectedRange.end.rowIndex = Math.max(0, store.selectedRange.end.rowIndex - 1)
          }
        }

        if (e.key === 'ArrowDown') {
          if (store.selectedCell.rowIndex === store.selectedRange.start.rowIndex) {
            store.selectedRange.end.rowIndex = Math.min(
              rowCount.value - 1,
              store.selectedRange.end.rowIndex + 1,
            )
          } else {
            store.selectedRange.start.rowIndex = Math.min(
              rowCount.value - 1,
              store.selectedRange.start.rowIndex + 1,
            )
          }
        }

        if (e.key === 'ArrowLeft') {
          if (store.selectedCell.colIndex === store.selectedRange.end.colIndex) {
            store.selectedRange.start.colIndex = Math.max(0, store.selectedRange.start.colIndex - 1)
          } else {
            store.selectedRange.end.colIndex = Math.max(0, store.selectedRange.end.colIndex - 1)
          }
        }

        if (e.key === 'ArrowRight') {
          if (store.selectedCell.colIndex === store.selectedRange.start.colIndex) {
            store.selectedRange.end.colIndex = Math.min(
              columnCount.value - 1,
              store.selectedRange.end.colIndex + 1,
            )
          } else {
            store.selectedRange.start.colIndex = Math.min(
              columnCount.value - 1,
              store.selectedRange.start.colIndex + 1,
            )
          }
        }

        // Reassign to shallow watchers in each table cell
        store.selectedRange = { ...store.selectedRange }
        return
      }

      const maxCol = Math.max(0, columnCount.value - 1)
      const newColIndex = clamp(colIndex + delta.col, 0, maxCol)

      const maxRow = Math.max(0, rowCount.value - 1)
      const newRowIndex = clamp(rowIndex + delta.row, 0, maxRow)

      if (newRowIndex == rowIndex && newColIndex === colIndex) {
        return
      }

      store.selectCell(newRowIndex, newColIndex)
    },
    { target: tableRef },
  )

  /**
   * Handle tab keypresses to navigate between cells. This function is:
   * 1. Used this composable for when the browser is focused somewhere within the table
   * 2. Returned so that it can be used in teleported DOM elements (e.g. the text cell contenteditable)
   */
  const onTabKeystroke = (e: KeyboardEvent) => {
    const rowIndex = store.selectedRange?.start.rowIndex ?? 0
    const colIndex = store.selectedRange?.start.colIndex ?? -1

    const isStartOfTable = colIndex === 0 && rowIndex === 0
    const isEndOfRow = colIndex === columnCount.value - 1
    const isEndOFTable = isEndOfRow && rowIndex === rowCount.value - 1

    let newRowIndex = rowIndex
    let newColIndex = colIndex

    if (e.shiftKey && !isStartOfTable) {
      const isStartOfRow = colIndex === 0
      newColIndex = isStartOfRow ? columnCount.value - 1 : colIndex - 1
      newRowIndex = isStartOfRow ? rowIndex - 1 : rowIndex
    } else if (!e.shiftKey && !isEndOFTable) {
      newColIndex = isEndOfRow ? 0 : colIndex + 1
      newRowIndex = isEndOfRow ? rowIndex + 1 : rowIndex
    }

    e.preventDefault()
    store.selectCell(newRowIndex, newColIndex)
  }

  /**
   * Clear the focused/selected cells when clicking inside the table on
   * an element that is not a focusable cell.
   */
  useEventListener(tableRef, 'click', async (e) => {
    if (!(e.target instanceof HTMLElement)) {
      return
    }

    const hasBeenRemovedFromDom = !e.target.closest('body')
    if (hasBeenRemovedFromDom) {
      return
    }

    const gridCellAncestor = e.target.closest('[role=gridcell]')
    const gridCellDescendant = e.target.querySelector('[role=gridcell]')
    const gridCell = gridCellAncestor || gridCellDescendant
    if (!gridCell) {
      store.clearFocusedAndSelected()
    } else {
      const ariaColindex = gridCell.getAttribute('aria-colindex')
      const ariaRowIndex = gridCell.getAttribute('aria-rowindex')
      if (!ariaColindex || !ariaRowIndex) {
        throw new Error('Gridcell is missing aria-colindex or aria-rowindex')
      }

      const colIndex = parseInt(ariaColindex) - 2
      const rowIndex = parseInt(ariaRowIndex) - 2

      const isHoldingShift = e.getModifierState('Shift')
      if (isHoldingShift && store.selectedCell) {
        // When holding shift and clicking, we should update the range
        const startCol = Math.min(store.selectedCell.colIndex, colIndex)
        const endCol = Math.max(store.selectedCell.colIndex, colIndex)
        const startRow = Math.min(store.selectedCell.rowIndex, rowIndex)
        const endRow = Math.max(store.selectedCell.rowIndex, rowIndex)
        store.selectedRange = {
          start: { rowIndex: startRow, colIndex: startCol },
          end: { rowIndex: endRow, colIndex: endCol },
        }
      } else {
        store.selectCell(rowIndex, colIndex)
      }
    }
  })

  /**
   * Navigate to prev/next cell using (shift+)tab
   *
   * The navigation "wraps around".
   * Going back from 0,0 moves to end of table and vice versa.
   */
  onKeyStroke('Tab', onTabKeystroke, { target: tableRef })

  onClickOutside(
    tableRef,
    () => {
      store.clearFocusedAndSelected()
    },
    // any table cell that relies on floating UI or teleport will potentially
    // detached from the table container and attached to another
    // we need to mark such elements with an attribute and ignore them
    { ignore: [`[data-${TELEPORTED_CELL_SELECTOR}]`, '[data-lock-field-button]'] },
  )

  return {
    moveCellFocusDown,
    /**
     * We can't just listen for the tab keypress on the table element because
     * the text cell contenteditable is teleported outside the table. So
     * here we return the tab handler so it can be handled when the user
     * tabs in the text cell contenteditable.
     */
    onTabKeystroke,
  }
}
