import { computed, inject, provide, ref, watch, type ComputedRef } from 'vue'

import { updateView } from '@/backend/updateView'
import { acceptHMRUpdate, defineStore } from 'pinia'
import { useWorkspaces } from '../Workspaces/useWorkspaces'
import { useProject } from './useProject'

export const usePinnedColumn = defineStore('pinned-column', () => {
  const projectStore = useProject()
  const workspacesStore = useWorkspaces()

  const backendNumPinnedProperties = computed(() => {
    return projectStore.activeView?.view.numPinnedProperties ?? 0
  })

  const numPinnedProperties = ref(0)

  watch(
    backendNumPinnedProperties,
    (v) => {
      numPinnedProperties.value = v
    },
    { immediate: true },
  )

  const propViewIndex = computed(() => {
    const activeView = projectStore.activeView?.view
    if (!activeView) return null
    return activeView.propertyIds.findIndex((id) => id === projectStore.selectedPropertyId)
  })

  const isPinned = computed(() => {
    return (
      typeof propViewIndex.value === 'number' && propViewIndex.value < numPinnedProperties.value
    )
  })

  async function togglePin() {
    const activeView = projectStore.activeView?.view
    if (!activeView || !projectStore.selectedPropertyId) return

    // Save previous value to revert in case of error
    const prevNumPinnedProperties = numPinnedProperties.value

    // Only ever allow one pinned property.
    numPinnedProperties.value = isPinned.value ? 0 : 1

    let oldPropertyIds: string[] | undefined = activeView.propertyIds
    if (numPinnedProperties.value) {
      const indexToPin = numPinnedProperties.value - 1
      const shouldShiftProp = propViewIndex.value !== indexToPin

      if (shouldShiftProp) {
        // Put the prop to pin in the first position
        const res = projectStore.shiftPropertyToIndex(projectStore.selectedPropertyId, indexToPin)
        oldPropertyIds = res.oldPropertyIds
      }
    }

    const res = await updateView({
      ...activeView,
      propertyIds: projectStore.activeView?.view.propertyIds ?? [],
      workspaceId: workspacesStore.currentWorkspace?.id ?? '',
      viewId: activeView.id,
      projectId: projectStore.projectId ?? '',
      propertyOptions: activeView.propertyOptions ?? [],
      numPinnedProperties: numPinnedProperties.value,
    })

    if (!res.ok) {
      numPinnedProperties.value = prevNumPinnedProperties
      projectStore.upsertView({
        ...activeView,
        propertyIds: oldPropertyIds ?? [],
        numPinnedProperties: prevNumPinnedProperties,
      })
    }
  }

  function getColStyle(index: number) {
    if (index >= numPinnedProperties.value) return
    const propertyWidths = projectStore.visibleProperties.map(({ id }) => projectStore.getWidth(id))

    return {
      left: `${48 + propertyWidths.slice(0, index).reduce((a, b) => a + b, 0)}px`,
    }
  }

  function isPropPinned(propertyId: string) {
    const propertyIndex = projectStore.activeView?.view.propertyIds.findIndex(
      (id) => id === propertyId,
    )
    return typeof propertyIndex === 'number' && propertyIndex < numPinnedProperties.value
  }

  return {
    canPin: computed(() => true),
    isPinned,
    togglePin,
    numPinnedProperties: computed(() => numPinnedProperties.value),
    getColStyle,
    isPropPinned,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(usePinnedColumn, import.meta.hot))
}

export function providePinnedProp(propertyId: ComputedRef<string | undefined>) {
  const pinnedColumn = usePinnedColumn()
  const isPinned = computed(
    () => typeof propertyId.value === 'string' && pinnedColumn.isPropPinned(propertyId.value),
  )

  provide('pinned-prop', isPinned)
  return isPinned
}

export function injectPinnedProp() {
  return inject('pinned-prop') as ComputedRef<boolean>
}
