import { get } from './client'
import type { BackendMeta } from './types'

export type ListLibraryItems = BackendMeta<'/api/workspaces/{workspace_id}/library/entities', 'get'>

export const listLibraryItems = ({
  workspaceId,
  after,
  first,
}: {
  workspaceId: string
  after: ListLibraryItems['queryParams']['after']
  first: ListLibraryItems['queryParams']['first']
}) =>
  get<ListLibraryItems['body'], ListLibraryItems['successResponse'], ListLibraryItems['path']>(
    `/api/workspaces/${workspaceId}/library/entities`,
    {
      order_by: ['id'],
      after,
      first,
    },
  )
