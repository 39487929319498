<script setup lang="ts">
import { toRef } from 'vue'
import type { Message } from '../Project/useAskGo'

import { useProfileImage } from '@/modules/WorkspaceSettings/useProfileImage'
import AvatarIcon from '@/uiKit/AvatarIcon.vue'
import IconSprite from '@/uiKit/IconSprite.vue'
import RichNonEditableText from '@/uiKit/RichNonEditableText.vue'
import IconButton from '@/uiKit/IconButton.vue'
import { useAskGo } from '@/modules/Project/useAskGo'

const props = defineProps<{
  message: Message
}>()

const askGo = useAskGo()

const updateScore = (score: 1 | -1 | null | undefined) => {
  if (!props.message.id) {
    return
  }

  const currentScore = props.message.score
  const newScore = score === currentScore ? null : score

  askGo.updateMessageScore(props.message.id, newScore)
}
</script>

<template>
  <div
    class="flex"
    :class="message.authorId ? 'justify-end' : 'justify-start'"
  >
    <div
      v-if="message.text && !message.filesUploaded"
      class="group relative flex items-center gap-2"
      data-mask-parent
    >
      <!-- Ask Go avatar -->
      <div
        v-if="!message.authorId"
        class="shrink-0 self-start p-0.5"
      >
        <IconSprite
          class="text-icon"
          icon="ask-go"
          size="xxl"
        />
      </div>
      <!-- Text or rich text if the message was authored by a human or Ask Go, respectively -->
      <div
        v-if="message.authorId"
        class="rounded-corner-16 rounded-br-corner-4 bg-background-gray-subtlest px-4 py-2 text-sm-12px-light text-text-subtle"
      >
        {{ message.text }}
      </div>
      <template v-else>
        <RichNonEditableText
          class="w-full text-sm-12px-light text-text"
          data-mask
          :text="message.text"
        />
        <div
          class="absolute inset-0 flex items-center justify-end gap-1 opacity-0 transition duration-200 group-hover:opacity-100"
        >
          <IconButton
            :icon="message.score === 1 ? 'thumbs-up-fill' : 'thumbs-up'"
            size="sm"
            variant="transparent"
            rounded
            @click="updateScore(1)"
          />
          <IconButton
            :icon="message.score === -1 ? 'thumbs-down-fill' : 'thumbs-down'"
            size="sm"
            variant="transparent"
            rounded
            @click="updateScore(-1)"
          />
        </div>
      </template>
      <!-- User avatar -->
      <AvatarIcon
        v-if="message.authorId"
        class="shrink-0 self-end"
        size="md"
        shape="circle"
        :url="useProfileImage(toRef(message.authorId)).value"
      />
    </div>
    <div
      v-if="message.filesUploaded"
      class="flex flex-col gap-2"
    >
      <div
        v-if="message.text"
        class="flex justify-end gap-2"
      >
        <div
          class="max-w-[260px] rounded-corner-16 rounded-br-corner-4 bg-background-gray-subtlest px-4 py-2 text-sm-12px-light text-text-subtle"
        >
          {{ message.text }}
        </div>
        <!-- User avatar -->
        <AvatarIcon
          v-if="message.authorId"
          class="shrink-0 self-end"
          size="md"
          shape="circle"
          :url="useProfileImage(toRef(message.authorId)).value"
        />
      </div>
      <div class="flex justify-end gap-2">
        <!-- Files uploaded message -->
        <div
          class="flex max-w-[260px] items-center gap-1 rounded-corner-16 rounded-br-corner-4 bg-background-gray-subtlest px-4 py-2 text-sm-12px-light text-text-subtle"
        >
          <IconSprite icon="check" />
          {{ message.filesUploaded }}
          {{ message.filesUploaded === 1 ? 'file' : 'files' }} uploaded
        </div>
        <!-- User avatar -->
        <AvatarIcon
          v-if="message.authorId"
          class="shrink-0 self-end"
          size="md"
          shape="circle"
          :url="useProfileImage(toRef(message.authorId)).value"
        />
      </div>
    </div>
  </div>
</template>

<style scoped>
@property --color {
  syntax: '<color>';
  initial-value: black;
  inherits: false;
}
[data-mask] {
  mask-image: linear-gradient(to right, black, var(--color) 90%);
  transition: --color 0.2s ease;
}

[data-mask-parent]:hover [data-mask] {
  --color: transparent;
}
</style>
