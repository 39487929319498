import { get } from './client'
import type { BackendMeta } from './types'

export type GetUserWorkspacePermissions = BackendMeta<
  '/api/workspaces/{workspace_id}/members/{user_id}',
  'get'
>

export type GetUserWorkspacePermissionsParams = {
  workspaceId: GetUserWorkspacePermissions['pathParams']['workspace_id']
  userId: GetUserWorkspacePermissions['pathParams']['user_id']
}

/**
 * Get the user's permissions for a given Workspace.
 */
export const getUserWorkspacePermissions = async (params: GetUserWorkspacePermissionsParams) => {
  return await get<
    undefined,
    GetUserWorkspacePermissions['successResponse'],
    GetUserWorkspacePermissions['path']
  >(`/api/workspaces/${params.workspaceId}/members/${params.userId}`, undefined)
}
