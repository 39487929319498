<script setup lang="ts">
import { omit, type ComponentProps } from '@/shared/utils'
import FloatingMenu from '@/uiKit/FloatingMenu.vue'
import IconButton from '@/uiKit/IconButton.vue'
import ListMenuContainer from '@/uiKit/ListMenuContainer.vue'
import ListMenuItem from '@/uiKit/ListMenuItem.vue'
import { computed, ref } from 'vue'
import { useRouter } from 'vue-router'
import DeleteProjectDialog from './DeleteProjectDialog.vue'
import { copyToClipboard } from '@/shared/clipboard'
import DividerLine from '@/uiKit/DividerLine.vue'
import { uploadProjectCoverImage } from '@/backend/uploadProjectCover'
import { useProjects } from '@/modules/Projects/useProjects'
import { deleteProjectCoverImage } from '@/backend/deleteProjectCover'
import { useRecentProjects } from '@/modules/Projects/useRecentProjects'
import ToolTip from '@/uiKit/ToolTip.vue'

const props = withDefaults(
  defineProps<{
    workspaceId: string
    projectId: string
    size?: ComponentProps<typeof IconButton>['size']
    canDelete: boolean | undefined
    canUpdateCoverImage: boolean | undefined
  }>(),
  {
    size: 'xs',
  },
)

const isDeleting = ref(false)

const { resolve, currentRoute, replace } = useRouter()
const onDeleteProject = () => {
  isDeleting.value = false
  // Handle the case where the user has deleted the project that
  // they are currently viewing
  if (currentRoute.value.params.projectId === props.projectId) {
    replace({ name: 'WorkspaceProjects', params: { workspaceId: props.workspaceId } })
  }
}

const link = computed(() =>
  resolve({
    name: 'WorkspaceProject',
    params: { workspaceId: props.workspaceId, projectId: props.projectId },
  }),
)

const IDS = {
  newTab: 'new-tab',
  newWindow: 'new-window',
  copyProjectId: 'copy-project-id',
  edit: 'edit',
  favorite: 'favorite',
  duplicate: 'duplicate',
  delete: 'delete',
  uploadProjectCover: 'upload-project-cover',
  removeProjectCover: 'remove-project-cover',
}

const onSelect = (id: string) => {
  switch (id) {
    case IDS.newTab: {
      const { href } = link.value
      window.open(href, '_blank')
      break
    }
    case IDS.newWindow: {
      const { href } = link.value
      window.open(href, '_blank', 'toolbar=0,location=0,menubar=0')
      break
    }
    case IDS.copyProjectId: {
      copyToClipboard('Project ID', props.projectId)
      break
    }
    case IDS.delete: {
      isDeleting.value = true
      break
    }
    case IDS.removeProjectCover: {
      handleRemoveProjectCover()
      break
    }
    default:
      break
  }
}

const handleRemoveProjectCover = async () => {
  setCoverImageUploading(true)

  await deleteProjectCoverImage({
    workspaceId: props.workspaceId,
    projectId: props.projectId,
  })

  setCoverImageUploading(false)
}

const fileInput = ref<HTMLInputElement | null>(null)

const handleFileUpload = async (event: Event) => {
  const files = (event.target as HTMLInputElement).files
  if (files && files.length > 0) {
    const file = files[0]

    const formData = new FormData()
    formData.append('file', file)

    setCoverImageUploading(true)

    await uploadProjectCoverImage({
      workspaceId: props.workspaceId,
      projectId: props.projectId,
      formData,
    })

    setCoverImageUploading(false)
  }
}

const projectsStore = useProjects()
const recentProjectsStore = useRecentProjects()

const setCoverImageUploading = (value: boolean) => {
  projectsStore.setCoverImageDownloadError(props.projectId, false)
  recentProjectsStore.setCoverImageDownloadError(props.projectId, false)

  projectsStore.setCoverImageUploading(props.projectId, value)
  recentProjectsStore.setCoverImageUploading(props.projectId, value)
}
</script>

<template>
  <FloatingMenu
    v-bind="$attrs"
    @select="onSelect"
  >
    <template #trigger="{ triggerProps }">
      <IconButton
        icon="more-dots"
        data-test="project-menu-trigger"
        :size="size"
        variant="transparent"
        v-bind="{ ...omit(triggerProps, ['disabled']), ...$attrs }"
        @click="(e) => e.preventDefault()"
        >Click me</IconButton
      >
    </template>
    <template #content="{ contentProps, getItemProps }">
      <ListMenuContainer
        v-bind="contentProps"
        class="min-w-56"
      >
        <div class="flex w-full flex-col p-0.5">
          <ListMenuItem
            element="button"
            v-bind="omit(getItemProps({ value: IDS.newTab }), ['onSelect'])"
            icon="arrow-top-right"
          >
            Open in a new tab
          </ListMenuItem>
          <ListMenuItem
            element="button"
            v-bind="omit(getItemProps({ value: IDS.newWindow }), ['onSelect'])"
            icon="arrow-top-right"
            >Open in a new window</ListMenuItem
          >
          <ToolTip
            class="w-full"
            :arrow="true"
            :placement="{ allowedPlacements: ['left'] }"
            :title="projectId"
          >
            <ListMenuItem
              element="button"
              v-bind="omit(getItemProps({ value: IDS.copyProjectId }), ['onSelect'])"
              icon="copy"
            >
              Copy project ID
            </ListMenuItem>
          </ToolTip>
        </div>
        <template v-if="canUpdateCoverImage">
          <DividerLine color="subtle" />
          <div class="flex w-full flex-col p-0.5">
            <ListMenuItem
              element="button"
              v-bind="omit(getItemProps({ value: IDS.uploadProjectCover }), ['onSelect'])"
              icon="picture"
              class="relative"
            >
              Upload cover picture
              <input
                ref="fileInput"
                class="absolute inset-0 size-full cursor-pointer opacity-0"
                type="file"
                @change="handleFileUpload"
              />
            </ListMenuItem>
            <ListMenuItem
              element="button"
              v-bind="omit(getItemProps({ value: IDS.removeProjectCover }), ['onSelect'])"
              icon="remove-picture"
              >Delete cover picture</ListMenuItem
            >
          </div>
        </template>
        <template v-if="canDelete">
          <DividerLine color="subtle" />
          <div class="flex w-full flex-col p-0.5">
            <ListMenuItem
              element="button"
              v-bind="omit(getItemProps({ value: IDS.delete }), ['onSelect'])"
              icon="trash"
              critical
              >Delete project</ListMenuItem
            >
          </div>
        </template>
      </ListMenuContainer>
    </template>
  </FloatingMenu>

  <DeleteProjectDialog
    :project-id="projectId"
    :workspace-id="workspaceId"
    :open="isDeleting"
    @delete="onDeleteProject"
    @close="isDeleting = false"
  />
</template>
