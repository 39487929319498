<script lang="ts" setup>
import { computed } from 'vue'
import type { IconName } from './IconName'
import AskGoIcon from '@/icons/put-icons-here/ask-go.svg'
import BingIcon from '@/icons/put-icons-here/bing-logo.svg'
import GoogleIcon from '@/icons/put-icons-here/company-google.svg'
import PythonLanguageIcon from '@/icons/put-icons-here/python-language.svg'
import ZapierIcon from '@/icons/put-icons-here/company-zapier.svg'
import AzureIcon from '@/icons/put-icons-here/company-azure.svg'

const props = withDefaults(
  defineProps<{
    icon: IconName
    size?: 'xxxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl' | 'xxxl' | 'xxxxl'
  }>(),
  { size: 'md' },
)

// Our icon font solution doesn't support multicolour icons, or icons
// that always need to be rendered in the same colours. Typically,
// external company icons always need to be rendered in their own
// colours, so we handle these cases separately.
const iconClass = computed(() => {
  return [
    'company-google',
    'python-language',
    'bing-logo',
    'company-zapier',
    'ask-go',
    'company-azure',
  ].includes(props.icon)
    ? ''
    : `icon-${props.icon}`
})

const sizes = {
  xxxs: ['text-[8px] leading-[8px]', 8],
  xs: ['text-[12px] leading-3', 12],
  sm: ['text-[14px] leading-[14px]', 14],
  md: ['text-[14px] leading-[14px]', 14],
  lg: ['text-[16px] leading-4', 16],
  xl: ['text-[16px] leading-4', 16],
  xxl: ['text-[20px] leading-5', 20],
  xxxl: ['text-[24px] leading-10', 24],
  xxxxl: ['text-[40px] leading-10', 40],
}
</script>

<template>
  <span
    class="flex items-center justify-center"
    :class="[iconClass, sizes[props.size][0]]"
  >
    <BingIcon
      v-if="icon === 'bing-logo'"
      :width="sizes[props.size][1]"
      :height="sizes[props.size][1]"
    />
    <GoogleIcon
      v-else-if="icon === 'company-google'"
      :width="sizes[props.size][1]"
      :height="sizes[props.size][1]"
    />
    <PythonLanguageIcon
      v-else-if="icon === 'python-language'"
      :width="sizes[props.size][1]"
      :height="sizes[props.size][1]"
    />
    <ZapierIcon
      v-else-if="icon === 'company-zapier'"
      class="size-full"
    />
    <AskGoIcon
      v-else-if="icon === 'ask-go'"
      :width="sizes[props.size][1]"
      :height="sizes[props.size][1]"
    />
    <AzureIcon
      v-else-if="icon === 'company-azure'"
      :width="sizes[props.size][1]"
      :height="sizes[props.size][1]"
    />
  </span>
</template>
