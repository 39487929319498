<script lang="ts">
export type PopupMenuChangeOpenReason = 'trigger' | 'clickOutside' | 'triggerContext'
</script>

<script lang="ts" setup>
/**
 * Is used whenever we need to render a popup menu that is anchored to a trigger element. Is intentionally very basic so that it can be flexible.
 *
 * It is anticipated that other reusable components will extend this and add extra functionality.
 * e.g. the SelectDropdown component is meant to be a styled replacement for the <select> element, so adds functionality like closing on escape,
 * closing on click outside, internal handling of open state etc.
 *
 * When finalizing this component, be sure to read documentaiton on floating-ui
 * to figure out things like auto-placement and sizing, etc.
 */
import {
  autoUpdate,
  autoPlacement as fuiAutoPlacement,
  flip as fuiFlip,
  offset as fuiOffset,
  size,
  useFloating,
  type AutoPlacementOptions,
  type FlipOptions,
  type OffsetOptions,
  type Placement,
} from '@floating-ui/vue'
import { onClickOutside } from '@vueuse/core'
import { computed, inject, nextTick, provide, ref, watch } from 'vue'
import { useFocusTrap } from '@vueuse/integrations/useFocusTrap'

const props = withDefaults(
  defineProps<{
    /**
     * High level rules for how the popup should be placed.
     *
     * @see https://floating-ui.com/docs/autoPlacement
     */
    autoPlacement?: AutoPlacementOptions
    disableFocusTrap?: boolean
    disableCloseOnClickOutside?: boolean
    flip?: FlipOptions
    matchTargetWidth?: boolean
    /**
     * Apply an offset to the final position of the popup. Is useful when you
     * want there to be whitespace between the trigger and the popup, or if you
     * want the popup to cover the trigger.
     *
     * @see https://floating-ui.com/docs/offset
     */
    offset?: OffsetOptions
    open: boolean
    openOnRightClick?: boolean
    placement?: Placement
    /**
     * The element to use as the trigger. Defaults to a button, to correctly handle
     * keyboard events. If you need to use a different element, or if the element that's
     * being passed to the trigger slot is itself a button, you can set this to 'div'.
     *
     * @default 'button'
     */
    triggerElement?: 'button' | 'div'
    /**
     * The element to teleport the popup to. Defaults to 'body'. Can be used to teleport
     * the popup to a modal dialog, so that the modal's onClickOutside handler doesn't
     * trigger when clicking on the popup.
     */
    teleportTo?: string
    /**
     * When set, the popup will be teleported when this component is a child of
     * another PopupMenu. This is useful if the parent menu has overflow
     * styles that would clip the child menu if teleporting were disabled.
     *
     */
    enableNestedTeleport?: boolean
  }>(),
  {
    autoPlacement: undefined,
    disableFocusTrap: false,
    disableCloseOnClickOutside: false,
    flip: undefined,
    matchTargetWidth: false,
    offset: undefined,
    openOnRightClick: false,
    placement: undefined,
    triggerElement: 'button',
    teleportTo: 'body',
    enableNestedTeleport: false,
  },
)

const emit = defineEmits<{
  (eventName: 'click:outside', event: PointerEvent): void
  (eventName: 'click:trigger', event: PointerEvent | MouseEvent): void
  (eventName: 'change:open', open: boolean, changeReason: PopupMenuChangeOpenReason): void
}>()

const target = ref()
const floating = ref()

const middleware = computed(() => [
  fuiOffset(props.offset),
  // autoPlacement and flip are conflicting, only one can be present
  props.autoPlacement ? fuiAutoPlacement(props.autoPlacement) : fuiFlip(props.flip),
  size({
    apply({ elements, rects }) {
      if (props.matchTargetWidth) {
        elements.floating.style.width = rects.reference.width + 'px'
      }
    },
  }),
])

const { floatingStyles } = useFloating(target, floating, {
  placement: props.autoPlacement ? undefined : props.placement, // placement shouldn't be used when autoPlacement is used
  middleware,
  whileElementsMounted: autoUpdate,
})

onClickOutside(
  floating,
  (e) => {
    if (props.disableCloseOnClickOutside) return
    emit('click:outside', e)
    emit('change:open', false, 'clickOutside')

    const isTrigger = e.target === target.value || target.value?.contains(e.target as Node)
    if (isTrigger) {
      e.stopPropagation()
    }
  },
  {
    ignore: [floating],
  },
)

function handleClick(e: MouseEvent) {
  if (props.triggerElement !== 'button') {
    return
  }

  // prevent context menu from opening if openOnRightClick is false
  const reason = e.type == 'contextmenu' ? 'triggerContext' : 'trigger'
  if (reason === 'triggerContext' && !props.openOnRightClick) {
    return
  }

  e.stopPropagation()
  emit('click:trigger', e)
  emit('change:open', !props.open, reason)
}

const handleClickTriggerSlot = (e: MouseEvent) => {
  e.stopPropagation()
  emit('click:trigger', e)
  emit('change:open', !props.open, 'trigger')
}

// Disable teleporting when this component is a child of another PopupMenu
const isNested = inject('popup', false)
provide('popup', true)

const { activate, deactivate } = useFocusTrap(floating)
watch(
  () => props.open,
  async (open) => {
    await nextTick()

    if (open && !props.disableFocusTrap) {
      activate()
    } else {
      deactivate()
    }
  },
  { immediate: true },
)
</script>

<template>
  <component
    :is="triggerElement"
    ref="target"
    :type="triggerElement === 'button' ? 'button' : undefined"
    data-test="popup-menu-trigger"
    class="group/popup-trigger outline-none"
    v-bind="$attrs"
    style="text-align: unset"
    @click="handleClick"
    @contextmenu.prevent="handleClick"
  >
    <slot
      name="trigger"
      :click="handleClickTriggerSlot"
    />
  </component>
  <!-- teleport is needed because overflow:hidden/auto parents will clip the dropdown  -->
  <!-- it's not handled by the floating-ui -->
  <!-- one parent example is the property sidebar -->
  <Teleport
    v-if="open"
    :to="teleportTo"
    :disabled="!enableNestedTeleport && isNested"
  >
    <div
      ref="floating"
      data-test="popup-menu-focus-trap"
      class="box-border flex h-auto min-h-0 shrink grow-0 flex-col"
      :style="floatingStyles"
    >
      <slot name="dropdown" />
    </div>
  </Teleport>
</template>
