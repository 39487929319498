<script lang="ts">
const isOpen = ref(false)

export function useCsvImportModal() {
  const { fieldUsage } = useBilling()
  const paywall = usePaywallStore()
  const { captureAnalyticsEvent } = useAnalytics()

  return {
    open() {
      captureAnalyticsEvent(ANALYTICS_EVENT.CSV_CLICK_IMPORT)
      if (fieldUsage && fieldUsage.limitUsage >= fieldUsage.limitValue) {
        paywall.dialogIsOpen = true
      } else {
        isOpen.value = true
      }
    },
    close() {
      isOpen.value = false
    },
    isOpen,
  }
}
</script>

<script setup lang="ts">
import { getUploadUrl } from '@/backend/getUploadUrl'
import { prepareImport } from '@/backend/prepareImport'
import { startImport } from '@/backend/startImport'
import { uploadFile } from '@/backend/uploadFile'
import { toast } from '@/shared/toast'
import DarwinButton from '@/uiKit/DarwinButton.vue'
import FileUpload from '@/uiKit/FileUpload.vue'
import IconButton from '@/uiKit/IconButton.vue'
import IconSprite from '@/uiKit/IconSprite.vue'
import ModalDialog from '@/uiKit/ModalDialog.vue'
import TextField from '@/uiKit/TextField.vue'
import { useElementBounding } from '@vueuse/core'
import { computed, ref, watchEffect } from 'vue'
import ModalStep from './ModalStep.vue'
import LinearProgress from '@/uiKit/LinearProgress.vue'
import * as sentry from '@sentry/vue'
import { useBilling } from '../Billing/useBilling'
import { usePaywallStore } from '../Billing/paywall'
import { ANALYTICS_EVENT, useAnalytics } from '@/sharedComposables/useAnalytics'
import type { ExternalToast } from '@/shared/toast/types'

const props = defineProps<{
  workspaceId: string
}>()

const { captureAnalyticsEvent } = useAnalytics()
const paywall = usePaywallStore()

const file = ref<File | null>(null)
const name = ref('')
const isLoading = ref(false)
const uploadProgress = ref<number | null>(null)
const importId = ref('')

const Steps = {
  ChoosingFile: 1,
  NamingProject: 2,
  Uploading: 3,
}
const step = computed(() => {
  if (isLoading.value && uploadProgress.value !== null) return Steps.Uploading
  if (file.value) return Steps.NamingProject
  return Steps.ChoosingFile
})

const innerRef = ref<HTMLDivElement>()
const { height } = useElementBounding(innerRef)

const enableHeightTransition = ref(false)
watchEffect((onCleanup) => {
  if (!isOpen.value) {
    enableHeightTransition.value = false
    return
  }
  const timeout = setTimeout(() => {
    enableHeightTransition.value = true
  }, 100)

  onCleanup(() => clearTimeout(timeout))
})

function handleError(error: { code: string; message: string }) {
  captureAnalyticsEvent(ANALYTICS_EVENT.CSV_IMPORT_FAILURE)
  isLoading.value = false
  uploadProgress.value = null

  let description = error.message
  let action: ExternalToast['action'] | undefined

  switch (error.code) {
    case 'duplicate_name': {
      description = 'A project with this name already exists'
      break
    }
    case 'internal_server_error': {
      description = "We weren't able to import your file. Please try again."
      break
    }
    case 'system_limits_exceeded': {
      break
    }
    case 'billing_limits_exceeded': {
      description = "You have reached your plan's limit. Please upgrade your plan."
      action = {
        label: 'Upgrade',
        onClick: () => {
          isOpen.value = false
          paywall.dialogIsOpen = true
        },
      }
      break
    }
    case 'bad_request': {
      break
    }
    case 'invalid_import_file': {
      break
    }
    default: {
      sentry.captureException(new Error('Unexpected Import error'))
      break
    }
  }

  toast.error('Import failed', {
    description,
  })
}

async function importCsv() {
  if (!file.value) return
  captureAnalyticsEvent(ANALYTICS_EVENT.CSV_BEGIN_IMPORT)
  uploadProgress.value = null
  isLoading.value = true

  const result = await prepareImport({
    projectName: name.value,
    workspaceId: props.workspaceId,
  })
  if (!result.ok) {
    handleError(result.error)
    return
  }

  importId.value = result.data.id
  const uploadUrl = await getUploadUrl({
    workspaceId: props.workspaceId,
    importId: importId.value,
  })

  if (!uploadUrl.ok) {
    handleError(uploadUrl.error)
    return
  }

  uploadProgress.value = 0
  const s3Res = await uploadFile(uploadUrl.data.upload_url, file.value, (progress) => {
    uploadProgress.value = progress * 100
  })

  if (!s3Res.ok) {
    handleError(s3Res.error)
    return
  }
  isLoading.value = true

  // Start import
  const startRes = await startImport({
    workspaceId: props.workspaceId,
    importId: importId.value,
  })

  if (!startRes.ok) {
    handleError(startRes.error)
    return
  }

  uploadProgress.value = 100
  isLoading.value = false
  isOpen.value = false
  file.value = null
}

async function onChooseFile(files: File[]) {
  file.value = files[0]
  name.value = file.value?.name
    .replace(/\..*?$/, '') // Remove file extension
    .replace(/[^a-zA-Z0-9]/g, ' ') // Remove special characters
    .split(/\s+/g)
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize letters
    .join(' ')
    .trim()
}
</script>

<template>
  <ModalDialog
    :open="isOpen"
    data-test="csv-import-modal"
    @close="
      () => {
        if (isLoading) return
        isOpen = false
      }
    "
  >
    <div class="relative flex w-[480px] flex-col justify-center">
      <IconButton
        icon="close"
        size="lg"
        variant="transparent"
        class="absolute right-2 top-2 z-10 text-text-subtle"
        :disabled="isLoading"
        @click="
          () => {
            if (isLoading) return
            isOpen = false
          }
        "
      />

      <div
        :style="{ height: `${height}px` }"
        class="overflow-hidden transition-all"
        :class="enableHeightTransition ? 'duration-[600ms]' : 'duration-0'"
      >
        <div
          ref="innerRef"
          class="relative p-4"
        >
          <h1 class="text-sm-12px-default text-text">Import CSV</h1>
          <span class="text-sm-12px-light text-text-subtle">
            Create a new project from your CSV data
          </span>
          <div class="relative mt-4">
            <ModalStep :step="step">
              <div v-if="step === Steps.Uploading">
                <div
                  class="rounded-corner-10 border border-dashed border-border-subtle bg-background-gray-subtlest px-[80px] py-[2.55rem] text-center hover:bg-background-gray-subtlest-hovered"
                >
                  <div class="flex w-full items-center gap-2">
                    <LinearProgress
                      class="flex-1"
                      :value="uploadProgress"
                      size="sm"
                      aria-label="Upload progress"
                    />
                    <span class="w-[30px] text-left text-sm-12px-default text-text-subtle"
                      >{{ Math.round(uploadProgress ?? 0) }}%</span
                    >
                  </div>
                </div>
              </div>
              <div v-else-if="step === Steps.NamingProject">
                <div
                  class="flex items-center rounded-corner-10 border border-dashed border-border-subtle bg-background-gray-subtlest px-2"
                >
                  <IconSprite
                    icon="upload"
                    size="md"
                    class="text-icon-subtle"
                  />
                  <span class="ml-2 py-2 text-sm-12px-default text-text-subtle">{{
                    file?.name
                  }}</span>
                  <IconButton
                    icon="trash"
                    size="md"
                    variant="transparent"
                    class="ml-auto"
                    :disabled="isLoading"
                    @click="() => (file = null)"
                  />
                </div>
                <TextField
                  size="lg"
                  placeholder="Project name"
                  label="Name of the project"
                  class="mt-4"
                  :value="name"
                  :disabled="isLoading"
                  @change="name = $event"
                />
              </div>
              <div v-else>
                <FileUpload
                  placeholder="Choose a CSV file"
                  accept=".csv"
                  @change="onChooseFile"
                  @reject="toast.error('The provided file is not supported.')"
                />
              </div>
            </ModalStep>
          </div>
        </div>
      </div>
      <div class="flex flex-row items-center justify-end gap-2 border-t border-border-subtle p-2">
        <DarwinButton
          variant="neutral"
          size="sm"
          :disabled="isLoading"
          @click="isOpen = false"
        >
          Cancel
        </DarwinButton>
        <DarwinButton
          variant="black"
          size="sm"
          :disabled="!name || !file || isLoading"
          :loading="isLoading"
          data-test="csv-import-submit"
          @click="importCsv"
        >
          Import CSV
        </DarwinButton>
      </div>
    </div>
  </ModalDialog>
</template>
