<script setup lang="ts">
import { useBilling } from '@/modules/Billing/useBilling'
import DarwinButton from '@/uiKit/DarwinButton.vue'
import IconSprite from '@/uiKit/IconSprite.vue'
import BillingCurrentPlan from './BillingCurrentPlan.vue'
import { useLoadBilling } from '@/modules/Billing/useLoadBilling'
import { toRef } from 'vue'
import { usePermissionsStore } from '../IdentityAndAccess/permissionsStore'

const props = defineProps<{ workspaceId: string }>()

const billingStore = useBilling()
useLoadBilling(toRef(props, 'workspaceId'))

const permissionsStore = usePermissionsStore()
</script>

<template>
  <div class="m-auto flex max-w-[804px] flex-col items-stretch pt-16">
    <div class="mx-[22px] mb-8">
      <h1 class="mb-1 text-xl-18px-bold text-text">Billing and Usage</h1>
      <p class="text-sm-12px-light text-text-subtle">
        Update your payment information or switch plans according to your needs.
      </p>
    </div>
    <BillingCurrentPlan
      v-if="billingStore.activePlan && billingStore.fieldUsage && billingStore.tokenUsage"
      :plan="billingStore.activePlan"
      :field-usage="billingStore.fieldUsage"
      :token-usage="billingStore.tokenUsage"
      :workspace-id="workspaceId"
    />

    <div
      v-if="billingStore.customerPortalUrl && permissionsStore.workspacePermissions.manage_billing"
      class="mx-[22px] mt-8 flex justify-between"
    >
      <div>
        <h2 class="mb-0.5 text-sm-12px-default text-text">Billing Details</h2>
        <p class="text-sm-12px-light text-text-subtle">Manage your subscription in Stripe</p>
      </div>
      <DarwinButton
        variant="neutral"
        size="sm"
        :href="billingStore.customerPortalUrl"
      >
        Edit billing details
        <template #trailing-icon>
          <IconSprite icon="arrow-top-right" />
        </template>
      </DarwinButton>
    </div>
  </div>
</template>
