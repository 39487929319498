import { Permission } from '@/backend/types'
import type { PartialRecord } from '@/shared/types'
import { watch, type Ref } from 'vue'
import { FeatureFlag, useFeatureFlags } from '../App/useFeatureFlags'
import { usePermissionsStore } from './permissionsStore'
import { useUser } from './useUser'

const ALL_PERMISSIONS_ENABLED = Object.values(Permission).reduce<PartialRecord<Permission, true>>(
  (acc, permission) => {
    return { ...acc, [permission]: true }
  },
  {},
)

/**
 * Reacts to changes in a workspace ID ref to load the user's permissions for
 * the current workspace.
 */
export const useLoadWorkspacePermissions = (workspaceId: Ref<string>) => {
  const userStore = useUser()
  const permissionStore = usePermissionsStore()

  const godMode = useFeatureFlags(FeatureFlag.ENABLE_ALL_FRONTEND_ACTIONS)
  watch(
    () => [workspaceId.value, userStore.user?.id, godMode.value] as const,
    ([newWorkspaceId, newUserId, isInGodMode], old) => {
      const [oldWorkspaceId, oldUserId] = old ?? []
      const nothingHasChanged = newWorkspaceId === oldWorkspaceId && newUserId === oldUserId
      if (!newUserId || nothingHasChanged) {
        return
      }

      if (isInGodMode) {
        Object.keys(permissionStore.allProjectPermissions).forEach((projectId) => {
          permissionStore.allProjectPermissions[projectId] = ALL_PERMISSIONS_ENABLED
        })
        permissionStore.workspacePermissions = ALL_PERMISSIONS_ENABLED
      } else {
        permissionStore.loadWorkspacePermissions({
          userId: newUserId,
          workspaceId: newWorkspaceId,
        })
      }
    },
    { immediate: true },
  )
}
