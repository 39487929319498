import type { components } from '@/api'
import { put } from './client'

export type SetFieldValuePath = `/api/workspaces/${string}/projects/${string}/entities/${string}`
export type SetFieldValueRequest = components['schemas']['update_entity']

export type UpdateEntityFieldValue = Extract<
  components['schemas']['field_value'],
  Record<string, unknown> | null
>

export type PutEntityResponse = components['schemas']['Projects.Entities.EntityResponse']

export type SetFieldValueParams = {
  workspaceId: string
  projectId: string
  entityId: string
  // Mapping of property ID to new value
  fields: Record<string, UpdateEntityFieldValue>
}

/**
 * Updates the field values for an entity. Can update any number of fields
 * for the entity.
 */
export const setFieldValue = ({
  workspaceId,
  projectId,
  entityId,
  fields,
}: SetFieldValueParams) => {
  return put<SetFieldValueRequest, PutEntityResponse, SetFieldValuePath>(
    `/api/workspaces/${workspaceId}/projects/${projectId}/entities/${entityId}`,
    { fields },
  )
}
