import type { operations } from '@/api'
import { post } from './client'

export type AddEntityPath = `/api/workspaces/${string}/projects/${string}/entities`

export type AddEntityRequest = Exclude<
  operations['entity-create']['requestBody'],
  undefined
>['content']['application/json']
export type AddEntityResponse =
  operations['entity-create']['responses']['200']['content']['application/json']

export const addEntity = (workspaceId: string, projectId: string) => {
  return post<AddEntityRequest, AddEntityResponse, AddEntityPath>(
    `/api/workspaces/${workspaceId}/projects/${projectId}/entities`,
    {},
  )
}
