import { setEntityMetadata } from '@/backend/setEntityMetadata'
import { useWorkspaces } from '@/modules/Workspaces/useWorkspaces'
import { useGroundingStore } from './useGroundingStore'
import { useProject } from './useProject'

export function useGroundingFeedback() {
  const workspacesStore = useWorkspaces()
  const projectStore = useProject()
  const groundingStore = useGroundingStore()

  const submitGroundingFeedback = async (score: 1 | -1 | null) => {
    if (!workspacesStore.currentWorkspace || !projectStore.projectId || !groundingStore.field) {
      throw new Error(
        'No current workspace, project, or grounding field found when submitting grounding feedback',
      )
    }

    const pastScore = groundingStore.field.score
    const newScore = score === pastScore ? null : score
    groundingStore.field.score = newScore

    const response = await setEntityMetadata({
      workspaceId: workspacesStore.currentWorkspace.id,
      projectId: projectStore.projectId,
      entityId: groundingStore.field.entityId,
      propertyIdOrSlug: groundingStore.field.propertyId,
      score: newScore,
    })

    if (!response.ok) {
      groundingStore.field.score = pastScore
      throw new Error('Failed to submit grounding feedback')
    }
  }

  return submitGroundingFeedback
}
