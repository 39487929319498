<script setup lang="ts">
import { computed, ref } from 'vue'
import { useRouter } from 'vue-router'

import { useWorkspaces } from '@/modules/Workspaces/useWorkspaces'
import { ANALYTICS_EVENT, useAnalytics } from '@/sharedComposables/useAnalytics'
import { doExportProject } from '@/shared/utils/exports'
import AvatarIcon from '@/uiKit/AvatarIcon.vue'
import DarwinButton from '@/uiKit/DarwinButton.vue'
import IconButton from '@/uiKit/IconButton.vue'
import ModalDialog from '@/uiKit/ModalDialog.vue'
import SegmentedControl, { type SegmentedControlItem } from '@/uiKit/SegmentedControl.vue'

import { useProject } from './useProject'
import { useLibraryStore } from '../Library/libraryStore'
import { usePermissionsStore } from '../IdentityAndAccess/permissionsStore'

const router = useRouter()

const { captureAnalyticsEvent } = useAnalytics()
const projectStore = useProject()
const workspacesStore = useWorkspaces()
const permissionsStore = usePermissionsStore()

const superView = computed(() => {
  const route = router.currentRoute.value

  if (route.name === 'WorkflowView') {
    return 'workflow'
  }

  if (
    route.name === 'WorkspaceProjectEntityView' ||
    route.name === 'WorkspaceProjectEntitySubView' ||
    route.name === 'WorkspaceProjectEntityViewEmpty'
  ) {
    return 'entity'
  }

  return 'main'
})

const viewOptions = computed<SegmentedControlItem<'main' | 'entity' | 'workflow'>[]>(() => {
  const options: SegmentedControlItem<'main' | 'entity' | 'workflow'>[] = [
    {
      label: 'Build',
      value: 'main',
      width: 80,
    },
    {
      label: 'Review',
      value: 'entity',
      width: 80,
    },
  ]

  if (permissionsStore.currentProjectPermissions.update_projects) {
    options.push({
      label: 'Automate',
      value: 'workflow',
      width: 80,
    })
  }

  return options
})

const onActiveViewChange = (event: 'main' | 'entity' | 'workflow') => {
  if (event === 'workflow') {
    return router.push({
      name: 'WorkflowView',
      params: {
        workspaceId: workspacesStore.currentWorkspace?.id,
        projectId: projectStore.projectId,
      },
    })
  }

  const activeView = projectStore.activeView
  if (!activeView) {
    return
  }

  if (event === 'entity') {
    if (!(activeView.entities && activeView.entities.length > 0 && activeView.entities[0])) {
      return router.push({
        name: 'WorkspaceProjectEntityViewEmpty',
        params: {
          workspaceId: workspacesStore.currentWorkspace?.id,
          projectId: projectStore.projectId,
        },
      })
    }
    if (!projectStore.activeView) {
      return router.push({
        name: 'WorkspaceProjectEntityView',
        params: {
          workspaceId: workspacesStore.currentWorkspace?.id,
          projectId: projectStore.projectId,
          entityId: activeView.entities[0].id,
        },
      })
    } else {
      return router.push({
        name: 'WorkspaceProjectEntitySubView',
        params: {
          workspaceId: workspacesStore.currentWorkspace?.id,
          projectId: projectStore.projectId,
          entityId: activeView.entities[0].id,
          viewId: projectStore.activeView.id,
        },
      })
    }
  }

  if (event === 'main') {
    if (!projectStore.activeView) {
      return router.push({
        name: 'WorkspaceProjectTable',
        params: {
          workspaceId: workspacesStore.currentWorkspace?.id,
          projectId: projectStore.projectId,
        },
      })
    } else {
      return router.push({
        name: 'WorkspaceProjectTableView',
        params: {
          workspaceId: workspacesStore.currentWorkspace?.id,
          projectId: projectStore.projectId,
          viewId: projectStore.activeView.id,
        },
      })
    }
  }
}

const exporting = ref(false)
const isExportOpen = ref(false)

const onExport = async () => {
  if (!workspacesStore.currentWorkspace?.id || !projectStore.projectId) return
  captureAnalyticsEvent(ANALYTICS_EVENT.PROJECT_EXPORTED)
  exporting.value = true
  try {
    await doExportProject(workspacesStore.currentWorkspace?.id, projectStore.projectId, 200, 3000) // keep trying for max 200 times (sleep 3000ms = 3s) = 10m
    captureAnalyticsEvent(ANALYTICS_EVENT.PROJECT_EXPORTED)
  } finally {
    exporting.value = false
  }
}

const libraryStore = useLibraryStore()
</script>

<template>
  <!--
    reduce the height and remove the bottom padding when on the table view because
    the table has a 2px top border which is used to highlight input columns, and
    this top border eats into the space used by this top bar.
  -->
  <div
    class="flex w-full items-center border-t border-border-subtle bg-surface-secondary-persist px-2.5"
    :class="superView === 'main' ? 'h-[42px] min-h-[42px] pt-0.5' : 'h-11 min-h-11 py-0.5'"
  >
    <!-- Library -->
    <div class="flex w-1/3 items-center">
      <DarwinButton
        size="sm"
        variant="neutral"
        @click="libraryStore.dialogIsOpen = true"
        >Library</DarwinButton
      >
    </div>

    <!-- Nav Segmented Control -->
    <div class="flex w-1/3 items-center justify-center">
      <div class="sticky right-0">
        <SegmentedControl
          name="viewControl"
          aria-label="View Control"
          :value="superView"
          :items="viewOptions"
          @change="onActiveViewChange"
        />
      </div>
    </div>

    <!-- Export -->
    <div class="flex w-1/3 items-center justify-end">
      <DarwinButton
        v-if="
          workspacesStore.currentWorkspace?.id &&
          projectStore.projectId &&
          permissionsStore.currentProjectPermissions.manage_project_exports
        "
        size="sm"
        variant="neutral"
        :loading="exporting"
        @click="isExportOpen = true"
        >Export</DarwinButton
      >
    </div>
  </div>

  <ModalDialog
    :open="isExportOpen"
    to="body"
    aria-label="Export data"
    class="overflow-hidden"
    :outline="false"
    placement="right"
    :close-on-escape="true"
    @close="isExportOpen = false"
  >
    <div
      class="relative flex h-full w-[352px] flex-col overflow-hidden rounded-corner-10 border-0 bg-surface-popover"
    >
      <IconButton
        size="lg"
        variant="transparent"
        class="absolute right-2 top-2 text-text-subtle"
        icon="close"
        @click="isExportOpen = false"
      />
      <div class="px-5 py-4 text-center text-sm-12px-default text-text-subtle">Export data</div>
      <div class="flex border-t border-border-subtle p-4">
        <AvatarIcon
          icon-name="table"
          class="size-8"
        />
        <div class="flex grow items-center px-3">
          <p class="text-sm-12px-default leading-4 text-text-subtle">Export as CSV</p>
        </div>
        <DarwinButton
          size="sm"
          class="my-0.5 w-[120px]"
          variant="neutral"
          :loading="exporting"
          @click="onExport"
          >Export data</DarwinButton
        >
      </div>
      <div class="flex border-t border-border-subtle p-4">
        <AvatarIcon
          icon-name="company-zapier"
          class="size-8"
        />
        <div class="flex grow items-center px-3">
          <p class="text-sm-12px-default leading-4 text-text-subtle">Send to Zapier</p>
        </div>
        <DarwinButton
          size="sm"
          class="my-0.5 w-[120px]"
          variant="neutral"
          href="https://zapier.com/apps/v7-go/integrations"
          target="_blank"
          >Open Zapier</DarwinButton
        >
      </div>
      <div class="flex border-t border-border-subtle p-4">
        <AvatarIcon
          icon-name="json"
          class="size-8"
        />
        <div class="flex grow items-center px-3">
          <p class="text-sm-12px-default leading-4 text-text-subtle">Send via the API</p>
        </div>
        <DarwinButton
          size="sm"
          variant="neutral"
          class="my-0.5 w-[120px]"
          href="https://docs.go.v7labs.com/reference/generate-an-api-key"
          target="_blank"
          >Open docs</DarwinButton
        >
      </div>
    </div>
  </ModalDialog>
</template>
