<script setup lang="ts">
import { useWorkspaces } from '@/modules/Workspaces/useWorkspaces'
import ListMenuItem from '@/uiKit/ListMenuItem.vue'
import AvatarIcon from '@/uiKit/AvatarIcon.vue'
import { storeToRefs } from 'pinia'

import SelectDropdown from '@/uiKit/SelectDropdown.vue'
import ListMenu from '@/uiKit/ListMenu.vue'
import IconSprite from '@/uiKit/IconSprite.vue'
import { PERMISSIONS_DIALOG_ID } from './consts'
import { computed } from 'vue'

export type ProjectDefaultRole = 'editor' | 'reader' | 'noaccess'

const props = withDefaults(
  defineProps<{
    defaultRole?: ProjectDefaultRole
  }>(),
  { defaultRole: 'reader' },
)
defineEmits<{
  (e: 'update:projectDefaultRole', role: (typeof LIST_ITEMS)[number]['role']): void
}>()

const workspaceStore = useWorkspaces()
const { currentWorkspace } = storeToRefs(workspaceStore)

const roleTitleMap: Record<ProjectDefaultRole, string> = {
  editor: 'Can edit',
  reader: 'Can view',
  noaccess: 'Has no access',
}

const dropdownTriggerText = computed(() => roleTitleMap[props.defaultRole].toLowerCase())

const LIST_ITEMS = [
  {
    role: 'noaccess',
    title: roleTitleMap.noaccess,
    description:
      'This project will be private by default. Only invited users will be able to see it in their workspace.',
  },
  {
    role: 'reader',
    title: roleTitleMap.reader,
    description: 'Can access the project but cannot modify properties or data.',
  },
  {
    role: 'editor',
    title: roleTitleMap.editor,
    description:
      'Everyone in the workspace will be able to see and open this project, even non-invited users.',
  },
] as const
</script>

<template>
  <SelectDropdown
    :offset="{ mainAxis: 5 }"
    :teleport-to="`#${PERMISSIONS_DIALOG_ID}`"
    tabindex="-1"
    match-target-width
  >
    <template #trigger="{ isOpen }">
      <ListMenuItem
        class="py-1.5 focus:bg-background-transparent-hovered"
        :active="isOpen"
        :label="`Everyone in ${currentWorkspace?.name} workspace`"
      >
        <template #prefix>
          <AvatarIcon
            :url="currentWorkspace?.iconUrl"
            :full-text="currentWorkspace?.name"
            size="sm"
            :error-condition="currentWorkspace?.iconDownloadError"
            :loading-condition="currentWorkspace?.iconUploading"
          />
        </template>
        <template #suffix>
          <div class="flex w-max items-center gap-1 text-text">
            <div class="grow-0 text-nowrap text-text-subtle">
              {{ dropdownTriggerText }}
            </div>
            <div class="flex min-h-5 items-center justify-center text-icon-subtlest">
              <IconSprite icon="chevron-select" />
            </div>
          </div>
        </template>
      </ListMenuItem>
    </template>
    <template #dropdown="{ close }">
      <ListMenu
        :items="LIST_ITEMS.map((data) => ({ id: data.role, data }))"
        :initial-active-item="LIST_ITEMS.findIndex((item) => item.role === defaultRole)"
      >
        <template #item="{ item, active, key, setActiveItem }">
          <ListMenuItem
            :active="active"
            :aria-selected="active"
            @mousemove="setActiveItem(key)"
            @select="
              () => {
                $emit('update:projectDefaultRole', item.data.role)
                close()
              }
            "
          >
            <div class="flex items-start">
              <div class="flex size-5 min-w-5 items-center justify-center">
                <IconSprite
                  v-if="defaultRole === item.data.role"
                  class="text-icon-subtle"
                  icon="check"
                />
              </div>
              <div class="px-1 py-0.5">
                <div>{{ item.data.title }}</div>
                <div class="text-text-subtle">{{ item.data.description }}</div>
              </div>
            </div>
          </ListMenuItem>
        </template>
      </ListMenu>
    </template>
  </SelectDropdown>
</template>
