import { addEntity as backendAddEntity } from '@/backend/addEntity'
import { addProperty as backendAddProperty } from '@/backend/addProperty'
import { listPlans } from '@/backend/listPlans'
import { recalculateEntitites as backendRecalculateEntities } from '@/backend/recalculateEntitites'
import { recalculateEntity as backendRecalculateEntity } from '@/backend/recalculateEntity'
import type { APIResult } from '@/backend/types'
import { ANALYTICS_EVENT, useAnalytics } from '@/sharedComposables/useAnalytics'
import { useLocalStorage } from '@vueuse/core'
import { useWorkspaces } from '../Workspaces/useWorkspaces'
import { usePaywallStore } from './paywall'
import { serializePlan, useBilling } from './useBilling'

const LIMIT_EXCEEDED_CODE = 'billing_limits_exceeded'

/**
 * Some objects have usage that is limited by the workspace's active plan. This
 * composable provides wrappers around the backend functions that create these
 * objects, so that we can show a paywall dialog if the user tries to perform an
 * action that would exceed their plan's limits.
 */
export const useLimitedAction = () => {
  const timesAdded = useLocalStorage('times-added-prop', 0)
  const paywallStore = usePaywallStore()
  const billingStore = useBilling()
  const workspacesStore = useWorkspaces()
  const { captureAnalyticsEvent } = useAnalytics()

  /**
   * Middleware to handle 402 errors from the backend, which indicate that the
   * user's plan limits have been exceeded. If this happens, we open the paywall
   * dialog and update the active plan in the billing store.
   */
  const handleApiResponse = async (response: APIResult<unknown>) => {
    if (!response.ok && response.error.code === LIMIT_EXCEEDED_CODE) {
      paywallStore.dialogIsOpen = true

      if (workspacesStore.currentWorkspace) {
        // Refresh the active plan, as other users may have increased the limit usage
        // and we want to make sure that the current user sees the most up-to-date
        // usage state.
        const listPlansResponse = await listPlans(workspacesStore.currentWorkspace.id)
        if (listPlansResponse.ok) {
          billingStore.activePlan = serializePlan(listPlansResponse.data.effective_plan)
        }
      }
    }
  }

  const addEntity: typeof backendAddEntity = async (...args) => {
    const response = await backendAddEntity(...args)
    await handleApiResponse(response)
    if (response.ok) {
      timesAdded.value++
    }
    if (timesAdded.value === 5) {
      captureAnalyticsEvent(ANALYTICS_EVENT.FIVE_ENTITIES_CREATED)
    }
    return response
  }

  const addProperty: typeof backendAddProperty = async (...args) => {
    const response = await backendAddProperty(...args)
    await handleApiResponse(response)
    return response
  }

  const recalculateEntities: typeof backendRecalculateEntities = async (...args) => {
    const response = await backendRecalculateEntities(...args)
    await handleApiResponse(response)
    return response
  }

  const recalculateEntity: typeof backendRecalculateEntity = async (...args) => {
    const response = await backendRecalculateEntity(...args)
    await handleApiResponse(response)
    return response
  }

  return {
    /**
     * Provides a wrapper around the backend's addEntity function that shows a
     * paywall dialog if the user's plan limits have been exceeded.
     */
    addEntity,
    /**
     * Provides a wrapper around the backend's addProperty function that shows a
     * paywall dialog if the user's plan limits have been exceeded.
     */
    addProperty,
    /**
     * Provides a wrapper around the backend's recalculateEntities function
     * that shows a paywall dialog if the user's plan limits have been exceeded.
     */
    recalculateEntities,
    /**
     * Provides a wrapper around the backend's recalculateEntity function that
     * shows a paywall dialog if the user's plan limits have been exceeded.
     */
    recalculateEntity,
  }
}
