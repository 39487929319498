<script setup lang="ts">
import ModalDialog from '@/uiKit/ModalDialog.vue'
import IconButton from '@/uiKit/IconButton.vue'
import DarwinButton from '@/uiKit/DarwinButton.vue'
import ObjectURLImage from '@/modules/Projects/ObjectURLImage.vue'
import { useProjects } from '@/modules/Projects/useProjects'
import { computed, ref } from 'vue'
import PermissionsDialogInviteForm from './PermissionsDialogInviteForm.vue'
import { PERMISSIONS_DIALOG_ID } from './consts'
import {
  useWorkspaceMembers,
  type Invitation,
  type WorkspaceMember,
} from '@/modules/WorkspaceSettings/useWorkspaceMembers'
import type { ProjectMemberRole } from './types'
import { useProjectPermissionsStore } from './projectPermissionsStore'
import ProjectDefaultPermission, { type ProjectDefaultRole } from './ProjectDefaultPermission.vue'
import PermissionsDialogProjectMember from './PermissionsDialogProjectMember.vue'
import type { ResourceRole } from '@/backend/types'
import { useUser } from '@/modules/IdentityAndAccess/useUser'
import { toast } from '@/shared/toast'
import SeatLimitBanner from './SeatLimitBanner.vue'
import { useBilling } from '@/modules/Billing/useBilling'
import IconSprite from '@/uiKit/IconSprite.vue'
import { usePermissionsStore } from '@/modules/IdentityAndAccess/permissionsStore'
import { useAllowedRoles } from '@/modules/IdentityAndAccess/useAllowedRoles'

const props = defineProps<{
  workspaceId: string
  open: boolean
  rootProjectId: string
}>()

defineEmits<{
  (e: 'close'): void
}>()

/** For a11y - HTML ID for the dialog's label */
const DIALOG_LABEL_ID = 'permissions-dialog-label'

const projectsStore = useProjects()
const rootProject = computed(() => projectsStore.projects.find((p) => p.id === props.rootProjectId))
const coverImageUrl = computed(() => rootProject.value?.coverImageUrls.high)

const urlStart = computed(() => `${window.location.host}/${props.workspaceId}/projects/`)
const urlEnd = computed(() => `/${props.rootProjectId}`)

/**
 * Text to render on the copy link button. The text changes to 'Copied' for a short
 * time after the link is copied to the clipboard.
 */
const copyButtonText = ref<'Copy link' | 'Copied'>('Copy link')

/** Copy a link to the current project to the clipboard */
const onCopyLink = ({ showToast }: { showToast: boolean }) => {
  const url = `${window.location.protocol}//${urlStart.value}${props.rootProjectId}`
  navigator.clipboard.writeText(url)

  if (showToast) {
    toast.success('Link copied to clipboard')
  }

  copyButtonText.value = 'Copied'
  setTimeout(() => {
    copyButtonText.value = 'Copy link'
  }, 2000)
}

const workspaceMembersStore = useWorkspaceMembers()

const projectPermissionsStore = useProjectPermissionsStore()
const onInviteUsers = async ({
  existingUsers,
  role,
  newUserEmails,
}: {
  existingUsers: WorkspaceMember[]
  role: ProjectMemberRole
  newUserEmails: string[]
}) => {
  try {
    const [, projectInvitations] = await Promise.all([
      await Promise.all(
        existingUsers.map((user) =>
          projectPermissionsStore.setProjectRole({
            projectId: props.rootProjectId,
            role,
            userId: user.id,
            workspaceId: props.workspaceId,
          }),
        ),
      ),
      projectPermissionsStore.sendProjectInvitations({
        projectId: props.rootProjectId,
        workspaceId: props.workspaceId,
        emails: newUserEmails,
        role,
      }),
    ])

    if (projectInvitations.length > 0) {
      // If we've invited new users, then add the invitations to the
      // list of workspace invitations
      const newWorkspaceInvitations: Invitation[] = projectInvitations.map((invite) => ({
        id: 'id' in invite ? invite.id : undefined,
        email: invite.email,
        role: 'editor',
        status: 'pending',
        workspaceId: props.workspaceId,
        inviteUrl: 'invite_url' in invite ? invite.invite_url : undefined,
      }))

      workspaceMembersStore.invitations = [
        ...workspaceMembersStore.invitations,
        ...newWorkspaceInvitations,
      ]
    }
  } catch {
    toast.error('Failed to invite users')
    throw new Error('Failed to invite users')
  }
}

const userStore = useUser()
type ProjectMember = WorkspaceMember & {
  projectRole: ResourceRole
  isCurrentUser: boolean
  type: 'workspaceMember'
}
const projectMembers = computed<ProjectMember[]>(() =>
  Object.entries(projectPermissionsStore.workspaceMemberRoles)
    .map(([userId, role]) => {
      const workspaceMember = workspaceMembersStore.workspaceMembers.find((m) => m.id === userId)
      // Ideally, all project members would also be workspace members. But there is a BE bug where
      // project members aren't deleted when a workspace member is deleted, so we could get
      // orphaned project members. This check is to handle that case - we filter them out
      // on the FE.
      // see https://vseven.slack.com/archives/C06UK9537B8/p1725526371109919
      if (!workspaceMember) {
        return null
      }

      return {
        ...workspaceMember,
        projectRole: role,
        isCurrentUser: userId === userStore.user?.id,
        type: 'workspaceMember' as const,
      }
    })
    .filter((member): member is ProjectMember => !!member?.projectRole),
)

const workspaceMembers = computed(() =>
  workspaceMembersStore.workspaceMembers.map((member) => ({
    member,
    projectRole: projectPermissionsStore.workspaceMemberRoles[member.id],
  })),
)

const onChangeRole = (userId: string, role: ResourceRole | null) => {
  if (role === null || role === 'editor' || role === 'reader') {
    try {
      projectPermissionsStore.setProjectRole({
        projectId: props.rootProjectId,
        userId,
        workspaceId: props.workspaceId,
        role,
      })
    } catch {
      toast.error('Failed to update role')
      throw new Error('Failed to update role')
    }
  }
}

const onDeleteInvitation = async (invitationId: string) => {
  await projectPermissionsStore.deleteProjectInvitation({
    invitationId,
    projectId: props.rootProjectId,
    workspaceId: props.workspaceId,
  })
  workspaceMembersStore.invitations = workspaceMembersStore.invitations.filter(
    (i) => i.id !== invitationId,
  )
}

const anyOneInWorkspaceProjectPermission = computed(
  () => projectPermissionsStore.defaultRole ?? 'noaccess',
)

const updateProjectDefaultRole = (role: ProjectDefaultRole) => {
  projectPermissionsStore.setProjectRole({
    userId: 'anyone_in_workspace',
    role: role === 'noaccess' ? null : role,
    workspaceId: props.workspaceId,
    projectId: props.rootProjectId,
  })
}

const inviteFormComponentRef = ref<InstanceType<typeof PermissionsDialogInviteForm> | null>(null)

const billingStore = useBilling()

const limitReached = computed(
  () =>
    (billingStore.seatUsage?.limitUsage ?? 0) +
      workspaceMembersStore.invitations.length +
      (inviteFormComponentRef.value?.newUserEmails.length ?? 0) >=
    (billingStore.seatUsage?.limitValue ?? 0),
)

const permissionsStore = usePermissionsStore()
const allowedInviteRoles = useAllowedRoles({
  permissionName: 'invite_members',
  scope: 'project',
  rolesSubset: ['reader', 'editor'],
})
const allowedUpdateRoles = useAllowedRoles({
  permissionName: 'update_members',
  rolesSubset: ['reader', 'editor'],
  scope: 'project',
})
</script>

<template>
  <ModalDialog
    :id="PERMISSIONS_DIALOG_ID"
    :open="open"
    placement="right"
    class="overflow-hidden"
    :outline="false"
    :aria-labelledby="DIALOG_LABEL_ID"
    @close="$emit('close')"
  >
    <div class="h-full">
      <div
        class="relative flex h-full w-[400px] flex-col overflow-hidden rounded-corner-10 border-0 bg-surface-tertiary-persist"
      >
        <IconButton
          size="lg"
          variant="transparent"
          class="absolute right-2 top-2 z-1 text-text-subtle"
          icon="close"
          @click="$emit('close')"
        />
        <div class="relative flex items-stretch justify-start gap-4 p-4">
          <div
            class="size-20 min-h-20 min-w-20 rounded-corner-10 bg-gradient-to-b from-surface-primary-persist via-surface-primary-persist to-background-gray-sunken"
          >
            <div
              v-if="rootProject?.coverImageDownloadError"
              class="flex size-full items-center justify-center rounded-corner-10 bg-background-gray-subtle text-text-subtle"
            >
              <IconSprite
                icon="file"
                size="xxxl"
              />
            </div>
            <ObjectURLImage
              v-else-if="coverImageUrl"
              class="size-full rounded-corner-10 object-cover"
              :url="coverImageUrl"
              :loading="false"
            />
          </div>
          <div class="flex min-w-0 flex-col items-start justify-between">
            <div class="max-w-full">
              <p
                :id="DIALOG_LABEL_ID"
                class="text-md-13px-default text-text"
              >
                Share this project
              </p>
              <div
                class="flex max-w-full cursor-pointer truncate text-xs-11px-light text-text-subtlest"
                @click="onCopyLink({ showToast: true })"
              >
                <div class="shrink truncate">{{ urlStart }}</div>
                <div class="grow">{{ urlEnd }}</div>
              </div>
            </div>
            <DarwinButton
              rounded
              variant="outline"
              class="w-[88px] max-w-[88px]"
              size="md"
              @click="onCopyLink({ showToast: false })"
              >{{ copyButtonText }}</DarwinButton
            >
          </div>
        </div>
        <div
          class="go-scrollbar flex min-h-0 grow flex-col gap-4 overflow-y-auto rounded-lg bg-surface-primary p-4"
          data-test="permissions-dialog-scroll-container"
        >
          <PermissionsDialogInviteForm
            v-if="permissionsStore.currentProjectPermissions.invite_members"
            ref="inviteFormComponentRef"
            :workspace-members="workspaceMembers"
            :pending-invite-emails="
              projectPermissionsStore.projectInvitations.map((invite) => invite.email)
            "
            :limit-reached="limitReached"
            :allowed-roles="allowedInviteRoles"
            @invite="onInviteUsers"
          />
          <ProjectDefaultPermission
            v-if="permissionsStore.currentProjectPermissions.update_members"
            :default-role="anyOneInWorkspaceProjectPermission as ProjectDefaultRole"
            @update:project-default-role="updateProjectDefaultRole"
          />
          <div class="grow">
            <ul>
              <PermissionsDialogProjectMember
                v-for="member in projectMembers.toSorted((a, b) =>
                  a.projectRole === 'owner' ? -1 : 1,
                )"
                :key="member.id"
                :member="member"
                :is-current-user="member.isCurrentUser"
                :role="member.projectRole"
                :allowed-roles="allowedUpdateRoles"
                @change:role="onChangeRole(member.id, $event)"
              />
              <PermissionsDialogProjectMember
                v-for="invitation in projectPermissionsStore.projectInvitations"
                :key="invitation.id"
                :member="{ ...invitation, type: 'invitation' }"
                :is-current-user="false"
                :role="invitation.role"
                :allowed-roles="allowedUpdateRoles"
                @delete:invitation="onDeleteInvitation"
              />
            </ul>
          </div>
          <SeatLimitBanner v-if="limitReached" />
        </div>
      </div>
    </div>
  </ModalDialog>
</template>
