import type { ResourceRole } from '@/backend/types'

/**
 * Maps each backend resource role to the human-friendly label that we
 * display in the UI.
 */
export const roleLabelMap: Record<ResourceRole, string> = {
  admin: 'Admin',
  worker: 'Worker',
  editor: 'User',
  owner: 'Owner',
  reader: 'Viewer',
}

// The order of roles on the UI. Roles are displayed in this order on the Settings pages
export const roleOrder: ResourceRole[] = ['owner', 'admin', 'editor', 'worker']
