import { acceptHMRUpdate, defineStore } from 'pinia'
import { computed, ref } from 'vue'

export type CellLocation = { rowIndex: number; colIndex: number }
export type TableRange = { start: CellLocation; end: CellLocation }

/**
 * Tiny generic pinia store used to hold and set focused and selected cells
 *
 * Can be used in any kind of table component, with the caveat that we need to reset the
 * state when a new one is mounted.
 *
 * The assumption is that there is always just one active table on the page.
 *
 * If that requirement changes, when we need to redo this as a composable which
 * is then passed into multiple shared composables, or a nested set of states tied to ids, etc.
 */
export const useTable = defineStore('table', () => {
  /**
   * The range of cells that the user has selected, either
   * by dragging the cursor over them, or by pressing
   * shift + arrow keys.
   */
  const selectedRange = ref<TableRange | null>(null)
  const hasSelectedMultipleCells = computed(() => {
    if (!selectedRange.value) {
      return false
    }

    const hasMultipleCols = selectedRange.value.end.colIndex > selectedRange.value.start.colIndex
    const hasMultipleRows = selectedRange.value.end.rowIndex > selectedRange.value.start.rowIndex

    return hasMultipleCols || hasMultipleRows
  })

  /**
   * The cell that the user has clicked on. This is the cell
   * that will be edited if the user starts typing.
   */
  const selectedCell = ref<CellLocation | null>(null)

  /**
   * Select a single cell. This replaces the selected range
   * with a 1x1 range containing only this cell
   */
  const selectCell = (rowIndex: number, colIndex: number) => {
    if (isCellSelected({ rowIndex, colIndex })) {
      focusCell(rowIndex, colIndex)
    } else {
      focusedCell.value = null
      selectedCell.value = { rowIndex, colIndex }
      selectedRange.value = { start: { rowIndex, colIndex }, end: { rowIndex, colIndex } }
    }
  }

  /** Returns true if and only if a cell is contained within the selected range */
  const isCellInSelectedRange = (cell: CellLocation): boolean => {
    if (!selectedRange.value) {
      return false
    }

    return (
      cell.colIndex >= selectedRange.value.start.colIndex &&
      cell.colIndex <= selectedRange.value.end.colIndex &&
      cell.rowIndex >= selectedRange.value.start.rowIndex &&
      cell.rowIndex <= selectedRange.value.end.rowIndex
    )
  }

  /** Returns true if and only if a cell is selected */
  const isCellSelected = ({
    colIndex,
    rowIndex,
  }: {
    rowIndex: number
    colIndex: number
  }): boolean =>
    selectedCell.value?.rowIndex === rowIndex && selectedCell.value.colIndex === colIndex

  /**
   * The cell which is being edited by the user. The plan is for this
   * to eventually be the cell that has browser focus, and for edit state
   * to be held separately. For this to happen we need 2 things:
   * 1. An 'edit mode' boolean that will indicate that the focused
   * cell is in edit mode
   * 2. The ability to select ranges (currently our 'range' state will
   * only ever store 1 cell)
   */
  const focusedCell = ref<{ rowIndex: number; colIndex: number } | null>(null)

  const focusCell = (rowIndex: number, colIndex: number) => {
    focusedCell.value = { rowIndex, colIndex }
  }

  const isCellFocused = (rowIndex: number, colIndex: number): boolean =>
    focusedCell.value?.rowIndex === rowIndex && focusedCell.value.colIndex === colIndex

  const clearFocusedAndSelected = () => {
    focusedCell.value = null
    selectedRange.value = null
    selectedCell.value = null
  }

  return {
    // Selected range of cells
    selectedRange,
    isCellInSelectedRange,
    hasSelectedMultipleCells,

    // Selected cell
    selectedCell,
    selectCell,
    isCellSelected,

    // The focused cell
    focusedCell,
    focusCell,
    isCellFocused,

    clearFocusedAndSelected,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useTable, import.meta.hot))
}
