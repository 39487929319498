import type { Permission, ResourceRole } from '@/backend/types'
import { computed, type ComputedRef } from 'vue'
import { usePermissionsStore } from './permissionsStore'

type PermissionName = Extract<
  Permission,
  'invite_members' | 'update_members' | 'remove_members' | 'add_members'
>

/**
 * invite/add/remove/update member permissions are complicated. They can be either
 * granted (true), denied (false), or granted conditionally based on the what role
 * the action applies to. This composable takes a permission name, scope (workspace/project)
 * and a list of roles, and replies a subset of roles that the user is able to
 * perform the action on.
 */
export const useAllowedRoles = <T extends ResourceRole>({
  permissionName,
  rolesSubset,
  scope,
}: {
  permissionName: PermissionName
  scope: 'project' | 'workspace'
  rolesSubset: T[]
}): ComputedRef<T[]> => {
  const permissionsStore = usePermissionsStore()

  return computed(() => {
    const permissions =
      scope === 'project'
        ? permissionsStore.currentProjectPermissions
        : permissionsStore.workspacePermissions

    const permission = permissions[permissionName]

    if (!permission) {
      return []
    }

    if (permission === true) {
      return rolesSubset
    }

    return rolesSubset.filter((role) => permission.any_of.includes(role))
  })
}
