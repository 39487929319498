import { listProjects } from '@/backend/listProjects'
import { useDataLoader } from '@/sharedComposables/useDataLoader'

export const LIMIT_RECENT_PROJECTS = 15

export const recentProjectsLoader = useDataLoader((workspaceId: string) =>
  listProjects(workspaceId, {
    first: LIMIT_RECENT_PROJECTS,
    order_by: ['updated_at'],
    order_directions: ['desc'],
    only_parents: true,
  }),
)
