<script lang="ts">
export const removeCategoryTag = (name: string) => {
  return name.replace(/\s*\[.*?\]/g, '')
}

export const getCategoryTag = (name: string) => {
  const match = name.match(/\[(.*?)\]/)
  return match ? match[1] : null
}
</script>

<script setup lang="ts">
import type { ListTemplatesResponse } from '@/backend/listTemplates'
import ObjectURLImage from '@/modules/Projects/ObjectURLImage.vue'
import DarwinButton from '@/uiKit/DarwinButton.vue'
import { useCloneProject } from '@/modules/Projects/useCloneProject'
import { FeatureFlag, useFeatureFlags } from '../App/useFeatureFlags'

const props = defineProps<{
  template: ListTemplatesResponse['data'][number]
  workspaceId: string
}>()

const emit = defineEmits<{
  (e: 'close' | 'is-cloning'): void
}>()

const isNewTemplateModalEnabled = useFeatureFlags(FeatureFlag.NEW_TEMPLATE_MODAL) || false

const { cloneProject } = useCloneProject()
const onCloneProject = async (templateId: string) => {
  emit('is-cloning')
  await cloneProject(templateId, props.workspaceId, 'template')
  emit('close')
}
</script>

<template>
  <button
    v-if="isNewTemplateModalEnabled"
    class="group/template relative flex h-[320px] cursor-pointer flex-col overflow-hidden rounded-corner-8 bg-surface-secondary-persist p-3 text-start transition-all duration-300 ease-in-out hover:bg-background-gray-subtlest"
    data-test="template-card"
    @click="onCloneProject(template.id)"
  >
    <p class="text-xs-11px-light text-text-subtlest">{{ getCategoryTag(template.name || '') }}</p>
    <div class="mb-4 flex w-full grow items-center justify-center">
      <div
        class="h-[194px] w-[140px] overflow-hidden rounded-corner-8 shadow-sm transition-all duration-300 ease-in-out group-hover/template:blur-lg"
      >
        <ObjectURLImage
          class="h-[194px] w-[140px] object-cover transition-all duration-300"
          :url="template.cover_image_urls.high"
          :loading="false"
        />
      </div>
      <div
        class="absolute z-10 mx-auto hidden group-hover/template:block"
        data-theme="dark"
      >
        <DarwinButton
          variant="neutral"
          :rounded="true"
          size="md"
          tabindex="-1"
          role="presentation"
          >Open</DarwinButton
        >
      </div>
    </div>
    <div
      class="pointer-events-none absolute bottom-0 max-h-[32px] flex-col pb-3 transition-all duration-500 ease-in-out group-hover/template:max-h-[80px]"
    >
      <h3 class="line-clamp-1 pr-2 text-md-13px-light text-text">
        {{ removeCategoryTag(template.name || '') }}
      </h3>
      <p
        class="line-clamp-1 text-xs-11px-light text-text-subtlest opacity-0 transition-opacity duration-500 group-hover/template:opacity-100"
      >
        {{ template.properties.length }}
        {{ template.properties.length === 1 ? 'property' : 'properties' }}
      </p>
    </div>
  </button>

  <!-- OLD -->
  <button
    v-else
    class="group/template relative flex h-[200px] cursor-pointer flex-col overflow-hidden rounded-corner-8 bg-surface-secondary-persist text-start"
    data-test="template-card"
    @click="onCloneProject(template.id)"
  >
    <div class="h-[136px] overflow-hidden">
      <ObjectURLImage
        class="w-full object-cover transition-all duration-300 group-hover/template:scale-110"
        :url="template.cover_image_urls.high"
        :loading="false"
      />
    </div>
    <div
      class="flex w-full items-center justify-between rounded-b-corner-8 border border-border-subtle group-hover/template:bg-background-gray-subtlest"
    >
      <div class="pointer-events-none flex h-16 w-full flex-col p-4 group-hover/template:w-1/2">
        <h3 class="line-clamp-1 text-sm-12px-bold text-text">
          {{ removeCategoryTag(template.name || '') }}
        </h3>
        <p class="line-clamp-1 text-xs-11px-light text-text-subtlest">
          {{ template.properties.length }}
          {{ template.properties.length === 1 ? 'property' : 'properties' }}
        </p>
      </div>
      <div class="hidden w-1/2 group-hover/template:block">
        <DarwinButton
          variant="neutral"
          size="md"
          tabindex="-1"
          role="presentation"
          aria-label="Use template"
          >Use template</DarwinButton
        >
      </div>
    </div>
  </button>
</template>
