<script setup lang="ts">
import { onKeyStroke } from '@vueuse/core'
import { computed, onMounted, onUnmounted, ref, watch } from 'vue'
import { GridItem, GridLayout } from 'vue3-grid-layout-next'

import { LDEvents, useABMetrics } from '@/modules/App/useABMetrics'
import { ANALYTICS_EVENT, useAnalytics } from '@/sharedComposables/useAnalytics'
import ConfirmationDialog from '@/uiKit/ConfirmationDialog.vue'

import { usePermissionsStore } from '../IdentityAndAccess/permissionsStore'
import EntityViewCard from './EntityViewCard.vue'
import EntityViewHeader from './EntityViewHeader.vue'
import FilterBar from './Filters/FilterBar.vue'
import GroundingModal from './GroundingModal.vue'
import { useLoadProjectPermissions } from './Permissions/useLoadProjectPermissions'
import ProjectTableViewsTabstrip from './ProjectTableViewsTabstrip.vue'
import ProjectTobBar from './ProjectTopBar.vue'
import PropertySidebar from './PropertySidebar.vue'
import { useEntity } from './useEntity'
import { useEntityView } from './useEntityView'
import { usePrevOrNextEntity } from './usePrevOrNextEntity'
import { useProject } from './useProject'
import { useProjectChannel } from './useProjectChannel'
import { useProjectChannelHandlers } from './useProjectChannelHandlers'
import { useProjectSync } from './useProjectSync'
import { useProperty } from './useProperty'
import { usePropertySidebarIntegration } from './usePropertySidebarIntegration'

const props = defineProps<{
  entityId: string
  projectId: string
  workspaceId: string
  viewId?: string
}>()

useProjectSync(props)

const entityStore = useEntity()
const projectStore = useProject()
const { layoutItems, updateItemSize: _updateItemSize } = useEntityView()
function updateItemSize(id: string | number) {
  _updateItemSize({
    propertyId: id,
    workspaceId: props.workspaceId,
    projectId: props.projectId,
  })
}

const hasLoaded = computed(() => projectStore.projectLoaded && entityLoaded.value)

// The checked state of the lock/unlock layout switch
const lockValue = ref(false)

/**
 * Layout changes are persisted for all users, so a user can only edit the layout
 * if they have permission to edit the project.
 */
const permissionsStore = usePermissionsStore()
const canEditLayout = computed(
  () => !!permissionsStore.currentProjectPermissions.manage_views && !lockValue.value,
)

const projectChannelHandlers = useProjectChannelHandlers()
useProjectChannel(props.projectId, projectChannelHandlers)

const {
  previousEntityId,
  nextEntityId,
  loadAndSetEntity,
  entityLoaded,
  navigateToPrevEntity,
  navigateToNextEntity,
} = usePrevOrNextEntity()

useLoadProjectPermissions({
  workspaceId: computed(() => props.workspaceId),
  rootProjectId: computed(() => props.projectId),
})

watch(
  () => [props.entityId, entityStore.areEntitiesStale, projectStore.projectLoaded] as const,
  async () => loadAndSetEntity(props.entityId),
  { immediate: true },
)

onUnmounted(() => {
  entityStore.resetEntities()
  entityStore.setEntityId()
})

onKeyStroke(['ArrowDown', 'ArrowUp'], (e: KeyboardEvent) => {
  // if the user is typing in an input, don't handle these keys
  if (
    e.target instanceof Element &&
    ['input', 'textarea'].includes(e.target?.tagName.toLowerCase())
  )
    return
  e.preventDefault()

  if (['ArrowUp'].includes(e.key)) {
    navigateToPrevEntity()
    return
  }
  if (['ArrowDown'].includes(e.key)) {
    navigateToNextEntity()
    return
  }
})

const {
  isPropertyBusy,
  deselectProperty,
  saveProperty,
  hideProperty,
  deleteProperty,
  deletePropertyConfirmationOpen,
  reprocessColumn,
  reprocessColumnConfirmationOpen,
} = usePropertySidebarIntegration()

const propertyStore = useProperty()
watch(
  () => propertyStore.property,
  (property) => {
    if (!property) {
      propertyStore.sidebarIsOpen = false
    } else {
      // AB_PROPERTY_EDITOR EXPERIMENT - Metrics
      startTrackingTimeFor(LDEvents.TIME_SPENT_ON_EDITING)
    }
  },
)

const { captureAnalyticsEvent } = useAnalytics()

onMounted(() => {
  captureAnalyticsEvent(ANALYTICS_EVENT.OPEN_ENTITY_VIEW)
})

const hoveredItemId = ref<string>()

const handleMouseEnter = (id: string) => {
  hoveredItemId.value = id
}

const handleMouseLeave = () => {
  hoveredItemId.value = undefined
}

const { startTrackingTimeFor, trackElapsedTimeEvent } = useABMetrics()
</script>
<!-- for reference - in main.css there is style overwriting for the placeholder -->

<template>
  <div class="grid size-full grid-cols-1 grid-rows-[max-content_1fr] bg-surface-secondary pt-0">
    <ProjectTobBar />

    <div class="flex h-full flex-1 flex-col overflow-y-auto border-t border-border-subtle">
      <EntityViewHeader
        v-model:is-layout-locked="lockValue"
        :project-id="projectId"
        :has-previous="Boolean(previousEntityId)"
        :has-next="Boolean(nextEntityId)"
        :view-id="props.viewId"
        @prev="navigateToPrevEntity"
        @next="navigateToNextEntity"
      />
      <div
        v-if="hasLoaded"
        class="grid h-full grid-cols-1 grid-rows-1 overflow-auto"
      >
        <GridLayout
          :layout="layoutItems"
          class="col-start-1 col-end-1 row-start-1 row-end-1 -mt-1"
          data-test="entity-grid-layout"
          :col-num="12"
          :row-height="30"
          :is-draggable="canEditLayout"
          :is-resizable="canEditLayout"
          :responsive="false"
          :vertical-compact="true"
          :use-css-transforms="true"
          :margin="[8, 8]"
        >
          <GridItem
            v-for="item in layoutItems"
            :key="item.i"
            class="touch-none"
            data-test="entity-grid-item"
            drag-ignore-from="[data-test='entity-card-content']"
            :x="item.x"
            :y="item.y"
            :w="item.w"
            :h="item.h"
            :i="item.i"
            :min-h="3"
            :min-w="2"
            @resized="updateItemSize"
            @moved="updateItemSize"
            @mouseenter="handleMouseEnter(item.i)"
            @mouseleave="handleMouseLeave"
          >
            <EntityViewCard
              :workspace-id="workspaceId"
              :project-id="projectId"
              :item="item"
              :is-layout-locked="!canEditLayout"
              :is-hovered="hoveredItemId === item.i"
            />
          </GridItem>
        </GridLayout>
        <div
          v-if="propertyStore.sidebarIsOpen"
          class="z-0 col-start-1 col-end-1 row-start-1 row-end-1 h-full justify-self-end pb-2.5 [&>:first-child]:shadow-lg"
        >
          <PropertySidebar
            :workspace-id="workspaceId"
            :project-id="projectId"
            :disabled="isPropertyBusy"
            @close="deselectProperty"
            @hide="hideProperty"
            @delete="deletePropertyConfirmationOpen = true"
            @update="
              (property) => {
                saveProperty(property, 'sidebar')

                // AB_PROPERTY_EDITOR EXPERIMENT - Metrics
                trackElapsedTimeEvent('property_sidebar_update')
              }
            "
            @reprocess="reprocessColumnConfirmationOpen = true"
          />
        </div>
      </div>
      <div v-else>Loading...</div>
    </div>

    <div class="flex flex-col">
      <FilterBar />
      <ProjectTableViewsTabstrip />
    </div>

    <ConfirmationDialog
      id="delete-property-confirmation"
      :open="deletePropertyConfirmationOpen"
      title="Delete this property?"
      description="This property will be deleted immediately. You can't undo this action."
      @confirm="deleteProperty"
      @close="deletePropertyConfirmationOpen = false"
    />

    <ConfirmationDialog
      id="reprocess-property-confirmation"
      :open="reprocessColumnConfirmationOpen"
      title="Recompute all stale fields for this property"
      description="This may take a while and become quite costly."
      variant="black"
      confirm-text="Confirm"
      @confirm="reprocessColumn"
      @close="reprocessColumnConfirmationOpen = false"
    />
    <GroundingModal />
  </div>
</template>
