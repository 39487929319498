import { remove } from './client'
import type { BackendMeta } from './types'

export type RemoveProjectInvitation = BackendMeta<
  '/api/workspaces/{workspace_id}/projects/{project_id}/invitations/{invitation_id}',
  'delete'
>

export type RemoveProjectInvitationParams = {
  workspaceId: RemoveProjectInvitation['pathParams']['workspace_id']
  projectId: RemoveProjectInvitation['pathParams']['project_id']
  invitationId: RemoveProjectInvitation['pathParams']['invitation_id']
}

export const removeProjectInvitation = async ({
  invitationId,
  projectId,
  workspaceId,
}: RemoveProjectInvitationParams) =>
  remove<undefined, RemoveProjectInvitation['successResponse'], RemoveProjectInvitation['path']>(
    `/api/workspaces/${workspaceId}/projects/${projectId}/invitations/${invitationId}`,
    undefined,
  )
