import type { PropertyLayout } from '@/modules/Project/useProject'
import { put } from './client'
import type { BackendMeta } from './types'

export type UpdateViewMeta = BackendMeta<
  '/api/workspaces/{workspace_id}/projects/{project_id}/views/{view_id}',
  'put'
>

export type UpdateViewPath = UpdateViewMeta['path']
export type UpdateViewResponse = UpdateViewMeta['successResponse']
export type UpdateViewRequest = UpdateViewMeta['body']
export type UpdateViewParams = {
  workspaceId: UpdateViewMeta['pathParams']['workspace_id']
  projectId: UpdateViewMeta['pathParams']['project_id']
  viewId: UpdateViewMeta['pathParams']['view_id']
  name: UpdateViewRequest['name']
  filters: UpdateViewRequest['filters']
  propertyIds: UpdateViewRequest['property_ids']
  propertyLayouts: PropertyLayout[]
  propertyOptions: UpdateViewRequest['property_options']
  assignablePropertyId: UpdateViewRequest['assignable_property_id']
  numPinnedProperties?: UpdateViewRequest['num_pinned_properties']
}

export const updateView = ({
  workspaceId,
  projectId,
  viewId,
  name,
  filters,
  propertyIds,
  propertyLayouts,
  propertyOptions,
  assignablePropertyId,
  numPinnedProperties: numPinnedProperties,
}: UpdateViewParams) =>
  put<UpdateViewRequest, UpdateViewResponse, UpdateViewPath>(
    `/api/workspaces/${workspaceId}/projects/${projectId}/views/${viewId}`,
    {
      name,
      filters,
      property_ids: propertyIds,
      property_layouts: propertyLayouts.map(({ propertyId, ...rest }) => ({
        property_id: propertyId,
        ...rest,
      })),
      property_options: propertyOptions,
      assignable_property_id: assignablePropertyId,
      num_pinned_properties: numPinnedProperties,
    },
  )
