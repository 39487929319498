<script setup lang="ts">
import { useMeterColor } from '@/shared/useMeterColor'
import { capitalize } from '@/shared/utils/string'
import IconSprite from '@/uiKit/IconSprite.vue'
import LinearProgress from '@/uiKit/LinearProgress.vue'
import ToolTip from '@/uiKit/ToolTip.vue'
import { toRef } from 'vue'

/**
 * This component is used across the app to show the usage of a feature towards its limit.
 */

type LimitType = 'soft' | 'hard'
const props = withDefaults(
  defineProps<{
    title: string
    titleTag?: 'h3' | 'h2' | 'h1' | 'div'
    currentUsage: number
    maxUsage: number
    /** ID to give to the root element - required for a11y */
    id: string
    type?: LimitType
  }>(),
  { titleTag: 'div', type: undefined },
)

const numberFormatter = new Intl.NumberFormat()

const meterColor = useMeterColor({
  current: toRef(props, 'currentUsage'),
  max: toRef(props, 'maxUsage'),
  defaultColor: 'blue',
})

const meterLabelId = `${props.id}-meter-label`

const typeTitle: Record<LimitType, string> = {
  soft: 'This limit is a soft limit',
  hard: 'This limit is a hard limit',
}

const typeDesc: Record<LimitType, string> = {
  soft: 'Soft limits are able to be exceeded.',
  hard: 'Hard limits are not able to be exceeded. To increase your limit, reach out to the V7 Go team.',
}
</script>

<template>
  <div :id="id">
    <component
      :is="titleTag"
      class="mb-0.5 text-sm-12px-default text-text"
      >{{ title }}</component
    >
    <div class="flex items-center justify-between">
      <p
        :id="meterLabelId"
        class="mb-2 text-sm-12px-light text-text-subtlest"
      >
        <span class="text-text">{{ numberFormatter.format(currentUsage) }}</span> /
        {{ numberFormatter.format(maxUsage) }} included
      </p>
      <!-- type indicator -->
      <ToolTip
        v-if="type"
        :title="typeTitle[type]"
        :body="typeDesc[type]"
        :placement="{ allowedPlacements: ['top-end'] }"
      >
        <div
          class="flex shrink-0 items-center truncate rounded-corner-4 bg-background-gray-subtlest px-0.5 text-xs-11px-default text-text-subtle"
        >
          {{ capitalize(type) }} limit
          <IconSprite
            icon="info-fill"
            size="xs"
            class="ml-1"
          />
        </div>
      </ToolTip>
    </div>
    <LinearProgress
      :aria-labelledby="meterLabelId"
      :min="0"
      :max="maxUsage"
      :value="currentUsage"
      :color="meterColor"
      role="meter"
    />
  </div>
</template>
