<script lang="ts">
declare global {
  interface Window {
    tolt?: {
      signup: (email: string) => void
    }
    tolt_referral?: string
  }
}

// We've had issues in the past with signup form,
// and creating an account from scratch to test the flow
// everytime is time-consuming.
const forceEnabled = ref(false)

export const forceEnableSignup = () => {
  forceEnabled.value = true
}
</script>

<script setup lang="ts">
import { getUser } from '@/backend/getUser'
import { listUsers } from '@/backend/listUsers'
import { postToHubspot, type DataPayload } from '@/backend/postToHubspot'
import { updateUser } from '@/backend/updateUser'
import { updateWorkspace } from '@/backend/updateWorkspace'
import V7Logo from '@/illustrations/v7-logo.svg'
import { useBilling } from '@/modules/Billing/useBilling'
import { serializeUser, useUser } from '@/modules/IdentityAndAccess/useUser'
import { serializeWorkspace, useWorkspaces } from '@/modules/Workspaces/useWorkspaces'
import { toast } from '@/shared/toast'
import DotSteps from '@/sharedComponents/DotSteps.vue'
import { ANALYTICS_EVENT, useAnalytics } from '@/sharedComposables/useAnalytics'
import { useDataLoader } from '@/sharedComposables/useDataLoader'
import { useForm } from '@/sharedComposables/useForm'
import { useUtmParams } from '@/sharedComposables/useUtmParams'
import BadgeItem from '@/uiKit/BadgeItem.vue'
import DarwinButton from '@/uiKit/DarwinButton.vue'
import IconButton from '@/uiKit/IconButton.vue'
import IconSprite from '@/uiKit/IconSprite.vue'
import InlineTextField from '@/uiKit/InlineTextField.vue'
import * as sentry from '@sentry/vue'
import { useEventListener } from '@vueuse/core'
import { UseFocusTrap } from '@vueuse/integrations/useFocusTrap/component.mjs'
import { format } from 'date-fns'
import { computed, onMounted, ref, watch, type BaseTransitionProps } from 'vue'
import { useRoute } from 'vue-router'
import { useWelcomeTour } from '../WelcomeTour/useWelcomeTour'
import {
  serializeWorkspaceMember,
  useWorkspaceMembers,
} from '../WorkspaceSettings/useWorkspaceMembers'
import CheckBox from '@/uiKit/CheckBox.vue'
import { usePermissionsStore } from '../IdentityAndAccess/permissionsStore'

/* Constants */
const SENTRY_HUBSPOT_UNEXPECTED_RESPONSE = 'SignUp Questionnaire to Hubspot failed - unknown reason'
const SENTRY_HUBSPOT_NETWORK_ERROR = 'SignUp Questionnaire to Hubspot failed - network error'

/* Stores and composables */
const workspaceMembersStore = useWorkspaceMembers()
const userStore = useUser()
const workspaceStore = useWorkspaces()
const billingStore = useBilling()
const { captureAnalyticsEvent } = useAnalytics()
const { getUtmParams } = useUtmParams()
const welcomeTour = useWelcomeTour()
const route = useRoute()

const usersLoader = useDataLoader(() => {
  if (!workspaceStore.currentWorkspace) {
    throw new Error('No current workspace')
  }
  return listUsers(workspaceStore.currentWorkspace.id)
})

/* Computeds and variables */
const hasLoadedWorkspaceMembers = computed(() => workspaceMembersStore.workspaceMembers.length > 0)
const localEnabled = computed(() => {
  return (
    isNotOnTheErrorPage.value &&
    userStore.user &&
    hasLoadedWorkspaceMembers.value &&
    !userStore.user?.signupCompleted
  )
})

const enabled = computed(() => forceEnabled.value || localEnabled.value)
const isNotOnTheErrorPage = computed(() => route.path !== '/error')

const step = ref(1)
const { values } = useForm({
  firstName: userStore.user?.firstName ?? '',
  lastName: userStore.user?.lastName ?? '',
  jobTitle: '',
  marketingOptin: { required: false, value: false },
})

const backDisabled = ref(false)

const topCenterSquare = ref<HTMLElement | null>(null)
const bottomCenterSquare = ref<HTMLElement | null>(null)
const topLeftSquare = ref<HTMLElement | null>(null)
const bottomLeftSquare = ref<HTMLElement | null>(null)
const topRightSquare = ref<HTMLElement | null>(null)
const bottomRightSquare = ref<HTMLElement | null>(null)

const formWrapper = ref<HTMLElement | null>(null)

/* Watchers */
onMounted(() => {
  formWrapper.value?.querySelector('input')?.focus()
})

watch(
  () => [workspaceStore.currentWorkspace?.id, userStore.user?.id] as const,
  async ([workspaceId, userId], [oldWorkspaceId, oldUserId]) => {
    if (!workspaceId || !userId || (oldWorkspaceId === workspaceId && oldUserId === userId)) return
    const response = await usersLoader.load()
    if (!response.ok) return

    workspaceMembersStore.setWorkspaceMembers(response.data.data.map(serializeWorkspaceMember))
    if (response.data.data.length <= 1) return
  },
)

watch(localEnabled, () => {
  if (localEnabled.value) {
    if (values.firstName && values.lastName) {
      step.value = 3
    }
  }
})
const permissionsStore = usePermissionsStore()

/* Handlers */
useEventListener(document, 'keydown', (e) => {
  if (e.key === 'Escape' && step.value > 1) {
    step.value -= 1
  }
})

const onSubmit = async (e: Event) => {
  e.preventDefault()
  if (step.value < 3) {
    step.value += 1
    return
  }

  await updateNamesAndMarkSignedUp()
  if (permissionsStore.hasWorkspaceUpdatePermission) {
    await updateWorkspaceName(`${values.firstName}'s Workspace`)
  }

  const { user } = userStore
  if (!user?.email) {
    return
  }

  if (!userStore.invitationProjectId) {
    welcomeTour.prepare()
  }

  forceEnabled.value = false

  captureAnalyticsEvent(ANALYTICS_EVENT.SUBMIT_SIGNUP_FORM, {
    jobTitle: values.jobTitle,
    marketingOptin: values.marketingOptin,
  })

  const utmParams = getUtmParams()
  if (window.dataLayer) {
    try {
      window.dataLayer.push({
        event: 'signupComplete',
        jobTitle: values.jobTitle,
        ...utmParams,
      })
    } catch (error) {
      sentry.captureException(error)
    }
  }

  try {
    const result = await postToHubspot(
      {
        email: user.email,
        firstname: values.firstName,
        lastname: values.lastName,
        jobtitle: values.jobTitle,
        go_signed_up_date: format(new Date(), 'yyyy-MM-dd') as DataPayload['go_signed_up_date'],
        go_workspace_id: workspaceStore.currentWorkspace?.id,
        go_user_id: user.id,
        pricing_plan_button_clicked: billingStore.activePlan?.name ?? 'free',
        ...(utmParams
          ? {
              utm_campaign: utmParams.utmCampaign,
              utm_content: utmParams.utmContent,
              utm_medium: utmParams.utmMedium,
              utm_source: utmParams.utmSource,
              utm_term: utmParams.utmTerm,
            }
          : {}),
      } as DataPayload,
      values.marketingOptin,
    )

    if (result.data.inlineMessage) {
      updateNamesAndMarkSignedUp()
      toast.success(result.data.inlineMessage)
    } else if (result.data.errors) {
      // errors to show to the user
      toast.error(result.data.errors.join('\n'))
    } else {
      // unexpected response - user gets in the app, unexpected Hubspot errors are not relevant for the user, fails silently, reports to sentry
      updateNamesAndMarkSignedUp()
      sentry.captureException(new Error(SENTRY_HUBSPOT_UNEXPECTED_RESPONSE))
    }
  } catch (error) {
    // network errors - user gets in the app, Hubspot fails silently, reports to sentry
    updateNamesAndMarkSignedUp()
    sentry.captureException(new Error(SENTRY_HUBSPOT_NETWORK_ERROR), { extra: { error } })
  }
}

const updateWorkspaceName = async (name: string) => {
  const ownedWorkspace = workspaceStore.workspaces.find((workspace) => workspace.role === 'owner')
  if (!ownedWorkspace) {
    return
  }

  if (name === ownedWorkspace.name) {
    return
  }
  const prevName = ownedWorkspace.name
  ownedWorkspace.name = name

  const result = await updateWorkspace(ownedWorkspace.id, { name })
  if (result.ok) {
    workspaceStore.updateWorkspace(serializeWorkspace(result.data))
    toast.success('Workspace name updated successfully')
  } else {
    toast.error('Failed to update workspace name')
    ownedWorkspace.name = prevName
  }
}

const syncToltReferral = () => {
  if (window.tolt && userStore.user?.email) {
    window.tolt.signup(userStore.user.email)
  }

  const toltReferral = window.tolt_referral

  if (workspaceStore.currentWorkspace && toltReferral) {
    updateWorkspace(workspaceStore.currentWorkspace.id, {
      toltReferral,
      name: workspaceStore.currentWorkspace.name,
    })
  }
}

const updateNamesAndMarkSignedUp = async () => {
  if (!userStore.user) {
    return
  }
  const updateUserPromise = updateUser({
    firstName: values.firstName,
    lastName: values.lastName,
    signupCompleted: true,
    marketingOptin: values.marketingOptin,
  })

  syncToltReferral()
  updateUserPromise.catch(() => {
    toast.error('Network error updating user')
    throw new Error('Network error updating user')
  })

  const response = await updateUserPromise
  if (!response.ok) {
    toast.error('Failed to update user')
    throw new Error('Failed to update user')
  }

  const userRes = await getUser()
  if (!userRes.ok) {
    toast.error('Failed to get user')
    throw new Error('Failed to get user')
  }

  userStore.setUser(serializeUser(userRes.data))
}

/* Animations */
const onRootEnter: BaseTransitionProps['onEnter'] = async (el, done) => {
  el.animate([{ opacity: 0 }, { opacity: 1 }], {
    duration: 250,
    easing: 'ease',
    fill: 'forwards',
  }).onfinish = done
  el.querySelector('input')?.focus()
}

const onRootLeave: BaseTransitionProps['onEnter'] = async (el, done) => {
  el.animate([{ opacity: 1 }, { opacity: 0 }], {
    duration: 250,
    easing: 'ease',
    fill: 'forwards',
  }).onfinish = done
}

const onEnter: BaseTransitionProps['onEnter'] = async (el, done) => {
  backDisabled.value = true
  const h = el.getBoundingClientRect().height
  el.style.display = 'none'
  await new Promise((r) => setTimeout(r, 500))
  el.style.display = 'block'
  ;[topLeftSquare, topCenterSquare, topRightSquare].forEach((el) => {
    if (!el.value) return
    el.value.animate([{ translate: `0 ${h / 2}px` }, { translate: '0 0px' }], {
      duration: 500,
      easing: 'ease',
      fill: 'forwards',
    })
  })
  ;[bottomLeftSquare, bottomCenterSquare, bottomRightSquare].forEach((el) => {
    if (!el.value) return
    el.value.animate([{ translate: `0 -${h / 2}px` }, { translate: '0 0px' }], {
      duration: 500,
      easing: 'ease',
      fill: 'forwards',
    })
  })

  setTimeout(done, 500)
  el.querySelector('input')?.focus()
}

const onLeave: BaseTransitionProps['onLeave'] = (el, done) => {
  const h = el.getBoundingClientRect().height

  ;[topLeftSquare, topCenterSquare, topRightSquare].forEach((el) => {
    if (!el.value) return
    el.value.animate([{ translate: '0 0px' }, { translate: `0 ${h / 2}px` }], {
      duration: 500,
      easing: 'ease',
      fill: 'forwards',
    })
  })
  ;[bottomLeftSquare, bottomCenterSquare, bottomRightSquare].forEach((el) => {
    if (!el.value) return
    el.value.animate([{ translate: '0 0px' }, { translate: `0 -${h / 2}px` }], {
      duration: 500,
      easing: 'ease',
      fill: 'forwards',
    })
  })

  setTimeout(() => {
    done()
    backDisabled.value = false
  }, 500)
}

const trackedStart = ref(false)
watch(enabled, () => {
  if (enabled.value && !trackedStart.value) {
    trackedStart.value = true
    captureAnalyticsEvent(ANALYTICS_EVENT.START_SIGNUP_FORM)
  }
})
</script>

<template>
  <Teleport to="body">
    <Transition
      @enter="onRootEnter"
      @leave="onRootLeave"
    >
      <UseFocusTrap v-if="enabled">
        <div
          class="fixed inset-0 z-[99999999] grid place-items-center overflow-hidden bg-surface-secondary"
        >
          <!-- Top Left square -->
          <div
            ref="topLeftSquare"
            class="absolute left-0 top-[-67px] z-10 h-1/2 w-[calc(50%-479px)] border-b border-r border-border-subtle bg-surface-secondary"
          ></div>
          <!-- Bottom Left square -->
          <div
            ref="bottomLeftSquare"
            class="absolute bottom-[-67px] left-0 z-10 h-1/2 w-[calc(50%-479px)] border-r border-t border-border-subtle bg-surface-secondary"
          ></div>
          <!-- Top Right square -->
          <div
            ref="topRightSquare"
            class="absolute right-0 top-[-67px] z-10 h-1/2 w-[calc(50%-479px)] border-b border-l border-border-subtle bg-surface-secondary"
          ></div>
          <!-- Bottom Right square -->
          <div
            ref="bottomRightSquare"
            class="absolute bottom-[-67px] right-0 z-10 h-1/2 w-[calc(50%-479px)] border-l border-t border-border-subtle bg-surface-secondary"
          ></div>
          <!-- Top Center square -->
          <div
            ref="topCenterSquare"
            class="absolute left-[calc(50%-480px)] top-0 z-10 h-[calc(50%-67px)] w-[960px] border-x border-b border-border-subtle bg-surface-secondary"
          >
            <DotSteps
              class="absolute bottom-6 left-12"
              :total="3"
              :current="step"
            />
          </div>
          <!-- Bottom Center square -->
          <div
            ref="bottomCenterSquare"
            class="absolute bottom-0 left-[calc(50%-480px)] z-10 flex h-[calc(50%-67px)] w-[960px] flex-col border-x border-t border-border-subtle bg-surface-secondary"
          >
            <div
              class="flex w-full items-center border-b border-border-subtle pl-11 transition-all duration-500"
              :class="step === 3 ? 'h-16 delay-[900ms]' : 'h-0 opacity-0'"
            >
              <CheckBox
                :checked="values.marketingOptin"
                label="I agree to receive marketing emails and updates."
                @change="values.marketingOptin = $event"
              />
            </div>
            <div class="ml-12 mt-6">
              <DarwinButton
                size="lg"
                rounded
                variant="neutral"
                class="!transition-all"
                :class="step === 1 ? 'pointer-events-none opacity-0' : 'opacity-100 delay-1000'"
                :disabled="backDisabled"
                @click="step--"
              >
                <template #leading-icon>
                  <IconSprite
                    icon="chevron-left"
                    size="md"
                  />
                </template>
                Back
              </DarwinButton>
            </div>
          </div>

          <div
            ref="formWrapper"
            class="h-[136px] w-[960px] border-x border-border-subtle"
          >
            <Transition
              :css="false"
              @enter="onEnter"
              @leave="onLeave"
            >
              <form
                v-if="step === 1"
                class="flex w-full flex-col justify-center overflow-hidden px-10 py-6"
                data-test="first-name-form"
                @submit="onSubmit"
              >
                <label class="px-2 text-md-13px-default">Welcome. What's your first name?</label>
                <div class="flex w-full items-center gap-10">
                  <InlineTextField
                    size="sm"
                    class="grow !bg-background-transparent [&_>input]:!text-display-xl-44px-bold"
                    placeholder="First name"
                    :value="values.firstName"
                    @input="values.firstName = $event"
                  />
                  <IconButton
                    icon="arrow-right"
                    size="xxl"
                    variant="brand"
                    rounded
                    :disabled="!values.firstName"
                  />
                </div>
              </form>
            </Transition>
            <Transition
              :css="false"
              @enter="onEnter"
              @leave="onLeave"
            >
              <form
                v-if="step === 2"
                class="flex w-full flex-col justify-center overflow-hidden px-10 py-6"
                data-test="last-name-form"
                @submit="onSubmit"
              >
                <label class="px-2 text-md-13px-default"
                  >Hi {{ values.firstName }}. What's your last name?</label
                >
                <div class="flex w-full items-center gap-10">
                  <InlineTextField
                    size="sm"
                    class="grow !bg-background-transparent [&_>input]:!text-display-xl-44px-bold"
                    placeholder="Last name"
                    :value="values.lastName"
                    @input="values.lastName = $event"
                  />
                  <IconButton
                    icon="arrow-right"
                    size="xxl"
                    variant="brand"
                    rounded
                    :disabled="!values.lastName"
                  />
                </div>
              </form>
            </Transition>
            <Transition
              :css="false"
              @enter="onEnter"
              @leave="onLeave"
            >
              <form
                v-if="step === 3"
                class="flex w-full flex-col justify-center overflow-hidden px-10 py-6"
                data-test="job-title-form"
                @submit="onSubmit"
              >
                <label class="px-2 text-md-13px-default">What's your job title?</label>
                <div class="flex w-full items-center gap-10">
                  <InlineTextField
                    size="sm"
                    class="grow !bg-background-transparent [&_>input]:!text-display-xl-44px-bold"
                    placeholder="Job title"
                    :value="values.jobTitle"
                    @input="values.jobTitle = $event"
                  />
                  <IconButton
                    icon="arrow-right"
                    size="xxl"
                    variant="brand"
                    rounded
                    :disabled="!values.jobTitle"
                  />
                </div>
              </form>
            </Transition>
          </div>

          <div class="fixed left-1/2 top-6 z-10 flex -translate-x-1/2">
            <V7Logo class="size-10" />
          </div>

          <div
            class="fixed bottom-6 left-1/2 z-10 flex -translate-x-1/2 flex-col items-center gap-3"
          >
            <BadgeItem
              :label="`Connected with ${userStore.user?.email ?? ''}`"
              class="inline-flex justify-self-center"
              variant="neutral"
              size="sm"
            />
            <div class="text-xs-11px-default text-text-subtlest">
              <div class="text-center">
                By signing up, I agree to
                <a
                  href="https://www.v7labs.com/terms"
                  target="_blank"
                  class="underline"
                >
                  V7’s Terms of Service</a
                >, Privacy Policy and Data Processing Agreement.
              </div>
              <div class="text-center">V7 Go is GDPR, ISO27001 and SOC 2 Type II Compliant.</div>
            </div>
          </div>
        </div>
      </UseFocusTrap>
    </Transition>
  </Teleport>
</template>
