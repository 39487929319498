<script setup lang="ts">
import CheckBox from '@/uiKit/CheckBox.vue'
import { usePermissionsStore } from '../IdentityAndAccess/permissionsStore'
defineProps<{
  isSelected: boolean
  extraWidth?: boolean
}>()

defineEmits<{ (e: 'change'): void }>()

const permissionsStore = usePermissionsStore()
</script>

<template>
  <div
    class="inline-flex h-8 items-center justify-center px-2 py-1.5 last:border-r-0 hover:cursor-pointer"
    :class="[
      extraWidth ? 'w-16' : 'w-12',
      isSelected ? 'bg-background-selected' : 'bg-surface-primary',
    ]"
  >
    <div
      :class="
        !isSelected &&
        'shrink grow basis-0 overflow-hidden text-ellipsis text-center text-sm-12px-light text-text-subtlest group-hover/row:flex group-hover/row:justify-center'
      "
      click.stop
    >
      <span
        :class="[
          isSelected && 'hidden',
          permissionsStore.currentProjectPermissions.update_entities && 'group-hover/row:hidden',
        ]"
      >
        <slot />
      </span>
      <CheckBox
        v-if="permissionsStore.currentProjectPermissions.update_entities"
        :checked="isSelected"
        :class="isSelected ? 'block' : 'hidden group-hover/row:block'"
        @change="$emit('change')"
      />
    </div>
  </div>
</template>
