import { put } from './client'
import type { BackendMeta } from './types'

export type UpdateProjectPermission = BackendMeta<
  '/api/workspaces/{workspace_id}/projects/{project_id}/members/{user_id}',
  'put'
>

export type UpdateProjectPermissionParams = {
  workspaceId: UpdateProjectPermission['pathParams']['workspace_id']
  projectId: UpdateProjectPermission['pathParams']['project_id']
  role: UpdateProjectPermission['body']['role']
} & (
  | { userId: UpdateProjectPermission['pathParams']['user_id'] }
  | { group: 'anyone_in_workspace' | 'anyone_in_folder' }
)

export const updateProjectPermission = ({
  projectId,
  workspaceId,
  role,
  ...rest
}: UpdateProjectPermissionParams) => {
  const userId = 'userId' in rest ? rest.userId : rest.group

  return put<
    UpdateProjectPermission['body'],
    UpdateProjectPermission['successResponse'],
    UpdateProjectPermission['path']
  >(`/api/workspaces/${workspaceId}/projects/${projectId}/members/${userId}`, { role })
}
