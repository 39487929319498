<script setup lang="ts">
import { computed, inject, nextTick, onUnmounted, ref, toRef, watch } from 'vue'
import { VuePDF, usePDF } from '@tato30/vue-pdf'
import '@tato30/vue-pdf/style.css'
import { useRoute } from 'vue-router'
import { useDebounceFn, useElementSize } from '@vueuse/core'

import ScaleButtons from './ScaleButtons.vue'
import { useGroundingInteractions } from '@/sharedComposables/useGroundingInteractions'
import ClaimIndicator from '@/sharedComponents/ClaimIndicator.vue'
import type { components } from '@/api'
import { useNaturalZoom } from '@/sharedComposables/useNaturalZoom'
import { useGroundingStore, type Source } from './useGroundingStore'
import { useGroundingPolygons } from './useGroundingPolygons'

type BoundingBox = components['schemas']['Projects.Grounding.BoundingBox']
type OcrPage = components['schemas']['Projects.Entities.OCRPage']
type GroundingInfo = {
  sources: Source[]
  ocrPages: OcrPage[]
}

const props = withDefaults(
  defineProps<{
    filePath: string
    storagePrefix?: string
    groundingInfo?: GroundingInfo
    showSidebar?: boolean
  }>(),
  {
    storagePrefix: 'entityview-scale',
    groundingInfo: () => ({ sources: [], ocrPages: [] }),
    showPages: false,
  },
)

const inGrounding = inject('in-grounding', false)

const route = useRoute()
const projectId = [route.params.projectId].flat()[0]
const propertyId = [route.params.propertyId].flat()[0]
const { pdf, pages, download } = usePDF(computed(() => props.filePath))

defineExpose({
  download,
})

const storageKey = computed(() => `${props.storagePrefix}-proj-${projectId}-prop-${propertyId}`)
const {
  onZoom,
  scrollContainer: pdfContainer,
  scrollContainerChild,
  scale,
  setScale,
} = useNaturalZoom(storageKey)

const pdfInfo = ref<
  {
    scale: number
    rawDims: { pageWidth: number; pageHeight: number }
  }[]
>([])
const { width: pdfContainerWidth } = useElementSize(pdfContainer)

const pagesContainer = ref<HTMLDivElement | null>(null)
const { width: pagesWidth } = useElementSize(pagesContainer)

type GetBoxCoordsArgs = {
  scale: number
  box: BoundingBox
  page: number
}
function getBoxCoords({ scale, box, page }: GetBoxCoordsArgs) {
  const ocrPage = props.groundingInfo.ocrPages.find((p) => p.number === page)
  if (!ocrPage) return

  // percentage based values
  const left = (box.xmin / ocrPage.width) * 100
  const top = (box.ymin / ocrPage.height) * 100
  const right = (box.xmax / ocrPage.width) * 100
  const bottom = (box.ymax / ocrPage.height) * 100
  const width = right - left
  const height = bottom - top

  const coords = {
    width: `${width}%`,
    height: `${height}%`,
    left: `${left}%`,
    top: `${top}%`,
    'border-width': `${scale * 1}px`,
    'border-radius': `${scale * 8}px`,
  }

  return coords
}

const sourcesWithCoords = computed(() => {
  return props.groundingInfo?.sources.map((source) => {
    const boxes = source.boundingBoxes.map((b) => {
      const s = scale.value === 'fit' ? (pdfInfo.value[b.page - 1]?.scale ?? 1) : scale.value

      return {
        ...b,
        coords: getBoxCoords({
          scale: s,
          box: b,
          page: b.page,
        }),
      }
    })

    const claimCoords = boxes[0]?.coords
      ? {
          left: boxes[0]?.coords?.left,
          top: boxes[0]?.coords?.top,
        }
      : {}

    return {
      ...source,
      boxes,
      claimCoords,
    }
  })
})
function getPageSources(page: number) {
  return sourcesWithCoords.value.filter((s) => s.boxes.some((b) => b.page === page))
}

const groundingStore = useGroundingStore()
const { clickSourcePill } = useGroundingInteractions({
  onClaimPillClick: async ({ sourceId }) => {
    groundingStore.clickSource(sourceId)
    await nextTick()
    if (!pdfContainer.value) return

    const source = sourcesWithCoords.value.find((s) => s.id === sourceId)
    const page = source?.boxes[0].page
    const sourceEl = pdfContainer.value.querySelector(`[data-source="${sourceId}"]`)
    const pageInfo = pdfInfo.value[(page ?? 0) - 1]
    if (!sourceEl || !page || !pageInfo) return

    const viewbox = getOcrPageViewbox(page)
    const [vw, vh] = viewbox.split(' ').map(Number).slice(2)
    const actualWidth = pageInfo.rawDims.pageWidth
    const actualHeight = (actualWidth / vw) * vh
    const wScale = actualWidth / vw
    const hScale = actualHeight / vh
    const points = getPolygonPointsFromSource(source, page).flatMap((polygons) => {
      return polygons
        .trim()
        .split(' ')
        .flatMap((points) => {
          const [x, y] = points.split(',').map(Number)
          return { x: x * wScale, y: y * hScale }
        })
    })

    const [minX, minY, maxX, maxY] = points.reduce(
      ([minX, minY, maxX, maxY], { x, y }) => [
        Math.min(minX, x),
        Math.min(minY, y),
        Math.max(maxX, x),
        Math.max(maxY, y),
      ],
      [Infinity, Infinity, -Infinity, -Infinity],
    )
    const width = maxX - minX
    const height = maxY - minY

    const pdfRect = pdfContainer.value.getBoundingClientRect()
    // Figure out appropriate zoom level
    const zoom = Math.min(pdfRect.width / (width + 20), pdfRect.height / (height + 20), 2)
    setScale(zoom)
    await new Promise((resolve) => setTimeout(resolve, 100))

    const scaledLeft = minX * zoom
    const scaledTop =
      minY * zoom +
      ((page - 1) * pdfContainer.value.scrollHeight) / props.groundingInfo.ocrPages.length
    pdfContainer.value.scrollTo({
      left: scaledLeft - 100,
      top: scaledTop - 24,
      behavior: 'smooth',
    })
  },
})

function goToPage(page: number) {
  if (!pdfContainer.value) return
  const box = pdfContainer.value.querySelector(`[data-page="${page}"]`)
  // scroll box and put it in center of viewport
  if (!box || !(box instanceof HTMLElement)) return
  pdfContainer.value.scrollTo({
    top: box.offsetTop - pdfContainer.value.clientHeight / 2 + box.clientHeight / 2,
    behavior: 'smooth',
  })
}

const activePage = ref(1)
function onScroll() {
  if (!pdfContainer.value) return

  const container = pdfContainer.value

  // if at top, the page is 1
  if (container.scrollTop === 0) {
    activePage.value = 1
    return
  }

  // if at bottom, the page is last
  if (container.scrollTop + container.clientHeight >= container.scrollHeight) {
    activePage.value = pages.value
    return
  }

  const viewportCenter = container.scrollTop + container.clientHeight / 2

  let closestPage = 1
  let minDistance = Infinity

  const pagesArray = Array.from(container.querySelectorAll('[data-page]')) as HTMLElement[]

  pagesArray.forEach((page, index) => {
    const pageCenter = page.offsetTop + page.clientHeight / 2
    const distance = Math.abs(viewportCenter - pageCenter)

    if (distance < minDistance) {
      closestPage = index + 1
      minDistance = distance
    }
  })

  if (activePage.value !== closestPage) {
    activePage.value = closestPage
  }
}

const pagesScrollTo = useDebounceFn((top: number) => {
  if (!pagesContainer.value) return
  pagesContainer.value.scrollTo({ top, behavior: 'smooth' })
}, 200)

watch(activePage, () => {
  if (!pagesContainer.value) return
  const pageEl = pagesContainer.value.querySelector(`[data-page="${activePage.value}"]`)
  if (!(pageEl instanceof HTMLElement)) return

  const lowerBound = pagesContainer.value.scrollTop
  const upperBound = lowerBound + pagesContainer.value.clientHeight

  if (pageEl.offsetTop < lowerBound) {
    pagesScrollTo(pageEl.offsetTop - 10)
  }

  if (pageEl.offsetTop + pageEl.clientHeight > upperBound) {
    pagesScrollTo(pageEl.offsetTop + pageEl.clientHeight - pagesContainer.value.clientHeight + 10)
  }
})

onUnmounted(() => {
  pdf.value?.destroy()
})

const { getPolygonPointsFromSource, strokeWidthPerPage, getOcrPageViewbox } = useGroundingPolygons(
  toRef(props.groundingInfo, 'ocrPages'),
)
</script>

<template v-if="filePath">
  <div
    class="relative flex size-full overflow-hidden pt-0"
    :class="!inGrounding && 'p-1'"
    @wheel.ctrl.exact.prevent.stop="onZoom($event)"
    @wheel.meta.exact.prevent.stop="onZoom($event)"
  >
    <div
      v-if="showSidebar && pages > 1"
      ref="pagesContainer"
      class="go-scrollbar z-10 flex size-full w-[100px] flex-col gap-2 overflow-y-auto bg-surface-tertiary p-4 [contain:strict]"
    >
      <button
        v-for="page in pages"
        :key="page"
        class="relative flex scroll-my-2 flex-col items-center gap-1"
        :data-page="page"
        @click="goToPage(page)"
      >
        <VuePDF
          :page="page"
          :pdf="pdf"
          :width="pagesWidth"
          fit-parent
          :source="filePath"
          class="w-fit overscroll-none rounded-corner-2 outline outline-2 transition-all last:border-none"
          :class="page === activePage ? 'outline-border-focused' : 'outline-background-transparent'"
        />
        <span
          class="text-xxs-8px-default"
          :class="page === activePage ? 'text-text-selected' : 'text-text'"
        >
          {{ String(page).padStart(2, '0') }}
        </span>
        <div
          v-if="sourcesWithCoords?.find((s) => s.boxes.some((b) => b.page === page))"
          class="absolute right-1 top-1 size-1.5 rounded-full bg-background-stages-model-pressed"
          aria-label="Claimed"
        />
      </button>
    </div>

    <div class="flex size-full flex-col">
      <div
        class="size-full overflow-hidden rounded-corner-6"
        :class="!inGrounding && 'border border-border-subtle'"
      >
        <div
          id="pdf-container"
          ref="pdfContainer"
          class="go-scrollbar relative flex size-full flex-col flex-wrap items-center overflow-auto px-8 py-4 [contain:strict]"
          @scroll="onScroll"
        >
          <div
            id="pdf-container-child"
            ref="scrollContainerChild"
            class="inline-flex flex-col gap-4"
          >
            <div
              v-for="page in pages"
              :key="page"
              class="relative scroll-my-10"
              :data-page="page"
            >
              <VuePDF
                :page="page"
                :pdf="pdf"
                text-layer
                :scale="scale !== 'fit' ? scale : undefined"
                :width="scale === 'fit' ? pdfContainerWidth : undefined"
                :source="filePath"
                class="relative w-fit overscroll-none border-b transition last:border-none [&>*]:!block"
                :class="page === activePage ? '' : 'opacity-50'"
                @loaded="
                  (e) => {
                    pdfInfo[page - 1] = e as any
                    pdfInfo = [...pdfInfo]
                  }
                "
              >
                <div
                  v-for="source in getPageSources(page)"
                  :key="source.id"
                  class="absolute inset-0"
                >
                  <ClaimIndicator
                    :data-source="source.id"
                    class="absolute z-10 -translate-x-2 -translate-y-4 scroll-m-6"
                    :source-id="source.id"
                    :style="source.claimCoords"
                    @click="clickSourcePill(source.id)"
                  />
                  <svg
                    :viewBox="getOcrPageViewbox(page)"
                    class="absolute left-0 top-0 size-full"
                  >
                    <polygon
                      v-for="(points, index) in getPolygonPointsFromSource(source, page)"
                      :key="index"
                      :points="points"
                      class="fill-background-stages-model-subtle stroke-background-stages-model"
                      :style="{
                        strokeWidth: String(strokeWidthPerPage[page - 1]),
                        strokeLinecap: 'round',
                        strokeLinejoin: 'round',
                      }"
                    />
                  </svg>
                </div>
              </VuePDF>
            </div>
          </div>
        </div>
      </div>
      <div :class="inGrounding ? 'shadow absolute bottom-2 right-2 ml-auto' : 'pt-1'">
        <ScaleButtons
          :scale="scale"
          :full-width-mode="!inGrounding"
          @update:scale="setScale"
        />
      </div>
    </div>
  </div>
</template>
