import { get } from './client'
import type { BackendMeta } from './types'

export type ListProjectInvitations = BackendMeta<
  '/api/workspaces/{workspace_id}/projects/{project_id}/invitations',
  'get'
>

export type ListProjectInvitationsParams = {
  workspaceId: ListProjectInvitations['pathParams']['workspace_id']
  projectId: ListProjectInvitations['pathParams']['project_id']
  after: ListProjectInvitations['queryParams']['after']
  first: ListProjectInvitations['queryParams']['first']
}

/** Returns outstanding invitations to the project. */
export const listProjectInvitations = ({
  projectId,
  workspaceId,
  first,
  after,
}: ListProjectInvitationsParams) => {
  return get<
    ListProjectInvitations['queryParams'],
    ListProjectInvitations['successResponse'],
    ListProjectInvitations['path']
  >(`/api/workspaces/${workspaceId}/projects/${projectId}/invitations`, {
    first,
    after,
  })
}
