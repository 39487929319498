import { computed } from 'vue'
import { useRouter } from 'vue-router'

export const useRoutes = () => {
  const router = useRouter()

  const route = router.currentRoute.value

  const currentlyInSubProject = computed(() => {
    return (
      route.name === 'WorkspaceSubProjectTable' ||
      route.name === 'WorkspaceSubProjectTableView' ||
      route.name === 'WorkspaceSubProjectEntityView' ||
      route.name === 'WorkspaceSubProjectEntitySubView'
    )
  })

  const currentlyInEntityView = computed(() => {
    return (
      route.name === 'WorkspaceProjectEntityView' ||
      route.name === 'WorkspaceProjectEntitySubView' ||
      route.name === 'WorkspaceSubProjectEntityView' ||
      route.name === 'WorkspaceSubProjectEntitySubView'
    )
  })

  return { route, currentlyInSubProject, currentlyInEntityView }
}
