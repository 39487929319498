import { watch, type Ref } from 'vue'
import { usePermissionsStore } from '../IdentityAndAccess/permissionsStore'
import { useModelProviders } from './modelProvidersStore'

export const useLoadModelProviders = (workspaceId: Ref<string>) => {
  const modelProvidersStore = useModelProviders()
  const permissionsStore = usePermissionsStore()
  watch(
    workspaceId,
    (id) => {
      modelProvidersStore.refreshTools(id)

      if (permissionsStore.workspacePermissions.manage_tool_integrations) {
        modelProvidersStore.refreshProviderConfig(id)
      }
    },
    { immediate: true },
  )
}
