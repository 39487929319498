<script setup lang="ts">
import { useModelProviders } from '@/modules/WorkspaceSettings/modelProvidersStore'
import ListMenuItem from '@/uiKit/ListMenuItem.vue'
import { computed } from 'vue'
import { TOOL_ICON } from './icons'
import { PropertyTool, PropertyType, ToolProvider } from '@/backend/types'
import IconSprite from '@/uiKit/IconSprite.vue'
import type { IconName } from '@/uiKit/IconName'
import ListMenu from '@/uiKit/ListMenu.vue'
import ToolTip from '@/uiKit/ToolTip.vue'
import {
  FIELD_TOOL_LABELS,
  GROUNDED_TOOLS,
  TOOL_DESCRIPTIONS,
  TOOL_PROVIDERS,
} from '../WorkspaceSettings/propertyConfig'
import BadgeItem from '@/uiKit/BadgeItem.vue'
/**
 * Renders a list of tools that can be applied to a property, grouped by
 * provider.
 */

type ListItemTool = {
  icon: IconName
  label: string
  tool: PropertyTool
  provider: ToolProvider
  isNew?: boolean
}

const props = defineProps<{
  /** The tool that is currently selected */
  activeTool: PropertyTool
  /** The type of property */
  activeType: PropertyType
  /** If true, there will be a search input that will filter the list */
  searchable?: boolean
  /**
   * If true, then the component will only render models that are
   * compatible with grounding.
   */
  grounded: boolean
}>()

defineEmits<{
  (e: 'change', tool: PropertyTool): void
}>()

/**
 * When grounding is enabled, these are the only tools that are available. When
 * grounding is disabled, these tools are not available.
 */
const isAvailableBasedOnGrounding = (tool: PropertyTool) =>
  props.grounded ? GROUNDED_TOOLS.includes(tool) : !GROUNDED_TOOLS.includes(tool)

const isNew = (tool: PropertyTool) => {
  const newTools: PropertyTool[] = [PropertyTool.code]
  return newTools.includes(tool)
}

const modelProvidersStore = useModelProviders()

const getModels = (provider: ToolProvider): ListItemTool[] =>
  modelProvidersStore.providers[provider].enabled
    ? modelProvidersStore.providers[provider].tools
        .filter(
          (tool) =>
            modelProvidersStore.enabledTools.includes(tool.name) &&
            tool.supportedOutputTypes.includes(props.activeType) &&
            isAvailableBasedOnGrounding(tool.name),
        )
        .map((tool) => {
          const providerRemap: Record<string, ToolProvider> = {
            // visually show ocr as internal
            // and url scrape should be next to bing search
            [PropertyTool.ocr]: ToolProvider.internal,
            [PropertyTool.url_scrape]: ToolProvider.bing_search,
            [PropertyTool.go]: ToolProvider.internal,
          }

          return {
            label: FIELD_TOOL_LABELS[tool.name],
            icon: TOOL_ICON[tool.name],
            tool: tool.name,
            provider: providerRemap[tool.name] ?? TOOL_PROVIDERS[tool.name],
            isNew: isNew(tool.name),
          }
        })
    : []

const ungroupedModels = computed<ListItemTool[]>(() => {
  const models = [
    ...getModels(ToolProvider.internal),
    ...getModels(ToolProvider.azure_ocr),
    ...getModels(ToolProvider.bing_search),
    ...getModels(ToolProvider.fire_crawl),
    ...getModels(ToolProvider.amazon_textract),
  ]

  if (!props.grounded && props.activeType !== PropertyType.collection) {
    models.unshift({
      tool: PropertyTool.manual,
      label: FIELD_TOOL_LABELS.manual,
      icon: TOOL_ICON.manual,
      provider: ToolProvider.internal,
    })
  }

  return models
})
const openAiModels = computed(() => getModels(ToolProvider.open_ai))
const googleModels = computed(() => getModels(ToolProvider.google_ai))
const anthropicModels = computed(() => getModels(ToolProvider.anthropic))
const azureOpenAiModels = computed(() => getModels(ToolProvider.azure_open_ai))
const vertexAnthropicModels = computed(() => getModels(ToolProvider.vertex_ai_claude))

const getTooltipText = (tool: PropertyTool) => TOOL_DESCRIPTIONS[tool]
</script>

<template>
  <ListMenu
    role="listbox"
    class="min-w-[240px]"
    aria-label="Select a tool"
    :items="
      [
        ...ungroupedModels,
        ...openAiModels,
        ...azureOpenAiModels,
        ...googleModels,
        ...anthropicModels,
        ...vertexAnthropicModels,
      ].map((m) => ({
        id: m.tool,
        data: m,
      }))
    "
    :initial-active-item-predicate="(item) => item.data.tool === activeTool"
    :group-by-predicate="(item) => item.data.provider"
    :search-by-field="searchable ? 'label' : undefined"
  >
    <template #item="{ item, active, key, setActiveItem }">
      <ToolTip
        :title="getTooltipText(item.data.tool)"
        :arrow="true"
        :placement="{ allowedPlacements: ['right', 'left'] }"
      >
        <ListMenuItem
          :label="item.data.label"
          :active="active"
          :aria-selected="active"
          default-hover-disabled
          :icon="TOOL_ICON[item.data.tool]"
          @mousemove="setActiveItem(key)"
          @select="$emit('change', item.data.tool)"
        >
          <template #prefix>
            <IconSprite
              :icon="item.data.tool === activeTool ? 'check' : 'blank'"
              class="mr-1 text-icon-subtle"
            />
          </template>
          {{ item.data.label }}
          <span
            v-if="item.data.provider === ToolProvider.vertex_ai_claude"
            class="ml-auto text-xs-11px-light text-text-subtlest"
          >
            via Vertex AI
          </span>
          <span
            v-else-if="item.data.provider === ToolProvider.azure_open_ai"
            class="ml-auto text-xs-11px-light text-text-subtlest"
          >
            via Azure
          </span>
          <template
            v-if="item.data.isNew"
            #suffix
          >
            <BadgeItem
              label="New"
              size="xs"
              variant="blue"
            />
          </template>
        </ListMenuItem>
      </ToolTip>
    </template>
  </ListMenu>
</template>
