<script setup lang="ts">
import type { PropertyType } from '@/backend/types'
import { useBilling } from '@/modules/Billing/useBilling'
import { useLimitedAction } from '@/modules/Billing/useLimitedAction'
import { FIELD_DEFAULT_TOOL } from '@/modules/WorkspaceSettings/propertyConfig'
import { ANALYTICS_EVENT, useAnalytics } from '@/sharedComposables/useAnalytics'
import { computed, ref } from 'vue'
import { useWorkspaces } from '../Workspaces/useWorkspaces'
import ProjectConfiguration from './ProjectConfiguration.vue'
import ProjectTableHeaderAddProperty from './ProjectTableHeaderAddProperty.vue'
import ProjectTableHeaderCheckbox from './ProjectTableHeaderCheckbox.vue'
import ProjectTableHeaderItem from './ProjectTableHeaderItem.vue'
import { useColumnReordering } from './useColumnReordering'
import type { Property } from './useProject'
import { serializeProperty, useProject } from './useProject'
import { useProperty } from './useProperty'
import { PINNED_HEADER_Z_INDEX, useTableZIndices } from './useTableZIndices'
import { useAskGo } from './useAskGo'
import { usePermissionsStore } from '../IdentityAndAccess/permissionsStore'
import { usePinnedColumn } from './usePinnedColumn'

defineProps<{
  extraIndexColWidth?: boolean
}>()

const workspacesStore = useWorkspaces()
const projectStore = useProject()
const propertyStore = useProperty()

const billingStore = useBilling()

const { addProperty } = useLimitedAction()

const { captureAnalyticsEvent } = useAnalytics()

/**
 * We have optimistic UI when creating properties. This ref stores
 * the ids of properties that are 'placeholders' and are currently
 * being created.
 */
const optimisticIds = ref<string[]>([])

const createNewPropertyInStoreAndBackend = async (type: PropertyType) => {
  if (!projectStore.projectId || !workspacesStore.currentWorkspace) {
    return
  }

  const newProperty = {
    name: 'New property',
    type,
    tool: FIELD_DEFAULT_TOOL[type],
    description: '',
    inputIds: [],
    inputs: [],
    viewId: projectStore.activeView?.id ?? projectStore.mainView?.id,
  }

  const optimisticId = crypto.randomUUID()
  const optimisticProperty: Property = {
    ...newProperty,
    id: optimisticId,
    hash: '',
    owner: 'user',
    slug: optimisticId,
  }
  optimisticIds.value.push(optimisticId)
  projectStore.upsertProperty(optimisticProperty)
  if (projectStore.activeView) {
    projectStore.upsertView({
      ...projectStore.activeView.view,
      propertyIds: [...(projectStore.activeView.view.propertyIds ?? []), optimisticProperty.id],
    })
  }

  const result = await addProperty(
    workspacesStore.currentWorkspace.id,
    projectStore.projectId,
    newProperty,
  )

  projectStore.removeProperty(optimisticId)
  if (projectStore.activeView) {
    projectStore.upsertView({
      ...projectStore.activeView.view,
      propertyIds: projectStore.activeView.view.propertyIds.filter((id) => id !== optimisticId),
    })
  }

  if (result.ok) {
    const newPropertyId = result.data.id
    if (
      // The websocket connection might have already added this property ID
      // to the view
      projectStore.activeView?.view.propertyIds &&
      !projectStore.activeView.view.propertyIds.includes(newPropertyId)
    ) {
      projectStore.activeView.view.propertyIds.push(newPropertyId)
    }
    if (billingStore.fieldUsage) {
      billingStore.fieldUsage.limitUsage += projectStore.mainView?.entities?.length ?? 0
    }

    projectStore.upsertProperty(serializeProperty(result.data))
    projectStore.selectedPropertyId = newPropertyId
    propertyStore.reset()

    captureAnalyticsEvent(ANALYTICS_EVENT.PROPERTY_CREATED, {
      workspaceId: workspacesStore.currentWorkspace.id,
      projectId: projectStore.projectId,
      propertyId: newPropertyId,
    })
  }
}

const header = ref<HTMLElement | null>(null)

const { onHeaderItemMouseDown, hasMoved, dragBoxStyles, potentialNewIndex, lineStyles } =
  useColumnReordering(header)

const permissionsStore = usePermissionsStore()

const askGoStore = useAskGo()

const pinnedColumn = usePinnedColumn()
const pinnedColumns = computed(() => pinnedColumn.numPinnedProperties)

const tableZIndex = useTableZIndices()

// class="sticky top-0 grid w-max min-w-full border-t-2 border-t-surface-secondary-persist bg-surface-primary"
</script>

<template>
  <div
    ref="header"
    role="row"
    aria-rowindex="1"
    class="contents"
  >
    <ProjectTableHeaderCheckbox
      class="sticky left-0 top-0 border-y border-r border-border-subtle bg-surface-primary shadow-[0px_-2px_0px_var(--color-surface-secondary-persist)]"
      :extra-width="extraIndexColWidth"
      :style="{ zIndex: PINNED_HEADER_Z_INDEX }"
      aria-rowindex="1"
      aria-colindex="1"
    />
    <ProjectTableHeaderItem
      v-for="(property, index) in projectStore.visibleProperties"
      :key="property.id"
      class="group sticky top-0 border-y border-r border-border-subtle bg-surface-primary before:absolute before:-z-1 before:size-full focus-within:before:bg-background-transparent-hovered hover:before:bg-background-gray-subtlest focus:outline-none [&:nth-last-child(2)]:border-r-0"
      :property-id="property.id"
      :property-name="property.name"
      :property-type="property.type"
      :width="projectStore.getWidth(property.id)"
      :selected="property.id === projectStore.selectedPropertyId"
      :aria-selected="property.id === projectStore.selectedPropertyId"
      :is-input="
        propertyStore.editedInputs.some(
          ({ propertyId, entityId }) => propertyId === property.id && !entityId,
        ) || askGoStore.highlightedInput?.propertyId === property.id
      "
      data-test="property-header"
      :data-pinned="index < pinnedColumns ? '' : undefined"
      role="columnheader"
      :aria-rowindex="1"
      :aria-colindex="index + 2"
      :loading="optimisticIds.includes(property.id)"
      :style="{
        ...pinnedColumn.getColStyle(index),
        zIndex: index < pinnedColumns ? tableZIndex.zIndex.pinnedHeader : tableZIndex.zIndex.header,
      }"
      @select="projectStore.setSelectedProperty(property.id)"
      @resize="projectStore.resizeProperty(property.id, $event)"
      @mousedown="onHeaderItemMouseDown(property.id, $event)"
    />
    <div
      class="sticky right-0 top-0 flex h-8 w-min flex-row border-t border-border-subtle bg-surface-primary shadow-[0px_-2px_0px_var(--color-surface-secondary-persist)]"
      :style="{ zIndex: tableZIndex.zIndex.header }"
    >
      <template v-if="permissionsStore.currentProjectPermissions.manage_properties">
        <ProjectTableHeaderAddProperty
          class="border-b border-l border-border-subtle bg-surface-primary hover:bg-background-transparent-hovered active:bg-background-transparent-pressed"
          :project-id="projectStore.projectId"
          @create-property="createNewPropertyInStoreAndBackend"
        />
        <ProjectConfiguration />
      </template>
      <div
        v-else
        class="border-b border-l border-border-subtle"
      />
    </div>
    <Teleport
      v-if="hasMoved"
      to="body"
    >
      <div
        class="pointer-events-none absolute z-10 bg-background-selected"
        :style="dragBoxStyles"
      >
        <div class="flex h-full items-center justify-center"></div>
      </div>

      <div
        v-if="potentialNewIndex !== null"
        ref="wholeSpanRef"
        :style="lineStyles"
        class="absolute z-10 h-full border-l-2 border-background-primary"
      ></div>
    </Teleport>
  </div>
</template>
