import { post } from './client'
import type { BackendMeta } from './types'

export type CreateProjectInvitation = BackendMeta<
  '/api/workspaces/{workspace_id}/projects/{project_id}/invitations',
  'post'
>

export type CreateProjectInvitationParams = {
  workspaceId: CreateProjectInvitation['pathParams']['workspace_id']
  projectId: CreateProjectInvitation['pathParams']['project_id']
  invitations: Array<{
    email: CreateProjectInvitation['body']['invitations'][number]['email']
    role: Extract<
      CreateProjectInvitation['body']['invitations'][number]['role'],
      'editor' | 'reader'
    >
  }>
}

export async function createProjectInvitation({
  invitations,
  projectId,
  workspaceId,
}: CreateProjectInvitationParams) {
  return await post<
    CreateProjectInvitation['body'],
    CreateProjectInvitation['successResponse'],
    CreateProjectInvitation['path']
  >(`/api/workspaces/${workspaceId}/projects/${projectId}/invitations`, { invitations })
}
