import { useFuse } from '@vueuse/integrations/useFuse'
import { computed, type ComputedRef, type Ref } from 'vue'

/**
 * Composable function to apply a fuzzy search to a list of items. Will return all items
 * that are sufficiently 'close' to the search term, where closeness is determined by the
 * Levenshtein distance between the search term and the item's search keys.
 * @see https://en.wikipedia.org/wiki/Levenshtein_distance
 *
 * @param params - Input parameters
 * @param params.items - The list of items to search through
 * @param params.searchTerm - The search term to use
 * @param params.keys - Keys of each item to search through
 * @param params.threshold - The threshold for the search, between 0 and 1. Lower values
 *  will return fewer results, higher values will return more results.
 */
export const useFuzzySearch = <T>({
  items,
  searchTerm,
  keys,
  threshold = 0.4,
}: {
  items: Ref<T[]>
  searchTerm: Ref<string>
  keys: (keyof T & string)[]
  threshold?: number
}): ComputedRef<T[]> => {
  const { results } = useFuse(searchTerm, items, {
    fuseOptions: {
      keys,
      threshold,
      shouldSort: true,
    },
    matchAllWhenSearchEmpty: true,
  })

  const resultItems = computed(() => results.value.map((result) => result.item))

  return resultItems
}
