import { useRouter, type LocationQueryValue, type RouteParamsGeneric } from 'vue-router'

type ResolveSubProjectRouteParams = {
  workspaceId: RouteParamsGeneric[string]
  subProjectId: RouteParamsGeneric[string]
  parentProjectId: RouteParamsGeneric[string]
  parentEntityId: LocationQueryValue | LocationQueryValue[]
}

/**
 * Returns a function that is used to get the route for a given subproject.
 */
export const useResolveSubProjectRoute = () => {
  const router = useRouter()
  return ({
    parentProjectId,
    subProjectId,
    workspaceId,
    parentEntityId,
  }: ResolveSubProjectRouteParams) =>
    router.resolve({
      name: 'WorkspaceSubProjectTable',
      params: {
        workspaceId,
        projectId: subProjectId,
        parentProjectId: parentProjectId,
      },
      query: { parentEntityId },
    })
}
