import { get } from './client'
import type { BackendMeta } from './types'

export type GetUserProjectPermissions = BackendMeta<
  '/api/workspaces/{workspace_id}/projects/{project_id}/members/{user_id}',
  'get'
>

export type GetUserProjectPermissionsParams = {
  workspaceId: GetUserProjectPermissions['pathParams']['workspace_id']
  projectId: GetUserProjectPermissions['pathParams']['project_id']
  userId: GetUserProjectPermissions['pathParams']['user_id']
}

/**
 * Get the user's permissions for a given project.
 */
export const getUserProjectPermissions = async (params: GetUserProjectPermissionsParams) => {
  return await get<
    undefined,
    GetUserProjectPermissions['successResponse'],
    GetUserProjectPermissions['path']
  >(
    `/api/workspaces/${params.workspaceId}/projects/${params.projectId}/members/${params.userId}`,
    undefined,
  )
}
