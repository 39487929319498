<script setup lang="ts">
import DarwinButton from '@/uiKit/DarwinButton.vue'
import SelectDropdown from '@/uiKit/SelectDropdown.vue'
import SelectDropdownTrigger from '@/uiKit/SelectDropdownTrigger.vue'
import ListMenu from '@/uiKit/ListMenu.vue'
import ListMenuItem from '@/uiKit/ListMenuItem.vue'
import type { ProjectMemberRole } from './types'
import { computed, ref, toRef, watch } from 'vue'
import { useElementSize } from '@vueuse/core'
import IconSprite from '@/uiKit/IconSprite.vue'
import { PERMISSIONS_DIALOG_ID } from './consts'
import type { WorkspaceMember } from '@/modules/WorkspaceSettings/useWorkspaceMembers'
import PopupMenu from '@/uiKit/PopupMenu.vue'
import AvatarIcon from '@/uiKit/AvatarIcon.vue'
import BadgeItem from '@/uiKit/BadgeItem.vue'
import type { ResourceRole } from '@/backend/types'
import { isEmailValid } from '@/shared/utils/email'
import { toast } from '@/shared/toast'
import { useFuzzySearch } from '@/sharedComposables/useFuzzySearch'

const props = defineProps<{
  /** All current workspace members */
  workspaceMembers: Array<{
    member: WorkspaceMember
    /** Will be undefined if the user has no explicit role in this project */
    projectRole?: ResourceRole
  }>
  /** All email addresses that have pending invitations to this project */
  pendingInviteEmails: string[]
  limitReached?: boolean
  /** All roles that the user is able to invite */
  allowedRoles: Extract<ResourceRole, 'reader' | 'editor'>[]
}>()

const emit = defineEmits<{
  (
    e: 'invite',
    args: { existingUsers: WorkspaceMember[]; role: ProjectMemberRole; newUserEmails: string[] },
  ): void
}>()

const roleTitleMap: Record<ProjectMemberRole, string> = {
  editor: 'Can edit',
  reader: 'Can view',
}

const getRoleTitle = (id: WorkspaceMember['id']): string | undefined => {
  const role = props.workspaceMembers.find((member) => member.member.id === id)?.projectRole
  if (role !== 'editor' && role !== 'reader') {
    return
  }

  return roleTitleMap[role].toLowerCase()
}

type ListItem = { title: string; description: string; role: ProjectMemberRole }
const listItems = computed<ListItem[]>(() => {
  const items: ListItem[] = []

  if (props.allowedRoles.includes('editor')) {
    items.push({
      role: 'editor',
      title: roleTitleMap.editor,
      description: 'Can access the project and modify properties or data.',
    })
  }

  if (props.allowedRoles.includes('reader')) {
    items.push({
      role: 'reader',
      title: roleTitleMap.reader,
      description: 'Can access the project but cannot modify properties or data.',
    })
  }

  return items
})
const limitReachedToastMessage = 'You have reached the limit of users that can be invited'

const defaultRole = props.allowedRoles.includes('editor') ? 'editor' : 'reader'
const selectedRole = ref<ProjectMemberRole>(defaultRole)
const dropdownTriggerText = computed(() => roleTitleMap[selectedRole.value].toLowerCase())

const textAndDropdowownTrigger = ref<HTMLElement | null>(null)
const { width: dropdownWidth } = useElementSize(textAndDropdowownTrigger)

const selectedWorkspaceMembers = ref<WorkspaceMember[]>([])
const onUnselectMember = (member: WorkspaceMember) => {
  selectedWorkspaceMembers.value = selectedWorkspaceMembers.value.filter((m) => m.id !== member.id)
}

const textInputIsFocused = ref(false)
const textValue = ref('')
const textValueIsEmail = computed(() => isEmailValid(textValue.value))

const filteredWorkspaceMembers = useFuzzySearch({
  items: toRef(props.workspaceMembers.map(({ member }) => member)),
  keys: ['email', 'fullName'],
  searchTerm: textValue,
})

const getBadgeLabel = (member: WorkspaceMember) => {
  if (member.firstName) {
    let name = member.firstName
    if (member.lastName) {
      name += ` ${member.lastName.slice(0, 1)}`
    }
    return name
  }

  if (member.lastName) {
    return member.lastName
  }

  // We should never really end up returning the ID, but it's the only
  // string we can guarantee to have
  return member.email ?? member.id
}

const newUserEmails = ref<string[]>([])
const onInviteNewUser = () => {
  if (props.limitReached) {
    toast.error(limitReachedToastMessage)
    return
  }
  if (textValueIsEmail.value) {
    if (
      props.pendingInviteEmails.includes(textValue.value) ||
      newUserEmails.value.includes(textValue.value)
    ) {
      toast.info('User has already been invited')
    } else {
      newUserEmails.value.push(textValue.value)
    }

    textValue.value = ''
  }
}

const onSubmitForm = (e: Event) => {
  e.preventDefault()

  const hasAddedNothing =
    selectedWorkspaceMembers.value.length === 0 && newUserEmails.value.length === 0
  if (hasAddedNothing && textValueIsEmail.value && !props.limitReached) {
    newUserEmails.value.push(textValue.value)
  }

  emit('invite', {
    existingUsers: selectedWorkspaceMembers.value,
    role: selectedRole.value,
    newUserEmails: newUserEmails.value,
  })
  selectedWorkspaceMembers.value = []
  newUserEmails.value = []
  textValue.value = ''
}

const dropdownIsOpen = computed(() => {
  if (!textValue.value) {
    return false
  }

  const isNewValidEmail =
    textValueIsEmail.value &&
    !newUserEmails.value.includes(textValue.value) &&
    !props.pendingInviteEmails.includes(textValue.value)
  const matchesWorkspaceMember = filteredWorkspaceMembers.value.length > 0

  // The dropdown should only be open if either there are matching workspace members,
  // or the text input is a valid email that hasn't been added yet
  return matchesWorkspaceMember || isNewValidEmail
})

/** For a11y - the HTML ID of the dropdown listbox */
const LISTBOX_ID = 'workspace-member-listbox'

/** For a11y - create a HTML ID for each workspace member's dropdown option */
const getItemLabelId = (item: WorkspaceMember) => `workspace-member-option-${item.id}`

const onTextInputEnter = () => {
  if (textValueIsEmail.value) {
    onInviteNewUser()
  } else if (filteredWorkspaceMembers.value.length === 1) {
    selectedWorkspaceMembers.value.push(filteredWorkspaceMembers.value[0])
    textValue.value = ''
  }
}

const onTextInputBackspace = () => {
  if (textValue.value) {
    return
  }

  if (newUserEmails.value.length > 0) {
    newUserEmails.value.pop()
  } else if (selectedWorkspaceMembers.value.length > 0) {
    selectedWorkspaceMembers.value.pop()
  }
}

const textInput = ref<HTMLInputElement | null>(null)
watch(textInput, (input) => {
  if (input) {
    input.focus()
  }
})

const onChangeTextValue = (e: Event) => {
  if (!(e.target instanceof HTMLInputElement)) {
    return
  }

  textValue.value = e.target.value
}

defineExpose({
  newUserEmails,
})
</script>

<template>
  <form
    class="flex items-stretch justify-stretch gap-2"
    @submit="onSubmitForm"
  >
    <div
      ref="textAndDropdowownTrigger"
      class="flex min-w-0 grow items-start gap-0.5 rounded-lg bg-background-gray-subtlest p-0.5"
    >
      <PopupMenu
        :open="dropdownIsOpen"
        :teleport-to="`#${PERMISSIONS_DIALOG_ID}`"
        disable-focus-trap
        class="grow"
        trigger-element="div"
        :placement="'bottom-start'"
        :offset="{ mainAxis: 5 }"
      >
        <template #trigger>
          <div
            class="flex min-h-7 flex-wrap items-center gap-1 rounded-corner-8 focus-within:bg-background-transparent-hovered hover:[&:not(:disabled)]:bg-background-transparent-hovered"
            :class="
              dropdownIsOpen ? 'bg-background-transparent-hovered' : 'bg-background-transparent'
            "
          >
            <div
              v-if="selectedWorkspaceMembers.length + newUserEmails.length > 0"
              class="flex flex-wrap items-center gap-1 px-1 pt-1"
            >
              <BadgeItem
                v-for="member in selectedWorkspaceMembers"
                :key="member.id"
                variant="neutral"
                size="sm"
                :label="getBadgeLabel(member)"
                trailing-icon="close"
                @trailing-icon-click="onUnselectMember(member)"
              >
                <template #leading-icon>
                  <AvatarIcon
                    :full-text="`${member.fullName}`"
                    shape="circle"
                    size="2xs"
                  />
                </template>
              </BadgeItem>
              <BadgeItem
                v-for="email in newUserEmails"
                :key="email"
                variant="neutral"
                size="sm"
                :label="email"
                trailing-icon="close"
                @trailing-icon-click="newUserEmails = newUserEmails.filter((e) => e !== email)"
              >
                <template #leading-icon>
                  <div
                    class="m-0.5 flex size-3 items-center justify-center rounded-full bg-background-gray-subtlest"
                  >
                    <IconSprite
                      icon="user-2"
                      size="xxxs"
                    />
                  </div>
                </template>
              </BadgeItem>
            </div>
            <input
              ref="textInput"
              :value="textValue"
              placeholder="Add people, emails..."
              class="min-w-6 grow rounded-corner-8 bg-background-transparent px-1.5 py-1 outline-none"
              aria-label="Name/email"
              role="combobox"
              :ariaExpanded="dropdownIsOpen ? 'true' : 'false'"
              :ariaControls="LISTBOX_ID"
              @input="onChangeTextValue"
              @keydown.enter.prevent="onTextInputEnter"
              @keydown.,.prevent="onTextInputEnter"
              @keydown.backspace.exact="onTextInputBackspace"
              @focus="textInputIsFocused = true"
              @blur="textInputIsFocused = false"
            />
          </div>
        </template>
        <template #dropdown>
          <ListMenu
            :items="filteredWorkspaceMembers.map((data) => ({ id: data.id, data }))"
            :style="{ width: dropdownWidth + 'px' }"
            :list-box-attrs="{ id: LISTBOX_ID }"
            class="max-h-96"
            :create-enabled-when-empty="textValueIsEmail && !newUserEmails.includes(textValue)"
            :create-enabled="textValueIsEmail && !newUserEmails.includes(textValue)"
            hide-no-results-text
          >
            <template #item="{ item, active, key, setActiveItem }">
              <ListMenuItem
                :active="active || filteredWorkspaceMembers.length === 1"
                :aria-selected="active"
                :aria-labelledby="getItemLabelId(item.data)"
                @mousemove="setActiveItem(key)"
                @select="
                  () => {
                    selectedWorkspaceMembers.push(item.data)
                    textValue = ''
                  }
                "
              >
                <template #prefix>
                  <AvatarIcon
                    :full-text="`${item.data.fullName}`"
                    shape="circle"
                    size="sm"
                  />
                </template>
                <div
                  :id="getItemLabelId(item.data)"
                  class="px-1"
                >
                  <div class="text-sm-12px-default text-text">{{ item.data.fullName }}</div>
                  <div class="text-xs-11px-light text-text-subtle">
                    {{ item.data.email }}
                  </div>
                </div>
                <template #suffix>
                  <div class="grow text-right text-xs-11px-default text-text-subtlest">
                    {{ getRoleTitle(item.data.id) }}
                  </div>
                </template>
              </ListMenuItem>
            </template>
            <template #create>
              <div class="w-full p-0.5">
                <ListMenuItem
                  class="flex items-center gap-1.5 px-2 py-1"
                  :class="limitReached ? 'cursor-not-allowed' : 'cursor-pointer'"
                  @select="onInviteNewUser"
                >
                  <div
                    class="flex size-5 items-center justify-center rounded-full bg-background-gray-subtlest"
                  >
                    <IconSprite
                      icon="plus"
                      :class="limitReached ? 'text-icon-disabled' : 'text-icon-subtle'"
                      size="xs"
                    />
                  </div>
                  <div class="px-1">
                    <div
                      class="text-sm-12px-default"
                      :class="limitReached ? 'text-text-disabled' : 'text-text'"
                    >
                      Invite new user to workspace
                    </div>
                    <div
                      class="text-xs-11px-light"
                      :class="limitReached ? 'text-text-disabled' : 'text-text-subtle'"
                    >
                      {{ textValue }}
                    </div>
                  </div>
                </ListMenuItem>
              </div>
            </template>
          </ListMenu>
        </template>
      </PopupMenu>
      <SelectDropdown
        :offset="{ mainAxis: 5 }"
        :teleport-to="`#${PERMISSIONS_DIALOG_ID}`"
      >
        <template #trigger="{ isOpen }">
          <SelectDropdownTrigger
            inline
            :active="isOpen"
            ><div class="w-max text-text">{{ dropdownTriggerText }}</div></SelectDropdownTrigger
          >
        </template>
        <template #dropdown="{ close }">
          <ListMenu
            :items="listItems.map((data) => ({ id: data.role, data }))"
            :style="{ width: dropdownWidth + 'px' }"
          >
            <template #item="{ item, active, key, setActiveItem }">
              <ListMenuItem
                :active="active"
                :aria-selected="active"
                @mousemove="setActiveItem(key)"
                @select="
                  () => {
                    selectedRole = item.data.role
                    close()
                  }
                "
              >
                <div class="flex items-start">
                  <div class="flex size-5 min-w-5 items-center justify-center">
                    <IconSprite
                      v-if="selectedRole === item.data.role"
                      class="text-icon-subtle"
                      icon="check"
                    />
                  </div>
                  <div class="px-1 py-0.5">
                    <div>{{ item.data.title }}</div>
                    <div class="text-text-subtle">{{ item.data.description }}</div>
                  </div>
                </div>
              </ListMenuItem>
            </template>
          </ListMenu>
        </template>
      </SelectDropdown>
    </div>

    <DarwinButton
      variant="black"
      size="md"
      rounded
      type="submit"
      >Invite</DarwinButton
    >
  </form>
</template>
