import { useEventListener, useLocalStorage } from '@vueuse/core'
import { ref, type Ref } from 'vue'

export function useSidebarWidth(container: Ref<HTMLElement | undefined>) {
  const sidebarWidth = useLocalStorage('sidebar-with', 350)

  const dragging = ref(false)
  function startDrag(e: MouseEvent) {
    e.preventDefault()
    e.stopPropagation()
    dragging.value = true
  }

  function commitDrag(e: MouseEvent) {
    if (!container.value || !dragging.value) return
    const x = e.clientX
    const sidebarRect = container.value.getBoundingClientRect()

    sidebarWidth.value = sidebarRect.right - x - 6
  }

  useEventListener(document, 'mousemove', commitDrag)
  useEventListener(document, 'mouseup', function dragLeave(e: MouseEvent) {
    commitDrag(e)
    dragging.value = false
  })

  return { sidebarWidth, startDrag }
}
